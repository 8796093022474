import HomepageLoggedIn from "../../components/homepage/logged-in/homepage-logged-in.component";
import HomepageNotLoggedIn from "../../components/homepage/not-logged-in/homepage-not-logged-in.component";
import useUserContext from "../../contexts/user.context";

import './home.styles.scss'

const Home = () => {

    const { currentUser } = useUserContext()

    return (
        <div>
            {
                currentUser
                ?
                    <HomepageLoggedIn />
                :
                    <HomepageNotLoggedIn />
            }
        </div>
    )
}

export default Home;
