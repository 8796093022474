import React from 'react';
import { Route, Routes } from 'react-router-dom'

import Home from "./routes/home/home.component";
import Navigation from "./routes/navigation/navigation.component";
import ModellerComponent from "./routes/modeller/modeller.component";
import ApproverComponent from "./routes/approver/approver.component";
import AdminComponent from "./routes/admin/admin.component";
import AccessDenied from "./routes/access-denied/access-denied.component";
import ManagerComponent from './routes/manager/manager.component';


function App() {
    return (
        <Routes>
            <Route path='/' element={<Navigation />}>
                <Route index element={<Home />}/>
                <Route path='administrator/*' element={<AdminComponent />}/>
                <Route path='approver/*' element={<ApproverComponent />}/>
                <Route path='modeller/*' element={<ModellerComponent />}/>
                <Route path='access-denied' element={<AccessDenied />}/>
                <Route path='manager/*' element={<ManagerComponent />}/>
            </Route>
        </Routes>
    );
}

export default App;
