import React, { useEffect, useState } from "react";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useUserContext from "../../contexts/user.context";
import { fetchStructureById } from "../../utils/api-fetchers/structure/structure-api-fetcher";
import { versionArrayToString } from "../../utils/formatters/version-formatter.util";
import { Iso8601ToLocaleDate } from "../../utils/formatters/date-formatter.util";
import { IStructure } from "../../interfaces/IStructure";
import { I3DObject } from "../../interfaces/I3DObject";

import './TDObject-information.styles.scss'
import HelpPopover from "../help-popover/help-popover.component";
import {HelpText} from "../../enums/help-text.enum";

interface IProps {
    TDObject: I3DObject | undefined
}

const Information3DObject = (props: IProps) => {

    const { TDObject } = props

    const { currentUser } = useUserContext()

    const [structure, setStructure] = useState<IStructure | undefined>(undefined)

    useEffect(() => {
        const getStructure = async () => {
            if (currentUser && TDObject)
                setStructure(await fetchStructureById(currentUser?.token, TDObject.structureId))
        }
        getStructure()
    }, [TDObject, currentUser])

    const versionHelpText = HelpText.VERSION

    return(
        <div className='information-3DObject-container'>
            <div className='information-3DObject-form-header-container'>
                <h4>
                    Informace o 3D modelu
                </h4>
            </div>
            <Row>
                <Col className='information-3DObject-column'>
                    <h6>
                        Název:
                    </h6>
                    <div>
                        {TDObject?.name}
                    </div>
                </Col>
                <Col className='information-3DObject-column'>
                    <div className='information-text-and-help'>
                        <h6>
                            Verze:
                        </h6>
                        <HelpPopover htmlContent={versionHelpText} />
                    </div>
                    <div>
                        {
                            TDObject && (
                            (typeof TDObject.version ==='string') ? TDObject.version : versionArrayToString(TDObject?.version))
                        }
                    </div>
                </Col>
                <Col className='information-3DObject-column'>
                    <h6>
                        Vytvořeno:
                    </h6>
                    <div>
                        { TDObject && Iso8601ToLocaleDate(TDObject?.createdDate)}
                    </div>
                </Col>
                <Col className='information-3DObject-right-column'>
                    <h6>
                        Struktura:
                    </h6>
                    <div>
                        {structure?.name}
                    </div>
                </Col>
            </Row>
            <hr className='border-3'/>
        </div>
    )
}

export default Information3DObject
