import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import SendIcon from "../icons/send-icon.component";
import EditIcon from "../icons/edit-icon.component";
import NewIcon from "../icons/new-icon.component";
import FilesIcon from "../icons/files-icon.component";
import DeleteIcon from "../icons/delete-icon.component";
import HelpPopover from "../help-popover/help-popover.component";
import WarningPopover from "../warning-popover/warning-popover.component";
import ExclamationMarkLightIcon from "../icons/exclamation-mark-light-icon.component";
import useUserContext from "../../contexts/user.context";
import { defaultTransformation, defaultTransformationWeb } from "../../utils/constants/TDObject-constants";
import { fetchStructureById } from "../../utils/api-fetchers/structure/structure-api-fetcher";
import { HelpText } from "../../enums/help-text.enum";
import { I3DObject } from "../../interfaces/I3DObject";
import { IStructure } from "../../interfaces/IStructure";
import { czechTDObjectStatus } from "../../enums/TDObject-status.enum";
import { WarningText } from "../../enums/warning-text.enum";

interface IProps {
    loading: boolean,
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
    default3DObject: I3DObject
}

const ModelDetailForm = (props: IProps) => {

    const {
        handleSubmit,
        loading,
        default3DObject
    } = props

    const { currentUser } = useUserContext()
    const navigate = useNavigate()

    const [structure, setStructure] = useState<IStructure | undefined>(undefined)
    const [transformation, setTransformation] = useState(defaultTransformation)
    const [transformationWeb, setTransformationWeb] = useState(defaultTransformationWeb)


    useEffect(() => {
        if (Object.keys(default3DObject.transformation).length > 0)
            setTransformation(default3DObject.transformation)
        if (Object.keys(default3DObject.transformationWeb).length > 0)
            setTransformationWeb(default3DObject.transformationWeb)
    }, [default3DObject.transformation, default3DObject.transformationWeb])

    useEffect(() => {
        const fetchStructure = async () => {
            if (currentUser) {
                setStructure(await fetchStructureById(currentUser.token, default3DObject.structureId))
            }
        }
        fetchStructure()
    }, [currentUser, default3DObject.structureId])

    // const [texturesCount, setTexturesCount] = useState<number>(0)
    // const [texturesCountArray, setTexturesCountArray] = useState<string[]>([])

    // =========================== TEXTURES ===========================

    // const handleTexturesCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    //     const value = parseInt(event.currentTarget.value)
    //     if ( ! isNaN(value)) {
    //         setTexturesCount(value)
    //         let newTexturesTypes: string[] = []
    //         for (let i = 0; i < value; i++) {
    //             newTexturesTypes.push('')
    //         }
    //         setTexturesCountArray(newTexturesTypes)
    //     }
    //     else {
    //         setTexturesCount(0)
    //     }
    // }

    // console.log(default3DObject)

    // =========================== ACTION HANDLERS ===========================

    const handleEditClick = () => {
        navigate('/modeller/model-edit/' + default3DObject.id, {
            state: {
                TDObject: default3DObject
            }
        })
    }
    // =========================== CHECKING CONDITIONS ===========================

    const canBeSentForApproval = () => {
        return (! loading) && default3DObject && default3DObject.status === 'finished'
    }

    const canBeFinished = () => {
        return (! loading) && default3DObject && default3DObject.status === 'unfinished'
    }

    const canModelBeEdited= () => {
        return default3DObject.status === 'unfinished'
    }

    const canModelBeDeleted = () => {
        return default3DObject.status === 'unfinished' || default3DObject.status === 'finished'
    }

    const canCreateNewVersion = () => {
        return default3DObject.status === 'unfinished'
    }

    // =========================== CONSTANTS ===========================

    const statusHelpText = HelpText.TD_OBJECT_API_STATUS

    const actionsHelpText = HelpText.FORM_ACTIONS_DETAIL

    const finishWarning = WarningText.FINISH_WARNING


    // =========================== RETURN ===========================

    return(
        <div className='TDObject-create-form'>
            <Form className='modeller-model-form' onSubmit={handleSubmit}>
                <div className='modeller-model-form-header-container'>
                    <h5 className='modeller-model-label'>
                        Informace o modelu
                    </h5>
                </div>
                <Row>
                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Název
                                <span className='modeller-form-required-star'>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                id='modelName'
                                value={default3DObject?.name}
                                disabled
                                readOnly
                            />
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Struktura
                                <span className='modeller-form-required-star'>*</span>
                            </Form.Label>
                            <div className='modeller-model-structure-input-container'>
                                <Form.Control
                                    type="text"
                                    id='structure'
                                    value={structure?.name || ''}
                                    disabled
                                    readOnly
                                />
                                {/*<Button*/}
                                {/*    className='modeller-model-structure-button'*/}
                                {/*    onClick={() => setStructureCreationModalOpen(true)}*/}
                                {/*    */}
                                {/*>*/}
                                {/*    Vytvořit novou*/}
                                {/*</Button>*/}
                            </div>
                        </Form.Group>
                    </Col>

                    {
                        <Col className='modeller-model-form-right-column'>
                            <Form.Group className="mb-3">
                                <div className='modeller-model-form-label-and-help'>
                                    <Form.Label>
                                        Stav modelu
                                    </Form.Label>
                                    <HelpPopover htmlContent={statusHelpText}/>
                                </div>
                                <Form.Control
                                    type="text"
                                    id='modelState'
                                    value={czechTDObjectStatus(default3DObject?.status)}
                                    required
                                    disabled
                                    readOnly
                                />
                            </Form.Group>
                        </Col>
                    }
                </Row>

                <div className='modeller-model-form-header-container'>
                    <h5>
                        Trasformace modelu
                    </h5>
                </div>

                <Row>
                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Translace</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelTranslationX' value={transformation.translation[0] || ''} disabled />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelTranslationY' value={transformation.translation[1]} disabled  />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelTranslationZ' value={transformation.translation[2]} disabled  />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Škálování</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="1" id='modelScaleX' value={transformation.scale[0]} disabled  />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="1" id='modelScaleY' value={transformation.scale[1]} disabled  />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="1" id='modelScaleZ' value={transformation.scale[2]} disabled  />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-right-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Rotace</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelRotation1' value={transformation.rotation[0]} disabled  />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelRotation2' value={transformation.rotation[1]} disabled  />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelRotation3' value={transformation.rotation[2]} disabled  />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="1" id='modelRotation4' value={transformation.rotation[3]} disabled  />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>

                <div className='modeller-model-form-header-container'>
                    <h5>
                        Trasformační síť modelu modelu
                    </h5>
                </div>

                <Row>
                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Translace</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelWebTranslationX' value={transformationWeb.translation[0]} disabled />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelWebTranslationY' value={transformationWeb.translation[1]} disabled />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelWebTranslationZ' value={transformationWeb.translation[2]} disabled />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Škálování</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="1" id='modelWebScaleX' value={transformationWeb?.scale[0]} disabled  />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="1" id='modelWebScaleY' value={transformationWeb.scale[1]} disabled   />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="1" id='modelWebScaleZ' value={transformationWeb.scale[2]} disabled   />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-right-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Rotace</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelWebRotation1' value={transformationWeb.rotation[0]} disabled  />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelWebRotation2' value={transformationWeb.rotation[1]} disabled />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="0" id='modelWebRotation3' value={transformationWeb.rotation[2]} disabled />
                                </Col>
                                <Col>
                                    <Form.Control readOnly type="number" placeholder="1" id='modelWebRotation4' value={transformationWeb.rotation[3]} disabled />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <div className='modeller-model-form-header-container'>
                    <h5 style={{margin: "0 1rem 0 0"}}>
                        Akce
                    </h5>
                    <HelpPopover htmlContent={actionsHelpText} />
                </div>

                <Row>
                    <Col className='modeller-model-submit-button-container modeller-model-form-column'>
                        <Button
                            variant="primary"
                            type="submit"
                            className='modeller-model-submit-button flex-button'
                            disabled={ ! canModelBeEdited() || loading}
                            // disabled
                            onClick={handleEditClick}
                            name='editExisting'
                        >
                            <EditIcon />
                            Upravit
                        </Button>
                    </Col>
                    <Col className='modeller-model-submit-button-container modeller-model-form-column'>
                        <Button
                            variant="primary"
                            type="submit"
                            className='modeller-model-submit-button flex-button'
                            disabled={ ! canCreateNewVersion() || loading}
                            // disabled
                            name='newVersionExisting'
                        >
                            <NewIcon />
                            Nová verze
                        </Button>
                    </Col>
                    <Col className='modeller-model-submit-button-container modeller-model-form-column modeller-model-warning-button'>
                        {
                            default3DObject.status === 'unfinished' &&
                            <WarningPopover htmlContent={finishWarning} />
                        }
                        <Button
                            variant="primary"
                            type="submit"
                            className='modeller-model-submit-button flex-button'
                            disabled={! canBeFinished() || loading}
                            // disabled
                            name='finishExisting'
                        >
                            <ExclamationMarkLightIcon />
                            Dokončit
                        </Button>
                        {
                            default3DObject.status === 'unfinished' &&
                            <WarningPopover htmlContent={finishWarning} />
                        }
                    </Col>
                    <Col className='modeller-model-submit-button-container modeller-model-form-column'>
                        <Button
                            variant="primary"
                            type="submit"
                            className='modeller-model-submit-button flex-button'
                            disabled={! canBeSentForApproval() || loading}
                            // disabled
                            name='sendApprovalExisting'
                        >
                            <SendIcon />
                            Odeslat
                        </Button>
                    </Col>
                    <Col className='modeller-model-submit-button-container modeller-model-form-column'>
                        <Button
                            variant="primary"
                            type="submit"
                            className='modeller-model-submit-button flex-button'
                            name='openFilesModal'
                        >
                            <FilesIcon />
                            Soubory
                        </Button>
                    </Col>
                    <Col className='modeller-model-submit-button-container modeller-model-form-right-column'>
                        <Button
                            variant="danger"
                            type="submit"
                            className='modeller-model-submit-button flex-button'
                            disabled={! canModelBeDeleted() || loading}
                            // disabled
                            name='deleteExisting'
                        >
                            <DeleteIcon />
                            Smazat
                        </Button>
                    </Col>
                </Row>
            </Form>
            {/*<StructureCreationModal open={structureCreationModalOpen} setOpen={setStructureCreationModalOpen}/>*/}
        </div>
    )
}

export default ModelDetailForm
