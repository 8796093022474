import React, { useEffect, useState } from "react";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import NewIcon from "../../../components/icons/new-icon.component";
import TDObjectDropdown from "../../../components/TDObject-dropdown/TDObject-dropdown.component";
import useUserContext from "../../../contexts/user.context";
import ErrorAndSuccessModals from "../../../components/error-and-success-modals/error-and-success-modals.component";
import {
    delete3DObjectVersion,
    fetch3DObjectsByCreatorId
} from "../../../utils/api-fetchers/TDObject/TDObject-api-fetcher";
import { versionArrayToString } from "../../../utils/formatters/version-formatter.util";

import { I3DObject } from "../../../interfaces/I3DObject";
import './modeller-all-creations.styles.scss'

const ModellerAllCreations = () => {

    const { currentUser } = useUserContext()

    const [TDObjects, setTDObjects] = useState<I3DObject[]>([])
    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false)
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false)

    let userApiToken = ''

    if (currentUser)
        userApiToken = currentUser.token

    useEffect(() => {
        const get3DObjects = async () => {
            if (currentUser) {
                const objects = await fetch3DObjectsByCreatorId(userApiToken, currentUser.id)
                setTDObjects(objects)
                // console.log(objects)
            }
        }
        get3DObjects()
    }, [currentUser, userApiToken])

    const handleObjectDelete = async (entity: I3DObject) => {
        try {
            if (currentUser) {
                await delete3DObjectVersion(currentUser?.token, entity.id, (typeof entity.version ==='string') ? entity.version : versionArrayToString(entity?.version))
                setTDObjects(await fetch3DObjectsByCreatorId(currentUser.token, currentUser.id))
            }
            setIsSuccessAlertOpen(true)
        } catch (e) {
            console.log('catching error ', e)
            setIsErrorAlertOpen(true)
        }
    }

    const handleCloseErrorAlert = () => {
        setIsErrorAlertOpen(false);
    };

    const handleCloseSuccessAlert = () => {
        setIsSuccessAlertOpen(false)
    };


    return (
        <div className='modeller-models-overview-container'>
            <div className='modeller-models-overview-header-container'>
                <h2>
                    Mé modely
                </h2>
                <h5>Modely, jež jsem vytvořil</h5>
            </div>
            <div className='modeller-overview-button-container'>
                <Button variant='primary' href='/modeller/model-creation' className='flex-button'><NewIcon />Vytvořit model</Button>
            </div>
            {
                TDObjects.length === 0
                ?
                    <Alert variant='info'>Nevytvořili jste žádné modely.</Alert>
                :
                    <div>
                        {
                            TDObjects.map(TDObject =>
                                <TDObjectDropdown key={TDObject.id} TDObject={TDObject} handleDelete={handleObjectDelete} />
                            )
                        }
                    </div>
            }
            <ErrorAndSuccessModals
                isErrorAlertOpen={isErrorAlertOpen}
                handleCloseErrorAlert={handleCloseErrorAlert}
                isSuccessAlertOpen={isSuccessAlertOpen}
                handleCloseSuccessAlert={handleCloseSuccessAlert}
            />
        </div>
    )
}

export default ModellerAllCreations
