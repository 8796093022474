import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Button from "react-bootstrap/Button";

import MagnifyingGlassIcon from "../../../components/icons/magnifying-glass-icon.component";
import EditIcon from "../../../components/icons/edit-icon.component";
import DeleteIcon from "../../../components/icons/delete-icon.component";
import Information3DObject from "../../../components/TDObject-information/TDObject-information.component";
import ModelViewer from "../../../components/model-viewer/model-viewer.component";
import useUserContext from "../../../contexts/user.context";
import HelpPopover from "../../../components/help-popover/help-popover.component";
import ErrorAndSuccessModals from "../../../components/error-and-success-modals/error-and-success-modals.component";
import { delete3DObjectVersion } from "../../../utils/api-fetchers/TDObject/TDObject-api-fetcher";
import { HelpText } from "../../../enums/help-text.enum";

import './model-detail-view.styles.scss'

const ModelDetailView = () => {

    const location = useLocation()
    const { state } = location
    const { TDObject } = state

    const navigate = useNavigate()
    const { currentUser } = useUserContext()

    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false)
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false)

    // ================================ ACTION HANDLERS ================================

    const handleDetailClick = () => {
        navigate('/modeller/model-detail-form/' + TDObject.id, {
                state:
                    {
                        TDObject: TDObject
                    }
            }
        )
    }
    const handleEditClick = () => {
        navigate('/modeller/model-edit/' + TDObject.id, {
                state:
                    {
                        TDObject: TDObject
                    }
            }
        )
    }

    const handleDeleteClick = async () => {
        try {
            if (currentUser)
                await delete3DObjectVersion(currentUser?.token, TDObject.id, TDObject.version)
            setIsSuccessAlertOpen(true)
        } catch (e) {
            console.log('catching error ', e)
            setIsErrorAlertOpen(true)
        }
    }

    // ================================ MODALS HANDLERS ================================

    const handleCloseErrorAlert = () => {
        setIsErrorAlertOpen(false);
    };

    const handleCloseSuccessAlert = () => {
        setIsSuccessAlertOpen(false)
        navigate('/modeller/all-creations')
    };

    // ================================ CONSTANTS ================================

    const helpText = HelpText.TD_OBJECT_VIEW_ACTIONS

    // ================================ CHECKING CONDITIONS ================================

    const canModelBeDeleted = () => {
        return TDObject.status === 'unfinished' || TDObject.status === 'finished'
    }
    const canModelBeEdited= () => {
        return TDObject.status === 'unfinished'
    }

    // ================================ RETURN ================================

    return(
        <div className='modeller-model-detail-view-container'>
            <div className='modeller-model-detail-view-header-container'>
                <h2>
                    Detail uloženého modelu
                </h2>
            </div>

            <Information3DObject TDObject={TDObject} />
            <div  className='modeller-model-detail-view-model-and-buttons-container'>
                {
                    TDObject && <ModelViewer modelId={TDObject.modelId} modelFormat={TDObject.format} />
                }

                <div className='modeller-model-detail-view-info-container'>
                    <div className='center-flex'>
                        <h5 className='margin-right no-margin-bottom'>
                            Dostupné akce
                        </h5>
                        <HelpPopover htmlContent={helpText}/>
                    </div>
                    <div className='modeller-model-detail-view-info-buttons-container'>
                        <Button className='flex-button' size='lg' onClick={handleDetailClick}>
                            <MagnifyingGlassIcon />
                            Detail
                        </Button>
                        <Button className='flex-button' size='lg' onClick={handleEditClick} disabled={ ! canModelBeEdited()}>
                            <EditIcon />
                            Upravit
                        </Button>
                        <Button className='flex-button' size='lg' variant='danger' onClick={handleDeleteClick} disabled={ ! canModelBeDeleted()}>
                            <DeleteIcon />
                            Smazat
                        </Button>
                    </div>
                </div>

            </div>
            <ErrorAndSuccessModals
                isErrorAlertOpen={isErrorAlertOpen}
                handleCloseErrorAlert={handleCloseErrorAlert}
                isSuccessAlertOpen={isSuccessAlertOpen}
                handleCloseSuccessAlert={handleCloseSuccessAlert}
            />
        </div>
    )
}

export default ModelDetailView
