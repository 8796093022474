import ENV from "../../../env";

const apiUrl = ENV.privateApiUrl

export const logIn = async (tokenId: string, clientId: string) => {
    return await fetch(apiUrl + "/auth/googlesignin", {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
            token: tokenId,
            clientId: clientId
        })
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
}
