import { Route, Routes } from 'react-router-dom'

import ApproverTasks from "./tasks-overview/approver-tasks.component";
import ApproverAllApprovals from "./approvals-overview/approver-approvals-overview.component";
import ApproverIterationApproval from "./iteration-approval/approver-iteration-approval.component";

const ApproverComponent = () => {
    return (
        <Routes>
            <Route path='tasks' element={<ApproverTasks />}/>
            <Route path='approvals' element={<ApproverAllApprovals  />}/>
            <Route path='iteration-approval/*' element={<ApproverIterationApproval />}/>
        </Routes>
    )
}

export default ApproverComponent
