import React from "react";

import Button from "react-bootstrap/Button";

import './logout.styles.scss'

interface IProps {
    handleLogout: () => void
}

const LogOutComponent = (props: IProps) => {
    const { handleLogout } = props

    return (
        <div className='logout-component'>
            <Button variant='outline-light' onClick={handleLogout}>Odhlásit</Button>
        </div>
    )
}

export default LogOutComponent;
