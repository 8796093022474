import ENV from "../../../env";
import { getMimeTypeFromFormat } from "../../parsers/file-format-parser";

const apiUrl = ENV.privateApiUrl

export const uploadPhotoToStreet = async (token: string, streetId: string, photo: any) => {

    let formData = new FormData()
    if (! photo || ! photo.name)
        throw new Error("Missing photo!")

    const fileName: string = photo.name
    const format = photo.name.split('.').pop().toUpperCase()
    const mimeType = getMimeTypeFromFormat(format)

    if ( ! mimeType)
        throw new Error("Invalid MimeType!")

    const file = new Blob([photo], { type: mimeType });

    formData.append('photo', file, fileName);

    return (fetch(apiUrl + "/areas/" + streetId + "/photos", {
        headers:{
            'Accept': 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "POST",
        body: formData
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}

export const uploadPhotoToStructure = async (token: string, structureId: string, photo: any) => {

    let formData = new FormData()
    if (! photo || ! photo.name)
        throw new Error("Missing photo!")

    const fileName: string = photo.name
    const format = photo.name.split('.').pop().toUpperCase()
    const mimeType = getMimeTypeFromFormat(format)

    if ( ! mimeType)
        throw new Error("Invalid MimeType!")

    const file = new Blob([photo], { type: mimeType });

    formData.append('photo', file, fileName);

    return (fetch(apiUrl + "/structures/" + structureId + "/photos", {
        headers:{
            'Accept': 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "POST",
        body: formData
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}

export const deletePhotoFromStreet = async (token: string, streetId: string, photoId: string) => {
    return (fetch(apiUrl + "/areas/" + streetId + "/photos/" + photoId, {
        headers:{
            'Accept': 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "DELETE"
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response
    }));
}

export const deletePhotoFromStructure = async (token: string, structureId: string, photoId: string) => {
    return (fetch(apiUrl + "/structures/" + structureId + "/photos/" + photoId, {
        headers:{
            'Accept': 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "DELETE"
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response
    }));
}

export const fetchPhoto = async (href: string, token: string) => {
      const response = await fetch(`${apiUrl}${href}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }

      return await response.blob()
}
