import React, { useEffect, useState } from "react";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";

import useUserContext from "../../contexts/user.context";
import ApprovalIterationName from "../approval-iteration-name/approval-iteration-name.component";
import IterationCommentsModal from "../iteration-comments-modal/iteration-comments-modal.component";
import HelpPopover from "../help-popover/help-popover.component";
import useTasksCountContext from "../../contexts/tasks-count.context";
import {
    finalizeModelIterationAdmin,
    revokeFinalizeModelIterationAdmin
} from "../../utils/api-fetchers/aproval-process/aproval-process-api-fetcher";
import { HelpText } from "../../enums/help-text.enum";
import { fetchUserById } from "../../utils/api-fetchers/users/user-api-fetcher";
import { fetchModelCommentsById } from "../../utils/api-fetchers/comment/comment-api-fetcher";
import { recomputeTasksNumber } from "../../utils/context-maintainers/tasks-count-context.maintainer";

import { IApprovalProcessModelIteration } from "../../interfaces/IApprovalProcessModelIteration";
import { IComment } from "../../interfaces/IComment";
import IUser from "../../interfaces/IUser";

import './approval-iteration-admin-result.styles.scss'
import CheckIcon from "../icons/check-icon.component";
import MagnifyingGlassIcon from "../icons/magnifying-glass-icon.component";

interface IProps {
    modelIteration: IApprovalProcessModelIteration,
    setNewIteration: (id: number) => void,
    setIsSuccessAlertOpen: (value: boolean) => void
    setIsErrorAlertOpen: (value: boolean) => void
}

const ApprovalIterationAdminResult = (props: IProps) => {

    const { modelIteration, setNewIteration, setIsErrorAlertOpen, setIsSuccessAlertOpen } = props
    const { currentUser } = useUserContext()
    const { setTasksCount } = useTasksCountContext()

    const [approvingHistorian, setApprovingHistorian] = useState<IUser | undefined>(undefined)
    const [approvingGraphician, setApprovingGraphician] = useState<IUser | undefined>(undefined)

    const [iterationComments, setIterationComments] = useState<IComment[] | undefined>(undefined)
    const [commentsOpen, setCommentsOpen] = useState(false)

    // =============================== useEffects ===============================

    useEffect(() => {
        const fetchApprovers = async () => {
            if (currentUser) {
                if (modelIteration.historianDecliningId)
                    setApprovingHistorian(await fetchUserById(currentUser?.token, modelIteration.historianDecliningId))
                if (modelIteration.historianApprovingId)
                    setApprovingHistorian(await fetchUserById(currentUser?.token, modelIteration.historianApprovingId))
                if (modelIteration.graphicianDecliningId)
                    setApprovingGraphician(await fetchUserById(currentUser?.token, modelIteration.graphicianDecliningId))
                if (modelIteration.graphicianApprovingId)
                    setApprovingGraphician(await fetchUserById(currentUser?.token, modelIteration.graphicianApprovingId))
            }
        }
        fetchApprovers()
    }, [currentUser, modelIteration])

    useEffect(() => {
        const fetchIterationComments = async () => {
            if (currentUser) {
                setIterationComments(await fetchModelCommentsById(currentUser.token, modelIteration.id))
            }
        }
        fetchIterationComments()
    }, [currentUser, modelIteration.id])

    // =============================== MODAL HANDLER ===============================

    const handleShowComments = () => {
        setCommentsOpen(true)
    }

    // ===============================  ACTIONS HANDLERS ===============================

    const handleRevoke = async () => {

        try {
            if (currentUser) {
                await revokeFinalizeModelIterationAdmin(currentUser.token, modelIteration.id)
                setNewIteration(modelIteration.id)
                setTasksCount(await recomputeTasksNumber(currentUser))
                setIsSuccessAlertOpen(true)
                // await revokeModelIterationRejectionAsRole(currentUser.token, modelIteration.id, 'historian')
            }
        } catch (e) {
            console.log('catching error', e)
            setIsErrorAlertOpen(true)
        }
    }

    const handlePublish = async () => {
        try {
            if (currentUser) {
                await finalizeModelIterationAdmin(currentUser.token, modelIteration.id)
                setNewIteration(modelIteration.id)
                setTasksCount(await recomputeTasksNumber(currentUser))
                setIsSuccessAlertOpen(true)
                // await revokeModelIterationRejectionAsRole(currentUser.token, modelIteration.id, 'historian')
            }
        } catch (e) {
            console.log('catching error', e)
            setIsErrorAlertOpen(true)
        }
    }

    // =============================== CHECKING CONDITIONS ===============================

    const commentsExist = () => {
        return iterationComments && iterationComments.length > 0
    }

    const unPublishHelpText = HelpText.UN_PUBLISH

    const publishHelpText = HelpText.PUBLISH


    // =============================== RETURN ===============================
    return(
        <div className='approval-iteration-admin-result-container'>
            <ApprovalIterationName iteration={modelIteration} />
            <Table striped bordered size="sm">
                <thead>
                <tr>
                    <th><h6><b>Schvalující grafik</b></h6></th>
                    <th><h6><b>Schvalující historik</b></h6></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th><h6>{approvingGraphician?.name}</h6></th>
                    <th><h6>{approvingHistorian?.name}</h6></th>
                </tr>
                </tbody>
            </Table>
            {
                currentUser &&
                <div className='approval-iteration-role-result-row-container'>
                    {
                        // getResultInformation()
                    }
                </div>
            }
            <hr className='my-hr'/>
            <div className='approval-iteration-admin-result-row-container-centered'>
                <Col className='approval-iteration-admin-button-and-help-container'>
                    {
                        modelIteration.finalizedDate
                        ? <>
                                <Button
                                    className='approver-iteration-approval-info-button'
                                    size='lg'
                                    onClick={handleRevoke}>
                                    Privatizovat
                                </Button>
                                <HelpPopover htmlContent={unPublishHelpText} />
                            </>
                            : <>
                                <Button
                                    className='approver-iteration-approval-info-button flex-button'
                                    size='lg'
                                    onClick={handlePublish}>
                                    <CheckIcon />
                                    Publikovat
                                </Button>
                                <HelpPopover htmlContent={publishHelpText} />
                            </>
                    }
                </Col>
                <Col>
                    <Button className='approver-iteration-approval-info-button flex-button' hidden={! commentsExist()} onClick={handleShowComments}>
                        <MagnifyingGlassIcon />
                        Komentáře
                    </Button>
                </Col>
            </div>
            {
                iterationComments &&
                <IterationCommentsModal open={commentsOpen} setOpen={setCommentsOpen} comments={iterationComments} />

            }
        </div>
    )
}

export default ApprovalIterationAdminResult
