import { useEffect, useState } from "react";

import Alert from "react-bootstrap/Alert";

import ApprovalProcessDropdown from "../../../components/approval-process-dropdown/approval-process-dropdown.component";
import useUserContext from "../../../contexts/user.context";
import { fetchUserApprovals } from "../../../utils/api-fetchers/aproval-process/aproval-process-api-fetcher";
import { IApprovalProcessDetail } from "../../../interfaces/IApprovalProcessDetail";

import './modeller-approvals.styles.scss'

const ModellerAllApprovals = () => {
    const { currentUser } = useUserContext()

    const [approvals, setApprovals] = useState<IApprovalProcessDetail[]>([])

    let userApiToken = ''

    if (currentUser)
        userApiToken = currentUser.token

    useEffect(() => {
        const getApprovals = async () => {
            if (currentUser) {
                // const approvals = await fetchUserApprovals(userApiToken, currentUser.id, currentUser.role)
                const approvals = await fetchUserApprovals(userApiToken)
                setApprovals(approvals)
                // console.log(approvals)
            }
        }
        getApprovals()
    }, [currentUser, userApiToken])

    return (
        <div className='modeller-approvals-container'>
            <div className='modeller-approvals-header-container'>
                <h2>
                    Schvalovací procesy
                </h2>
                <h5>Procesy, jichž jsem členem</h5>
            </div>
            {
                approvals.length === 0
                ? <Alert variant='info'>Žádné procesy k zobrazení.</Alert>
                : approvals.map(approval =>
                    <ApprovalProcessDropdown
                        key={approval.id}
                        approval={approval}
                        displayDecided={true}
                        modeller={true}
                    />)
            }
        </div>
    )
}

export default ModellerAllApprovals
