export enum FormActions {
    EDIT_EXISTING = 'editExisting',
    NEW_VERSION_EXISTING = 'newVersionExisting',
    FINISH_EXISTING = 'finishExisting',
    SEND_APPROVAL_EXISTING = 'sendApprovalExisting',
    DELETE_EXISTING = 'deleteExisting',
    SAVE_NEW = 'saveNew',
    SAVE_FINISH_NEW = 'saveFinishNew',
    SAVE_FINISH_SEND_APPROVAL_NEW = 'saveFinishSendApprovalNew',
    OPEN_FILES = 'openFilesModal'
}
