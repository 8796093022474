import { IApprovalProcess } from "../../../interfaces/IApprovalProcess";
import { IApprovalProcessDetail } from "../../../interfaces/IApprovalProcessDetail"
import { IApprovalProcessModelIteration } from "../../../interfaces/IApprovalProcessModelIteration";

import ENV from "../../../env";

const apiUrl = ENV.privateApiUrl

export const fetchApprovalById = async (processId: number, token: string): Promise<IApprovalProcessDetail> => {
    return fetch(apiUrl + "/approvals/" + processId,{
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "GET"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
}

const fetchUserApprovalsApi = async (token: string): Promise<IApprovalProcess[]> => {
    return fetch(apiUrl + "/approvals/", {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "GET"
        }
    ).then(response => {
        if (response.status == 404)
            return []
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
}

export const fetchApprovalMembers = async (token: string, approvalId: number) => {
    return fetch(apiUrl + "/approvals/" + approvalId + "/users", {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "GET"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json()
    })
}

export const fetchUserApprovals = async (token: string): Promise<IApprovalProcessDetail[]> => {
    const userApprovals = await fetchUserApprovalsApi(token)

    const processesWithDetailPromises = userApprovals.map(process => fetchApprovalById(process.id, token))
    return await Promise.all(processesWithDetailPromises)
}

export const approveModelIterationAsRole = async (token: string, modelIterationId: number, userRole: string) => {
    return fetch(apiUrl + "/approvals/iterations/model/" + modelIterationId + "/approval?as=" + userRole, {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "POST"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}

export const declineModelIterationAsRole = async (token: string, modelIterationId: number, userRole: string) => {
    return fetch(apiUrl + "/approvals/iterations/model/" + modelIterationId + "/rejection?as=" + userRole, {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "POST"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}

export const revokeModelIterationApprovalAsRole = async (token: string, modelIterationId: number, userRole: string) => {
    return fetch(apiUrl + "/approvals/iterations/model/" + modelIterationId + "/approval?as=" + userRole, {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "DELETE"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}

export const revokeModelIterationRejectionAsRole = async (token: string, modelIterationId: number, userRole: string) => {
    return fetch(apiUrl + "/approvals/iterations/model/" + modelIterationId + "/rejection?as=" + userRole, {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "DELETE"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}

export const finalizeModelIterationAdmin = async (token: string, modelIterationId: number) => {
    return fetch(apiUrl + "/approvals/iterations/model/" + modelIterationId + "/finalization", {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "POST"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}
export const revokeFinalizeModelIterationAdmin = async (token: string, modelIterationId: number) => {
    return fetch(apiUrl + "/approvals/iterations/model/" + modelIterationId + "/finalization", {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "DELETE"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}

export const addModelIterationComment = async (token: string, modelIterationId: number, comment: string) => {
    return fetch(apiUrl + "/comments/model/" + modelIterationId, {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "POST",
            body: JSON.stringify({
                type: "text",
                content: {
                    text: comment
                }
            })
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
}

export const fetchModelIterationById = async (token: string, modelIterationId: number): Promise<IApprovalProcessModelIteration> => {
    return fetch(apiUrl + "/approvals/iterations/model/" + modelIterationId + "?withComments=true", {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "GET"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
}

export const createModelIteration = async (token: string, approvalId: number, iterationName: string, TDObjectId: string): Promise<IApprovalProcessModelIteration> => {

    let formData = new FormData()
    formData.append('name', iterationName)
    formData.append('tdObjectId', TDObjectId)

    return fetch(apiUrl + "/approvals/" + approvalId + "/iterations/model", {
            headers:{
                'Accept': 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "POST",
            body: formData
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
}
