import { createContext, ReactNode, useContext, useState } from "react";

import IUser from "../interfaces/IUser";

const UserContext = createContext<{currentUser: IUser | null, setCurrentUser: (user: any) => void}>({
    currentUser: null,
    setCurrentUser: () => null
});

export default function useUserContext() {
    return useContext(UserContext)
}

export const UserProvider = ({children}: {children: ReactNode}) => {
    const [currentUser, setCurrentUser] = useState(null)
    const value = { currentUser, setCurrentUser }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
