import { useEffect, useState } from "react";

import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";

import { fetchUserById } from "../../utils/api-fetchers/users/user-api-fetcher";
import { fetchModelCommentsById } from "../../utils/api-fetchers/comment/comment-api-fetcher";
import {
    revokeModelIterationApprovalAsRole,
    revokeModelIterationRejectionAsRole
} from "../../utils/api-fetchers/aproval-process/aproval-process-api-fetcher";
import { recomputeTasksNumber } from "../../utils/context-maintainers/tasks-count-context.maintainer";
import ApprovalIterationComment from "../approval-iteration-comment/approval-iteration-comment.component";
import ApprovalIterationName from "../approval-iteration-name/approval-iteration-name.component";
import useUserContext from "../../contexts/user.context";
import useTasksCountContext from "../../contexts/tasks-count.context";

import { IApprovalProcessModelIteration } from "../../interfaces/IApprovalProcessModelIteration";
import { IComment } from "../../interfaces/IComment";
import { I3DObject } from "../../interfaces/I3DObject";
import IUser from "../../interfaces/IUser";

import './approval-iteration-role-result.styles.scss'
import ReloadIcon from "../icons/reload-icon.component";

interface IProps {
    modelIteration: IApprovalProcessModelIteration,
    setNewIteration: (id: number) => void,
    setIsSuccessAlertOpen: (value: boolean) => void,
    setIsErrorAlertOpen: (value: boolean) => void,
    TDObject: I3DObject | undefined
}

const ApprovalIterationRoleResult = (props: IProps) => {

    const { modelIteration, setNewIteration, setIsErrorAlertOpen, setIsSuccessAlertOpen, TDObject } = props
    const { currentUser } = useUserContext()
    const { setTasksCount } = useTasksCountContext()

    const [approvingHistorian, setApprovingHistorian] = useState<IUser | undefined>(undefined)
    const [approvingGraphician, setApprovingGraphician] = useState<IUser | undefined>(undefined)

    const [iterationComments, setIterationComments] = useState<IComment[] | undefined>(undefined)

    // =============================== useEffects ===============================

    useEffect(() => {
        const fetchApprovers = async () => {
            if (currentUser) {
                if (modelIteration.historianDecliningId)
                    setApprovingHistorian(await fetchUserById(currentUser?.token, modelIteration.historianDecliningId))
                if (modelIteration.historianApprovingId)
                    setApprovingHistorian(await fetchUserById(currentUser?.token, modelIteration.historianApprovingId))
                if (modelIteration.graphicianDecliningId)
                    setApprovingGraphician(await fetchUserById(currentUser?.token, modelIteration.graphicianDecliningId))
                if (modelIteration.graphicianApprovingId)
                    setApprovingGraphician(await fetchUserById(currentUser?.token, modelIteration.graphicianApprovingId))
            }
        }
        fetchApprovers()
    }, [currentUser, modelIteration])

    useEffect(() => {
        const fetchIterationComments = async () => {
            if (currentUser) {
                setIterationComments(await fetchModelCommentsById(currentUser.token, modelIteration.id))
            }
        }
        fetchIterationComments()
    }, [currentUser, modelIteration.id])


    // =============================== FORMATTERS ===============================

    const getApproverByRole = () => {
        if (currentUser?.role === 'historian')
            return approvingHistorian?.name
        if (currentUser?.role === 'approver')
            return approvingGraphician?.name
    }

    const getHistorianVerdictCzech = () => {
        if (modelIteration.historianDecliningId) return "Zamítnuto"
        if (modelIteration.historianApprovingId) return "Schváleno"
        return "Neohodnoceno"
    }

    const getGraphicianVerdictCzech = () => {
        if (modelIteration.graphicianDecliningId) return "Zamítnuto"
        if (modelIteration.graphicianApprovingId) return "Schváleno"
        return "Neohodnoceno"
    }

    const getResultInformation = () => {
        if (currentUser?.role === 'historian') {
            return <h5>Tuto iteraci již ohodnotil historik {getApproverByRole()}</h5>
        }
        if (currentUser?.role === 'approver')
            return <h5>Tuto iteraci již ohodnotil grafik {getApproverByRole()}</h5>
        return <></>
    }

    // =============================== CHECKING CONDITIONS ===============================

    const isDecidedByCurrentUser = () => {
        if (currentUser) {
            if (currentUser.role === 'historian')
                return currentUser.id === approvingHistorian?.id
            if (currentUser.role === 'approver')
                return currentUser.id === approvingGraphician?.id
        }
        return false
    }

    const isFinalized = () => {
        return TDObject && TDObject.status === 'finalized'
    }

    // =============================== ACTIONS HANDLERS ===============================

    const handleRevoke = async () => {

        try {
            if (currentUser) {
                if (currentUser.role === 'historian') {
                    if (modelIteration.historianDecliningId === currentUser.id)
                        await revokeModelIterationRejectionAsRole(currentUser.token, modelIteration.id, 'historian')
                    else if (modelIteration.historianApprovingId === currentUser.id)
                        await revokeModelIterationApprovalAsRole(currentUser.token, modelIteration.id, 'historian')
                } else if (currentUser.role === 'approver') {
                    if (modelIteration.graphicianDecliningId === currentUser.id)
                        await revokeModelIterationRejectionAsRole(currentUser.token, modelIteration.id, 'graphician')
                    else if (modelIteration.graphicianApprovingId === currentUser.id)
                        await revokeModelIterationApprovalAsRole(currentUser.token, modelIteration.id, 'graphician')
                }
                // await revokeModelIterationApprovalAsRole(currentUser.token, modelIteration.id, 'historian')
                setNewIteration(modelIteration.id)
                setTasksCount(await recomputeTasksNumber(currentUser))
                setIsSuccessAlertOpen(true)
                // await revokeModelIterationRejectionAsRole(currentUser.token, modelIteration.id, 'historian')
            }
        } catch (e) {
            console.log('catching error', e)
            setIsErrorAlertOpen(true)
        }
    }

    // =============================== RETURN ===============================

    return(
        <div className='approval-iteration-role-result-container'>
            <ApprovalIterationName iteration={modelIteration} />
            <Table striped bordered size="sm">
                <thead>
                    <tr>
                        <th><h6><b>Hodnocení grafika</b></h6></th>
                        <th><h6><b>Hodnocení historika</b></h6></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th><h6>{getGraphicianVerdictCzech()}</h6></th>
                        <th><h6>{getHistorianVerdictCzech()}</h6></th>
                    </tr>
                </tbody>
            </Table>
            {
                currentUser &&
                    <div className='approval-iteration-role-result-row-container'>
                        {
                            getResultInformation()
                        }
                    </div>
            }
            <div className='approval-iteration-role-result-row-container'>

                <h6>Komentáře:</h6>
                <div>
                    {
                        iterationComments && iterationComments.length > 0
                            ? iterationComments.map(comment => <ApprovalIterationComment key={comment.id} comment={comment} />)
                            : <h6>Nejsou žádné komentáře.</h6>
                    }
                </div>
            </div>
            <hr className='my-hr'/>
            {
                isDecidedByCurrentUser() &&
                <div className='approval-iteration-role-result-row-container-centered'>
                    <h6>Zde můžete vrátit své hodnocení do stavu Neohodnoceno (nelze u zveřejněných modelů).</h6>
                    <div className='approval-iteration-role-result-row-button-container'>
                        <Button onClick={handleRevoke} disabled={isFinalized()} className='flex-button'><ReloadIcon/>Vzít zpět</Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default ApprovalIterationRoleResult
