import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import CubeIcon from "../icons/cube-icon.component";
import MagnifyingGlassIcon from "../icons/magnifying-glass-icon.component";
import EditIcon from "../icons/edit-icon.component";
import DeleteIconNarrow from "../icons/delete-icon-narrow.component";
import useUserContext from "../../contexts/user.context";
import ErrorAndSuccessModals from "../error-and-success-modals/error-and-success-modals.component";
import HelpPopover from "../help-popover/help-popover.component";
import { czechTDObjectStatus } from "../../enums/TDObject-status.enum";
import { fetchStructureById } from "../../utils/api-fetchers/structure/structure-api-fetcher";
import { Iso8601ToLocaleDate } from "../../utils/formatters/date-formatter.util";
import { HelpText } from "../../enums/help-text.enum";
import { I3DObject } from "../../interfaces/I3DObject";
import { IStructure } from "../../interfaces/IStructure";

import './TDObject-dropdown.styles.scss'


interface IProps {
    TDObject: I3DObject,
    handleDelete: (entity: I3DObject) => void
}

const TDObjectDropdown = (props: IProps) => {

    const navigate = useNavigate()

    const { TDObject, handleDelete } = props
    const { currentUser } = useUserContext()

    const [modelStructure, setModelStructure] = useState<IStructure | null>(null)
    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false)
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false)

    // ================================ useEffects ================================

    useEffect(() => {
        const fetchModelStructure = async () => {
            if (currentUser) {
                const structure = await fetchStructureById(currentUser.token, TDObject.structureId)
                setModelStructure(structure)
            }
        }

        fetchModelStructure()
    }, [TDObject, currentUser])

    // ================================ CHECKING CONDITIONS ================================

    const canModelBeDeleted = () => {
        return TDObject.status === 'unfinished' || TDObject.status === 'finished'
    }

    const canModelBeEdited= () => {
        return TDObject.status === 'unfinished'
    }

    // ================================ ACTIONS HANDLERS ================================

    const handleEditClick = () => {
        navigate('/modeller/model-edit/' + TDObject.id, {
            state:
                {
                    TDObject: TDObject
                }
        })
    }
    const handleDeleteClick = () => {
        handleDelete(TDObject)
    }

    const handleDetailClick = () => {
        navigate('/modeller/model-detail-form/' + TDObject.id, {
            state:
                {
                    TDObject: TDObject
                }
        })
    }
    const handleModelClick = () => {
        navigate('/modeller/model-detail-view/' + TDObject.id, {
            state:
                {
                    TDObject: TDObject
                }
        })
    }

    // ================================ MODALS HANDLERS ================================

    const handleCloseErrorAlert = () => {
        setIsErrorAlertOpen(false);
    };

    const handleCloseSuccessAlert = () => {
        setIsSuccessAlertOpen(false)
    };

    // ================================ CONSTANTS ================================

    const actionsHelpText = HelpText.TD_OBJECT_DROPDOWN_ACTIONS

    const statusHelpText = HelpText.TD_OBJECT_API_STATUS

    const versionHelpText = HelpText.VERSION


    // ================================ RETURN ================================
    return(
        <div className='TDObject-dropdown-container'>
            <Accordion key={TDObject.id}>
                <Accordion.Item eventKey="0">

                    <Accordion.Header>
                        <Col>
                            <span className='TDObject-dropdown-accordion-item-column-name'>
                                {'Název:'}
                            </span>
                                <span>
                                {TDObject.name}
                            </span>
                        </Col>

                        <Col>
                            <span className='TDObject-dropdown-accordion-item-column-name'>
                                {'Struktura:'}
                            </span>
                            <span>
                                {
                                    modelStructure && modelStructure.name
                                }
                            </span>
                        </Col>

                    </Accordion.Header>

                    <Accordion.Body>
                        <Row className='TDObject-dropdown-accordion-item-body-row'>
                            <Col className='TDObject-dropdown-column-space-between'>
                                <h6>Stav</h6>
                                <HelpPopover htmlContent={statusHelpText}/>
                            </Col>
                            <Col>
                                <h6>Vytvořeno</h6>
                            </Col>
                            <Col className='TDObject-dropdown-column-space-between'>
                                <h6>Verze</h6>
                                <HelpPopover htmlContent={versionHelpText}/>
                            </Col>
                            <Col className='TDObject-dropdown-column-space-between'>
                                <h6>
                                    Akce
                                 </h6>
                                <HelpPopover htmlContent={actionsHelpText}/>
                            </Col>
                        </Row>

                        <hr />

                        <Row className='TDObject-dropdown-accordion-item-body-row'>
                            <Col>
                                {czechTDObjectStatus(TDObject.status)}
                            </Col>
                            <Col>
                                {
                                    Iso8601ToLocaleDate(TDObject.createdDate)
                                }
                            </Col>
                            <Col>
                                {TDObject.version}
                            </Col>
                            <Col className='TDObject-dropdown-column-space-between'>
                                <Button onClick={handleModelClick} className='flex-button'>
                                    <CubeIcon />
                                    3D
                                </Button>
                                <Button onClick={handleDetailClick} className='flex-button'>
                                    <MagnifyingGlassIcon />
                                    Detail
                                </Button>
                                <Button onClick={handleEditClick} disabled={ ! canModelBeEdited()} className='flex-button'>
                                {/*<Button onClick={handleEditClick} disabled>*/}
                                    <EditIcon />
                                    Upravit
                                </Button>
                                <Button variant='danger' onClick={handleDeleteClick} disabled={ ! canModelBeDeleted()} className='flex-button'>
                                    <DeleteIconNarrow />
                                </Button>
                            </Col>
                        </Row>

                    </Accordion.Body>

                </Accordion.Item>

            </Accordion>
            <ErrorAndSuccessModals
                isErrorAlertOpen={isErrorAlertOpen}
                handleCloseErrorAlert={handleCloseErrorAlert}
                isSuccessAlertOpen={isSuccessAlertOpen}
                handleCloseSuccessAlert={handleCloseSuccessAlert}
            />
        </div>
    )
}

export default TDObjectDropdown
