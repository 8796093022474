import React, {ChangeEvent, useEffect, useState} from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import SaveIcon from "../icons/save-icon.component";
import ExclamationMarkLightIcon from "../icons/exclamation-mark-light-icon.component";
import HelpPopover from "../help-popover/help-popover.component";
import WarningPopover from "../warning-popover/warning-popover.component";
import useUserContext from "../../contexts/user.context";
import { keysAndValuesToJson } from "../../utils/parsers/form-input-event-parser";
import { fetchStructureById, fetchStructures } from "../../utils/api-fetchers/structure/structure-api-fetcher";
import { WarningText } from "../../enums/warning-text.enum";

import { TextureType } from "../../enums/texture-type.enum";
import { HelpText } from "../../enums/help-text.enum";
import { IStructure } from "../../interfaces/IStructure";
import { I3DObject } from "../../interfaces/I3DObject";

import './TDObject-creation-form-form.styles.scss'

interface IProps {
    loading?: boolean,
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
    handleFileChange?: (event: any) => void,
    handleAssetsChange?: (event: any) => void,
    default3DObject?: I3DObject,
    setTagsObject?: (value: any) => void,
    versionCreation?: boolean,
    structureId?: string
}

const ModelCreationForm = (props: IProps) => {

    const {
        handleSubmit,
        handleFileChange,
        handleAssetsChange,
        loading,
        default3DObject,
        setTagsObject,
        versionCreation,
        structureId
    } = props
    const { currentUser } = useUserContext()

    const [structures, setStructures] = useState<IStructure[]>([])

    const [tagKeys, setTagKeys] = useState<string[]>([])
    const [tagKeyFieldData, setTagKeyFieldData] = useState<string>('')
    const [tagValues, setTagValues] = useState<string[]>([])
    const [tagValueFieldData, setTagValueFieldData] = useState<string>('')

    const [modelName, setModelName] = useState(default3DObject ? default3DObject.name : '')
    const [modelStructureId, setModelStructureId] = useState(default3DObject ? default3DObject.structureId : '')

    const [texturesCount, setTexturesCount] = useState<number>(0)
    const [texturesCountArray, setTexturesCountArray] = useState<string[]>([])

    useEffect(() => {
        const fetchStructuresLocal = async () => {
            if (currentUser) {
                const token: string = currentUser ? currentUser.token : ''
                if (structureId) {
                    setStructures([await fetchStructureById(token, structureId)])
                    setModelStructureId(structureId)
                } else
                    setStructures(await fetchStructures(token))
            }
        }
        fetchStructuresLocal()
    }, [currentUser])

    // =========================== NAME ===========================

    const handleModelNameChange = (event: any) => {
        setModelName(event.target.value)
    }

    // =========================== STRUCTURE ===========================

    const handleStructureChange = (event: any) => {
        setModelStructureId(event.target.value)
    }

    // =========================== TAG INPUT ===========================

    const handleTagKeyInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTagKeyFieldData(event.currentTarget.value)
    }

    const handleTagValueInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTagValueFieldData(event.currentTarget.value)
    }

    // =========================== TAGS ARRAYS ===========================

    const handleTagAdd = () => {
        if (tagKeys.includes(tagKeyFieldData) || tagKeyFieldData === '')
            return

        const newKeys = [...tagKeys, tagKeyFieldData]
        const newValues = [...tagValues, tagValueFieldData]
        setTagKeys(newKeys);
        setTagValues(newValues)
        if (setTagsObject)
            setTagsObject(keysAndValuesToJson(newKeys, newValues))
    }

    const getTagPairByKey = (key: string) => {
        for (let i = 0; i < tagKeys.length; i++) {
            if (tagKeys[i] === key)
                return {
                    key: key,
                    value: tagValues[i]
                }
        }
        return {}
    }

    const deleteTag = (key: string) => {
        const index = tagKeys.findIndex(t => t === key)
        // console.log(index)
        let keys = tagKeys.map(x => x)
        let values = tagValues.map(x => x)
        keys.splice(index, 1)
        values.splice(index, 1)
        setTagKeys(keys)
        setTagValues(values)
        if (setTagsObject)
            setTagsObject(keysAndValuesToJson(keys, values))
    }

    // =========================== TEXTURES ===========================

    const handleTexturesCountChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.currentTarget.value)
        if ( ! isNaN(value)) {
            setTexturesCount(value)
            let newTexturesTypes: string[] = []
            for (let i = 0; i < value; i++) {
                newTexturesTypes.push('')
            }
            setTexturesCountArray(newTexturesTypes)
        }
        else {
            setTexturesCount(0)
        }
    }

    // // =========================== CHECKING CONDITIONS ===========================
    //
    // const canBeSentForApproval = () => {
    //     return (! loading) && default3DObject && default3DObject.status === 'finished'
    // }
    //
    // const canBeFinished = () => {
    //     return (! loading) && default3DObject && default3DObject.status === 'unfinished'
    // }

    // =========================== CONSTANTS ===========================

    const actionsHelpText = HelpText.FORM_ACTIONS_CREATE
    const tagsHelpText = HelpText.TAGS_NOT_IMPLEMENTED

    const finishWarning = WarningText.FINISH_WARNING


    // =========================== RETURN ===========================

    return(
        <div className='TDObject-create-form'>
            <Form className='modeller-model-form' onSubmit={handleSubmit}>
                <div className='modeller-model-form-header-container'>
                    <h5 className='modeller-model-label'>
                        Informace o modelu
                    </h5>
                </div>
                <Row>
                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Název
                                <span className='modeller-form-required-star'>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Název..."
                                id='modelName'
                                value={modelName}
                                onChange={handleModelNameChange}
                                required
                                disabled={versionCreation}
                            />
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Struktura
                                <span className='modeller-form-required-star'>*</span>
                            </Form.Label>
                            <div className='modeller-model-structure-input-container'>
                                <Form.Select
                                    aria-placeholder="Vyberte strukturu..."
                                    id='modelStructure'
                                    value={modelStructureId}
                                    onChange={handleStructureChange}
                                    required
                                    disabled={versionCreation || structureId !== null}
                                >
                                    <option value="0" disabled>Vyberte strukturu</option>
                                    {
                                        structures.map(structure =>
                                            <option key={structure.id} value={structure.id}>{structure.name}</option>
                                        )
                                    }
                                </Form.Select>
                            </div>
                        </Form.Group>
                    </Col>
                    <Col className='modeller-model-form-right-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Soubor s 3D modelem
                                <span className='modeller-form-required-star'>*</span>
                                <Form.Text>   - povolené formáty: OBJ, GLB, FBX</Form.Text>
                            </Form.Label>
                            <input
                                className="form-control"
                                type="file"
                                id='modelFile'
                                onChange={handleFileChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                </Row>

                <div className='modeller-model-form-header-container'>
                    <h5>
                        Trasformace modelu
                    </h5>
                </div>

                <Row>
                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Translace</Form.Label>
                            <Form.Text>   - Při nevyplnění bude nastaveno na 0,0,0</Form.Text>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelTranslationX' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelTranslationY'  />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelTranslationZ' />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Škálování</Form.Label>
                            <Form.Text>   - Při nevyplnění bude nastaveno na 1,1,1</Form.Text>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelScaleX' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelScaleY' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelScaleZ' />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-right-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Rotace</Form.Label>
                            <Form.Text>   - Při nevyplnění bude nastaveno na 0,0,0,1</Form.Text>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelRotation1'/>
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelRotation2' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelRotation3' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelRotation4' />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>

                {/*<hr className='mt-5 mb-5 border-5'/>*/}

                <div className='modeller-model-form-header-container'>
                    <h5>
                        Trasformační síť modelu modelu
                    </h5>
                </div>

                <Row>
                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Translace</Form.Label>
                            <Form.Text>   - Při nevyplnění bude nastaveno na 0,0,0</Form.Text>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebTranslationX' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebTranslationY' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebTranslationZ' />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Škálování</Form.Label>
                            <Form.Text>   - Při nevyplnění bude nastaveno na 1,1,1</Form.Text>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelWebScaleX' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelWebScaleY' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelWebScaleZ'  />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-right-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Rotace</Form.Label>
                            <Form.Text>   - Při nevyplnění bude nastaveno na 0,0,0,1</Form.Text>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebRotation1' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebRotation2' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebRotation3' />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelWebRotation4' />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className='modeller-model-form-header-container'>
                            <h5>
                                Textury
                            </h5>
                        </div>
                    </Col>

                    <Col>
                        <div className='modeller-model-form-header-container'>
                            <h5>
                                Assety
                            </h5>
                        </div>
                    </Col>

                    <Col>
                        <div className='modeller-model-form-header-container'>
                            <h5 style={{margin: "0 1rem 0 0"}}>
                                Tagy
                            </h5>
                            <HelpPopover htmlContent={tagsHelpText} />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className='modeller-model-form-column'>
                        <div className='modeller-model-column-files-picker'>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Kolik textur chcete nahrát?
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="0"
                                    id='texturesCount'
                                    value={texturesCount}
                                    onChange={handleTexturesCountChange}
                                    required
                                />
                                {
                                    texturesCountArray.length > 0 &&
                                    <Form.Label>
                                        Pro každou texturu zvolte <strong>soubor</strong> (PNG/JPG/JPEG) a <strong>typ</strong>.
                                    </Form.Label>
                                }
                                {
                                    texturesCountArray.map((t, index) =>
                                        <div className='TDObject-create-form-texture-row' key={index}>
                                            <input className="form-control" type="file" id={'textureFile' + index}/>
                                            <div className='TDObject-create-form-texture-row-texture-type'>
                                                <Form.Select
                                                    aria-placeholder="Vyberte typ..."
                                                    id={'textureType' + index}
                                                    required
                                                >
                                                    {
                                                        Object.values(TextureType).map(type =>
                                                            <option key={type} value={type}>{type}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                            </div>
                                        </div>
                                    )
                                }
                            </Form.Group>
                        </div>
                    </Col>

                    <Col className='modeller-model-form-column'>
                        <div className='modeller-model-column-files-picker'>
                            <div className="mb-3">
                                <input className="form-control" type="file" id="formFile" multiple onChange={handleAssetsChange}/>
                            </div>
                        </div>
                    </Col>

                    <Col className='modeller-model-form-right-column'>
                        <div className='modeller-model-column-files-picker'>
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Název..."
                                              id='tagField' onChange={handleTagKeyInputChange}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Hodnota..."
                                              id='tagValueField' onChange={handleTagValueInputChange}/>
                            </Form.Group>
                            <Button variant="success" onClick={handleTagAdd} disabled>Přidat</Button>
                        </div>
                        <div>
                            {
                                tagKeys.map(tag => {
                                    return (
                                        <div className='modeller-model-created-tag-row' key={tag}>
                                            <h5>{getTagPairByKey(tag).key}: {getTagPairByKey(tag).value}</h5>
                                            <Button variant='danger' onClick={() => {deleteTag(tag)}}>X</Button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>

                <div className='modeller-model-form-header-container'>
                    <h5 style={{margin: "0 1rem 0 0"}}>
                        Akce
                    </h5>
                    <HelpPopover htmlContent={actionsHelpText} />
                </div>

                <Row>
                    <Col className='modeller-model-submit-button-container modeller-model-form-column'>
                        <Button
                            variant="primary"
                            type="submit"
                            className='modeller-model-submit-button flex-button'
                            disabled={loading}
                            name='saveNew'
                        >
                            <SaveIcon />
                            Uložit
                        </Button>
                    </Col>
                    <Col className='modeller-model-submit-button-container modeller-model-form-right-column modeller-model-warning-button'>
                        <WarningPopover htmlContent={finishWarning} />
                        <Button
                            variant="primary"
                            type="submit"
                            className='modeller-model-submit-button flex-button'
                            disabled={loading}
                            // disabled
                            name='saveFinishNew'
                        >
                            <ExclamationMarkLightIcon />
                            Uložit a dokončit
                        </Button>
                        <WarningPopover htmlContent={finishWarning} />
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ModelCreationForm
