import React from "react";

import Modal from "react-bootstrap/Modal";

interface IProps {
    isErrorAlertOpen: boolean,
    handleCloseErrorAlert: () => void,
    isSuccessAlertOpen: boolean,
    handleCloseSuccessAlert: () => void,

}

const ErrorAndSuccessModals = (props: IProps) => {
    const { isErrorAlertOpen, handleCloseErrorAlert, handleCloseSuccessAlert, isSuccessAlertOpen } = props
    return(
        <div>
            <Modal show={isErrorAlertOpen} onHide={handleCloseErrorAlert}>
                <Modal.Header closeButton>Došlo k chybě!</Modal.Header>
                <Modal.Body>
                    Nastala chyba při komunkaci se serverem. Zkontrolujte vyplněná data, zda jste přihlášeni a připojeni k internetu. Zkuste znovu načíst stránku.
                </Modal.Body>
            </Modal>
            <Modal show={isSuccessAlertOpen} onHide={handleCloseSuccessAlert}>
                <Modal.Header closeButton>Úspěch!</Modal.Header>
                <Modal.Body>
                    Akce byla úspěšně provedena.
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ErrorAndSuccessModals
