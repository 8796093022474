import { useEffect } from "react";
import { Outlet } from 'react-router-dom'
import { Link } from 'react-router-dom';

import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import GoogleAuthComponent from "../../components/google-auth/google-auth.component";
import useUserContext from "../../contexts/user.context";
import useTasksCountContext from "../../contexts/tasks-count.context";
import { recomputeTasksNumber } from "../../utils/context-maintainers/tasks-count-context.maintainer";

import './navigation.styles.scss'

const Navigation = () => {

    const { currentUser } = useUserContext()

    const { tasksCount, setTasksCount } = useTasksCountContext()

    useEffect(() => {
        const updateTasksNumber = async () => {
            if (currentUser) {
                const result = await recomputeTasksNumber(currentUser)
                setTasksCount(result)
            }
        }
        updateTasksNumber()
    }, [currentUser, setTasksCount])


    const getNavBarByRole = () => {
        if ( ! currentUser)
            return (
                <div className='navigation-bar-not-logged-in'>
                    <GoogleAuthComponent />
                </div>
            )
        else {
            switch(currentUser.role) {
                case 'commonUser':
                    return (
                        <div className='navigation-bar'>
                            <div className='nav-bar-navigation-buttons'>
                                <Link to='/'><Button className='nav-button' variant="light">Úvodní stránka</Button></Link>
                                <Link to='/modeller/all-creations'><Button className='nav-button' variant="light">Mé modely</Button></Link>
                                <Link to='/modeller/approvals'><Button className='nav-button' variant="light">Schvalovací procesy</Button></Link>
                            </div>
                            <GoogleAuthComponent  />
                        </div>
                    )
                case 'approver':
                    return (
                        <div className='navigation-bar'>
                            <div className='nav-bar-navigation-buttons'>
                                <Link to='/'><Button className='nav-button' variant="light">Úvodní stránka</Button></Link>
                                <Link to='/approver/tasks'>
                                    <Button className='nav-button' variant="light">Čeká na schválení
                                        <span className='nav-button-notification-badge'>
                                        <Badge bg='info'>{tasksCount}</Badge>
                                        </span>
                                    </Button>
                                </Link>
                                <Link to='/approver/approvals'><Button className='nav-button' variant="light">Schvalovací procesy</Button></Link>
                            </div>
                            <GoogleAuthComponent />
                        </div>
                    )
                case 'historian':
                    return (
                        <div className='navigation-bar'>
                            <div className='nav-bar-navigation-buttons'>
                                <Link to='/'><Button className='nav-button' variant="light">Úvodní stránka</Button></Link>
                                <Link to='/approver/tasks'>
                                    <Button className='nav-button' variant="light">Čeká na schválení
                                        <span className='nav-button-notification-badge'>
                                            <Badge bg='info'>{tasksCount}</Badge>
                                        </span>
                                    </Button>
                                </Link>
                                <Link to='/approver/approvals'><Button className='nav-button' variant="light">Schvalovací procesy</Button></Link>
                            </div>
                            <GoogleAuthComponent />
                        </div>
                    )
                case 'admin':
                    return (
                        <div className='navigation-bar'>
                            <div className='nav-bar-navigation-buttons'>
                                <Link to='/'><Button className='nav-button' variant="light">Úvodní stránka</Button></Link>
                                <Link to='/administrator/publishable'>
                                    <Button className='nav-button' variant="light">
                                        Čeká na publikaci
                                        <span className='nav-button-notification-badge'>
                                            <Badge bg='info'>{tasksCount}</Badge>
                                        </span>
                                    </Button>
                                </Link>
                                <Link to='/administrator/published'>
                                    <Button className='nav-button' variant="light">Veřejné modely</Button>
                                </Link>
                                <Link to='/manager/streets'><Button className='nav-button' variant="light">Ulice</Button></Link>
                            </div>
                            <GoogleAuthComponent />
                        </div>
                    )
                default:
                    return (
                        <div className='navigation-bar-not-logged-in'>
                            <GoogleAuthComponent />
                        </div>
                    )
            }
        }
    }

    return (
        <>
            {
                getNavBarByRole()
            }
            <Outlet />
        </>
    )
}

export default Navigation;
