import { useEffect, useState } from "react";

import Alert from "react-bootstrap/Alert";

import ApprovalProcessDropdown from "../../../components/approval-process-dropdown/approval-process-dropdown.component";
import useUserContext from "../../../contexts/user.context";
import { czechRole } from "../../../enums/roles.enum";
import { fetchUserApprovals } from "../../../utils/api-fetchers/aproval-process/aproval-process-api-fetcher";
import { IApprovalProcessDetail } from "../../../interfaces/IApprovalProcessDetail";

import './approver-tasks.styles.scss'

const ApproverTasks = () => {

    const { currentUser } = useUserContext()

    const [approvals, setApprovals] = useState<IApprovalProcessDetail[]>([])

    useEffect(() => {
        const getApprovals = async () => {
            if (currentUser) {
                const approvals = await fetchUserApprovals(currentUser.token)
                setApprovals(approvals)
            }
        }
        getApprovals()
    }, [currentUser])

    return (
        <div className='approver-tasks-container'>
            <div className='approver-tasks-header-container'>
                <h2>
                    Čeká na schválení
                </h2>
                {
                    currentUser &&
                    <h5>Procesy, jichž jsem členem, v nichž modely čekají na schválení rolí {czechRole(currentUser?.role)}</h5>
                }
                {
                    currentUser &&
                    <h5>Z fronty byly vyřazeny procesy zamítnuté druhou rolí</h5>
                }
            </div>
            {
                approvals && approvals.length === 0
                ?   <Alert variant='info'>Nejste členem žádných schvalovacích procesů.</Alert>
                :
                approvals.map(approval =>
                    <ApprovalProcessDropdown
                        key={approval.id}
                        approval={approval}
                        displayDecided={false}
                    />)
            }
        </div>
    )
}
export default ApproverTasks
