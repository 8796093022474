import { Route, Routes } from 'react-router-dom'

import ModellerAllCreations from "./all-creations/modeller-all-creations.component";
import ModellerModelCreation from "./model-creation/modeller-model-creation.component";
import ModellerAllApprovals from "./approvals/modeller-approvals.component";
import UnfinishedModeEdit from "./unfinished-model-edit/unfinished-model-edit.component";
import ModelDetail from "./model-detail-form/model-detail-form.component";
import ModelDetailView from "./model-detail-view/model-detail-view.component";
import ModellerVersionCreation from "./model-new-version-creation/model-new-version-creation.component";
import ApproverIterationApproval from "../approver/iteration-approval/approver-iteration-approval.component";

const ModellerComponent = () => {

    return (
        <Routes>
            <Route path='all-creations' element={<ModellerAllCreations />}/>
            <Route path='model-creation' element={<ModellerModelCreation />}/>
            <Route path='approvals' element={<ModellerAllApprovals />}/>
            <Route path='model-edit/*' element={<UnfinishedModeEdit />}/>
            <Route path='model-detail-form/*' element={<ModelDetail />}/>
            <Route path='model-detail-view/*' element={<ModelDetailView />}/>
            <Route path='create-new-version/*' element={<ModellerVersionCreation />}/>
            <Route path='iteration-approval/*' element={<ApproverIterationApproval />}/>
        </Routes>
    )
}

export default ModellerComponent
