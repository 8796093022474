import React from "react";
import Modal from "react-bootstrap/Modal";

interface IProps {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void
}

const TexturesWarningModal = (props: IProps) => {
    const { setIsOpen, isOpen } = props

    const handleClose = () => {
        setIsOpen(false)
    }
    return(
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton>Chybně vyplněné soubory</Modal.Header>
            <Modal.Body>
                Prosíme vložte všechny soubory textur a vyberte jejich typy.
            </Modal.Body>
        </Modal>
    )
}

export default TexturesWarningModal
