import { LocationResponse } from "../../../interfaces/ILocation";

export const fetchLocationData = (lat: number, lng:  number): Promise<LocationResponse> => {
    return fetch(`https://api.open-elevation.com/api/v1/lookup?locations=${lat},${lng}`,{
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
            },
            method: "GET"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
}
