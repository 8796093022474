import React from "react";

import { IApprovalProcessModelIteration } from "../../interfaces/IApprovalProcessModelIteration";

import './approval-iteration-name.styles.scss'

interface IProps {
    iteration: IApprovalProcessModelIteration
}

const ApprovalIterationName = (props: IProps) => {

    const { iteration } = props

    return(
        <div className='approval-iteration-name-container'>
            <div className='approval-iteration-name-header'>
                Název iterace
            </div>
            <div className='approval-iteration-name-content'>
                {iteration.name}
            </div>
        </div>
    )
}

export default ApprovalIterationName
