import { useEffect, useState } from "react";

import Alert from "react-bootstrap/Alert";
import Accordion from "react-bootstrap/Accordion";

import useUserContext from "../../../contexts/user.context";
import ApprovalProcessApprovedDropdown
    from "../../../components/approval-process-approved-dropdown/approval-process-approved-dropdown.component";
import { fetchUserApprovals } from "../../../utils/api-fetchers/aproval-process/aproval-process-api-fetcher";
import { IApprovalProcessDetail } from "../../../interfaces/IApprovalProcessDetail";

import './admin-publishable.styles.scss'

const AdminPublishable = () => {

    const { currentUser } = useUserContext()

    const [approvals, setApprovals] = useState<IApprovalProcessDetail[]>([])
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        const getApprovals = async () => {
            if (currentUser) {
                setApprovals(await fetchUserApprovals(currentUser.token))
            }
        }
        getApprovals()
    }, [currentUser])

    useEffect(() => {
        for (let aIndex = 0; aIndex < approvals.length; aIndex++) {
            const approval = approvals[aIndex]
            for (let iIndex = 0; iIndex < approval.modelIterations.length; iIndex++) {
                const iteration = approval.modelIterations[iIndex]
                if (iteration.historianApprovingId && iteration.graphicianApprovingId && ! iteration.finalizedDate) {
                    setShowAlert(false)
                    return
                }
            }
        }
        setShowAlert(true)
    }, [approvals])


    return (
        <div className='admin-publishable-container'>
            <div className='admin-publishable-header-container'>
                <h2>
                    Schválené iterace
                </h2>
                <h5>Tyto iterace byly schváleny a lze publikovat jejich modely</h5>
            </div>
            <Accordion>
                {
                    showAlert
                        ? <Alert variant='info'>Neexistují žádné schválené iterace s modely.</Alert>
                        :
                        approvals.map(approval => {
                            return <ApprovalProcessApprovedDropdown key={approval.id} approval={approval} />
                        })
                }
            </Accordion>
        </div>
    )
}

export default AdminPublishable
