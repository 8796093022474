import ITransformation from "../../interfaces/ITransformation";

// ===================== MODEL FILE ================================ //

export const parseModelFile = (event: any): File => {

    const fileId = 'modelFile'

    const fileInput = event.target.querySelector(`input#${fileId}`);
    const file = fileInput.files[0]
    if (! file)
        throw new Error('Empty file input field!')

    return file
}// ===================== TEXTURES FILES ================================ //

export const parseTextureFilesArray = (event: any): File[] => {
    const texturesCount = event.currentTarget.texturesCount.value
    let files: File[] = [];
    for (let i = 0; i < texturesCount; i++) {
        const fileId = "textureFile" + i;
        const fileInput = event.target.querySelector(`input#${fileId}`);
        const selectedFile = fileInput.files[0];
        if (! selectedFile)
            throw new Error('Empty file input field!')
        files.push(selectedFile)
    }

    return files
}

// ===================== TEXTURES TYPES ================================ //

export const parseTextureTypesArray = (event: any): string[] => {
    const texturesCount = event.currentTarget.texturesCount.value
    let values = [];
    for (let i = 0; i < texturesCount; i++) {
        const element = event.currentTarget.elements.namedItem(`textureType${i}`) as HTMLInputElement;
        if (element) {
            values.push(element.value);
        }
    }

    return values
}
// ===================== ASSETS FILES ================================ //

export const parseAssetsFilesArray = (event: any): File[] => {
    const fileInput = event.currentTarget.elements.namedItem('assetsFiles')
    return Array.from(fileInput.files);
}

// ===================== TRANSFORMATION DEFAULT VALUES ================================ //

const defaultTranslation = [0,0,0]
const defaultScale = [1,1,1]
const defaultRotation = [0,0,0,1]

// ===================== TRANSFORMATION ================================ //

const parseTransformationTranslation = (event: any): number[] => {
    let result = [...defaultTranslation]
    const x = event.currentTarget.modelTranslationX.value
    const y = event.currentTarget.modelTranslationY.value
    const z = event.currentTarget.modelTranslationZ.value
    if (x)
        result[0] = parseInt(x)
    if (y)
        result[1] = parseInt(y)
    if (z)
        result[2] = parseInt(z)

    return result
}

const parseTransformationScale = (event: any): number[] => {
    let result = [...defaultScale]
    const x = event.currentTarget.modelScaleX.value
    const y = event.currentTarget.modelScaleY.value
    const z = event.currentTarget.modelScaleZ.value
    if (x)
        result[0] = parseInt(x)
    if (y)
        result[1] = parseInt(y)
    if (z)
        result[2] = parseInt(z)

    return result
}

const parseTransformationRotation = (event: any): number[] => {
    let result = [...defaultRotation]
    const a = event.currentTarget.modelRotation1.value
    const b = event.currentTarget.modelRotation2.value
    const c = event.currentTarget.modelRotation3.value
    const d = event.currentTarget.modelRotation4.value
    if (a)
        result[0] = parseInt(a)
    if (b)
        result[1] = parseInt(b)
    if (c)
        result[2] = parseInt(c)
    if (d)
        result[3] = parseInt(d)

    return result
}

export const parseTransformation = (event: any): ITransformation => {
    return {
        translation: parseTransformationTranslation(event),
        scale: parseTransformationScale(event),
        rotation: parseTransformationRotation(event)
    }
}

// ===================== TRANSFORMATION web =========================== //

const parseTransformationWebTranslation = (event: any): number[] => {
    let result = [...defaultTranslation]
    const x = event.currentTarget.modelWebTranslationX.value
    const y = event.currentTarget.modelWebTranslationY.value
    const z = event.currentTarget.modelWebTranslationZ.value
    if (x)
        result[0] = parseInt(x)
    if (y)
        result[1] = parseInt(y)
    if (z)
        result[2] = parseInt(z)
    return result
}

const parseTransformationWebScale = (event: any): number[] => {
    let result = [...defaultScale]
    const x = event.currentTarget.modelWebScaleX.value
    const y = event.currentTarget.modelWebScaleY.value
    const z = event.currentTarget.modelWebScaleZ.value
    if (x)
        result[0] = parseInt(x)
    if (y)
        result[1] = parseInt(y)
    if (z)
        result[2] = parseInt(z)

    return result
}

const parseTransformationWebRotation = (event: any): number[] => {
    let result = [...defaultRotation]
    const a = event.currentTarget.modelWebRotation1.value
    const b = event.currentTarget.modelWebRotation2.value
    const c = event.currentTarget.modelWebRotation3.value
    const d = event.currentTarget.modelWebRotation4.value
    if (a)
        result[0] = parseInt(a)
    if (b)
        result[1] = parseInt(b)
    if (c)
        result[2] = parseInt(c)
    if (d)
        result[3] = parseInt(d)

    return result
}

export const parseTransformationWeb = (event: any): ITransformation => {
    return {
        translation: parseTransformationWebTranslation(event),
        scale: parseTransformationWebScale(event),
        rotation: parseTransformationWebRotation(event)
    }
}

// ===================== TAGS OBJECT =========================== //

export const keysAndValuesToJson = (keys: string[], values: any[]) => {
    const result = keys.reduce((obj: Record<string, any>, key, index) => {
        obj[key] = values[index];
        return obj;
    }, {});

    return JSON.stringify(result)
}
