export enum Role {
    approver = 'grafik',
    historian = 'historik',
    commonUser = 'modelář',
    admin = 'administrátor',
    unknown = 'neznámé'
}

export const czechRole = (role: string) => {
    switch (role) {
        case 'approver': {
            return Role.approver
        }
        case 'historian': {
            return Role.historian
        }
        case 'commonUser': {
            return Role.commonUser
        }
        case 'admin': {
            return Role.admin
        }
        default:
            return Role.unknown
    }
}
