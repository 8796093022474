import React, {ChangeEvent, useEffect, useState} from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import HelpPopover from "../help-popover/help-popover.component";
import useUserContext from "../../contexts/user.context";
import SaveIcon from "../icons/save-icon.component";
import ExclamationMarkLightIcon from "../icons/exclamation-mark-light-icon.component";
import WarningPopover from "../warning-popover/warning-popover.component";
import { czechTDObjectStatus } from "../../enums/TDObject-status.enum";
import { fetch3DObjectById } from "../../utils/api-fetchers/TDObject/TDObject-api-fetcher";
import { defaultTransformation, defaultTransformationWeb } from "../../utils/constants/TDObject-constants";
import { HelpText } from "../../enums/help-text.enum";
import { WarningText } from "../../enums/warning-text.enum";
import { TextureType } from "../../enums/texture-type.enum";

import { I3DObject } from "../../interfaces/I3DObject";
import './TDObject-edit-form-form.styles.scss'
import ReloadIcon from "../icons/reload-icon.component";


interface IProps {
    loading?: boolean,
    handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void,
    handleFileChange?: (event: any) => void,
    default3DObject: I3DObject
}

const ModelEditForm = (props: IProps) => {

    const { handleSubmit, handleFileChange, loading, default3DObject } = props
    const { currentUser } = useUserContext()

    const [localModelName, setLocalModelName] = useState(default3DObject.name)

    const [tagKeys, setTagKeys] = useState<string[]>([])
    const [tagKeyFieldData, setTagKeyFieldData] = useState<string>('')
    const [tagValues, setTagValues] = useState<string[]>([])
    const [tagValueFieldData, setTagValueFieldData] = useState<string>('')

    const [fetched3DObject, setFetched3DObject] = useState<I3DObject>(default3DObject)

    const [texturesCount, setTexturesCount] = useState<number>(0)
    const [texturesCountArray, setTexturesCountArray] = useState<string[]>([])

    const [transformation, setTransformation] = useState(defaultTransformation)
    const [transformationWeb, setTransformationWeb] = useState(defaultTransformationWeb)

    // =========================== useEffects ===========================

    useEffect(() => {
        const fetchObject = async () => {
            if (currentUser)
                setFetched3DObject(await fetch3DObjectById(currentUser.token, default3DObject.id))
       }
       fetchObject()
    }, [currentUser, default3DObject.id])

    useEffect(() => {
        if (Object.keys(fetched3DObject.transformation).length > 0)
            setTransformation(fetched3DObject.transformation)
        if (Object.keys(fetched3DObject.transformationWeb).length > 0)
            setTransformationWeb(fetched3DObject.transformationWeb)
    }, [fetched3DObject.transformation, fetched3DObject.transformationWeb])

    // =========================== TAGS ===========================

    const handleTagKeyInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTagKeyFieldData(event.currentTarget.value)
    }

    const handleTagValueInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTagValueFieldData(event.currentTarget.value)
    }

    const handleTagAdd = () => {
        if (tagKeys.includes(tagKeyFieldData) || tagKeyFieldData === '')
            return
        setTagKeys(current => [...current, tagKeyFieldData]);
        setTagValues(current => [...current, tagValueFieldData])
    }

    const getTagPairByKey = (key: string) => {
        for (let i = 0; i < tagKeys.length; i++) {
            if (tagKeys[i] === key)
                return {
                    key: key,
                    value: tagValues[i]
                }
        }
        return {}
    }

    const deleteTag = (key: string) => {
        const index = tagKeys.findIndex(t => t === key)
        // console.log(index)
        let keys = tagKeys.map(x => x)
        let values = tagValues.map(x => x)
        keys.splice(index, 1)
        values.splice(index, 1)
        setTagKeys(keys)
        setTagValues(values)
    }

    // =========================== NAME ===========================

    const handleModelNameChange = (event: any) => {
        setLocalModelName(event.target.value)
    }

    // =========================== CONSTANTS ===========================


    const statusHelpText = HelpText.TD_OBJECT_API_STATUS
    const actionsHelpText = HelpText.FORM_ACTIONS_EDIT
    const tagsHelpText = HelpText.TAGS_NOT_IMPLEMENTED
    const finishWarning = WarningText.FINISH_WARNING

    // =========================== TEXTURES ===========================

    const handleTexturesCountChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.currentTarget.value)
        if ( ! isNaN(value)) {
            setTexturesCount(value)
            let newTexturesTypes: string[] = []
            for (let i = 0; i < value; i++) {
                newTexturesTypes.push('')
            }
            setTexturesCountArray(newTexturesTypes)
        }
        else {
            setTexturesCount(0)
        }
    }

    // =========================== ACTIONS ===========================

    const handleFormReset = () => {
        console.log('form reset')
        const oldName = default3DObject.name

        const oldTranslation = [...default3DObject.transformation.translation]
        const oldScale = [...default3DObject.transformation.scale]
        const oldRotation = [...default3DObject.transformation.rotation]

        const oldTranslationWeb = [...default3DObject.transformationWeb.translation]
        const oldScaleWeb = [...default3DObject.transformationWeb.scale]
        const oldRotationWeb = [...default3DObject.transformationWeb.rotation]

        setLocalModelName(oldName)
        setTransformation({
            translation: oldTranslation,
            rotation: oldRotation,
            scale: oldScale
        })

        setTransformationWeb({
            translation: oldTranslationWeb,
            rotation: oldRotationWeb,
            scale: oldScaleWeb
        })
    }

    // ===================== TRANSFORMATION CHANGE HANDLERS =======================

    const handleTransformationChange = (event: any) => {
        const id = event.target.id
        let value: number
        if (event.target.value === '') {
            value = 0
        }
        else
            value = parseInt(event.target.value)

        let newTranslation = [...transformation.translation]
        let newScale = [...transformation.scale]
        let newRotation = [...transformation.rotation]

        switch (id) {
            case 'modelTranslationX': {
                newTranslation[0] = value
                break
            }
            case 'modelTranslationY': {
                newTranslation[1] = value
                break
            }
            case 'modelTranslationZ': {
                newTranslation[2] = value
                break
            }
            case 'modelScaleX': {
                newScale[0] = value
                break
            }
            case 'modelScaleY': {
                newScale[1] = value
                break
            }
            case 'modelScaleZ': {
                newScale[2] = value
                break
            }
            case 'modelRotation1': {
                newRotation[0] = value
                break
            }
            case 'modelRotation2': {
                newRotation[1] = value
                break
            }
            case 'modelRotation3': {
                newRotation[2] = value
                break
            }
            case 'modelRotation4': {
                newRotation[3] = value
                break
            }
            default:
                console.log('default')
        }
        setTransformation({
            translation: newTranslation,
            rotation: newRotation,
            scale: newScale
        })
    }
    // ===================== TRANSFORMATION WEB CHANGE HANDLERS =======================

    const handleTransformationWebChange = (event: any) => {
        const id = event.target.id
        const value = parseInt(event.target.value)

        let newTranslation = [...transformationWeb.translation]
        let newScale = [...transformationWeb.scale]
        let newRotation = [...transformationWeb.rotation]

        switch (id) {
            case 'modelWebTranslationX': {
                newTranslation[0] = value
                break
            }
            case 'modelWebTranslationY': {
                newTranslation[1] = value
                break
            }
            case 'modelWebTranslationZ': {
                newTranslation[2] = value
                break
            }
            case 'modelWebScaleX': {
                newScale[0] = value
                break
            }
            case 'modelWebScaleY': {
                newScale[1] = value
                break
            }
            case 'modelWebScaleZ': {
                newScale[2] = value
                break
            }
            case 'modelWebRotation1': {
                newRotation[0] = value
                break
            }
            case 'modelWebRotation2': {
                newRotation[1] = value
                break
            }
            case 'modelWebRotation3': {
                newRotation[2] = value
                break
            }
            case 'modelWebRotation4': {
                newRotation[3] = value
                break
            }
            default:
                console.log('default')
        }
        setTransformationWeb({
            translation: newTranslation,
            rotation: newRotation,
            scale: newScale
        })
    }

    // =========================== RETURN ===========================

    return(
        <div className='TDObject-create-form'>
            <Form className='modeller-model-form' onSubmit={handleSubmit}>
                <div className='modeller-model-form-header-container'>
                    <h5 className='modeller-model-label'>
                        Informace o modelu
                    </h5>
                </div>
                <Row>
                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>
                                Název
                                <span className='modeller-form-required-star'>*</span>
                            </Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Název..."
                                id='modelName'
                                value={localModelName}
                                onChange={handleModelNameChange}
                                required
                            />
                        </Form.Group>
                    </Col>
                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <div className='modeller-model-form-label-and-help'>
                                <Form.Label>
                                    Stav modelu
                                </Form.Label>
                                <HelpPopover htmlContent={statusHelpText}/>
                            </div>
                            <Form.Control
                                type="text"
                                id='modelState'
                                // value={modelName}
                                // onChange={handleModelNameChange}
                                value={czechTDObjectStatus(fetched3DObject?.status)}
                                required
                                disabled={true}
                            />
                                </Form.Group>
                            </Col>
                            <Col className='modeller-model-form-right-column'>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        Soubor s 3D modelem
                                        <span className='modeller-form-required-star'>*</span>
                                        <Form.Text>   - povolené formáty: OBJ, GLB, FBX</Form.Text>
                                    </Form.Label>
                                    <input
                                        className="form-control"
                                        type="file"
                                        id='modelFile'
                                        // value={modelFile}
                                        onChange={handleFileChange}
                                        required
                                    />
                                    {/*<Form.Control type="file" placeholder="Název..." id='modelFile' required onChange={onFileChange}/>*/}
                                </Form.Group>
                            </Col>
                </Row>

                <div className='modeller-model-form-header-container'>
                    <h5>
                        Trasformace modelu
                    </h5>
                </div>

                <Row>
                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Translace</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelTranslationX' value={transformation.translation[0]} onChange={handleTransformationChange}/>
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelTranslationY' value={transformation.translation[1]} onChange={handleTransformationChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelTranslationZ' value={transformation.translation[2]} onChange={handleTransformationChange} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Škálování</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelScaleX' value={transformation.scale[0]} onChange={handleTransformationChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelScaleY' value={transformation.scale[1]} onChange={handleTransformationChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelScaleZ' value={transformation.scale[2]} onChange={handleTransformationChange} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-right-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Rotace</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelRotation1' value={transformation.rotation[0]} onChange={handleTransformationChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelRotation2' value={transformation.rotation[1]} onChange={handleTransformationChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelRotation3' value={transformation.rotation[2]} onChange={handleTransformationChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelRotation4' value={transformation.rotation[3]} onChange={handleTransformationChange} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>

                {/*<hr className='mt-5 mb-5 border-5'/>*/}

                <div className='modeller-model-form-header-container'>
                    <h5>
                        Trasformační síť modelu modelu
                    </h5>
                </div>

                <Row>
                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Translace</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebTranslationX' value={transformationWeb.translation[0]} onChange={handleTransformationWebChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebTranslationY' value={transformationWeb.translation[1]} onChange={handleTransformationWebChange}/>
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebTranslationZ' value={transformationWeb.translation[2]} onChange={handleTransformationWebChange} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Škálování</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelWebScaleX' value={transformationWeb.scale[0]} onChange={handleTransformationWebChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelWebScaleY' value={transformationWeb.scale[1]} onChange={handleTransformationWebChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelWebScaleZ' value={transformationWeb.scale[2]} onChange={handleTransformationWebChange} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>

                    <Col className='modeller-model-form-right-column'>
                        <Form.Group className="mb-3">
                            <Form.Label>Rotace</Form.Label>
                            <Row>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebRotation1' value={transformationWeb.rotation[0]} onChange={handleTransformationWebChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebRotation2' value={transformationWeb.rotation[1]} onChange={handleTransformationWebChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="0" id='modelWebRotation3' value={transformationWeb.rotation[2]} onChange={handleTransformationWebChange} />
                                </Col>
                                <Col>
                                    <Form.Control type="number" placeholder="1" id='modelWebRotation4' value={transformationWeb.rotation[3]} onChange={handleTransformationWebChange} />
                                </Col>
                            </Row>
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <div className='modeller-model-form-header-container'>
                            <h5>
                                Textury
                            </h5>
                        </div>
                    </Col>

                    <Col>
                        <div className='modeller-model-form-header-container'>
                            <h5>
                                Assety
                            </h5>
                        </div>
                    </Col>

                    <Col>
                        <div className='modeller-model-form-header-container'>
                            <h5 style={{margin: "0 1rem 0 0"}}>
                                Tagy
                            </h5>
                            <HelpPopover htmlContent={tagsHelpText} />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col className='modeller-model-form-column'>
                        <div className='modeller-model-column-files-picker'>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Kolik textur chcete nahrát?
                                    <span className='modeller-form-required-star'>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="number"
                                    placeholder="0"
                                    id='texturesCount'
                                    value={texturesCount}
                                    onChange={handleTexturesCountChange}
                                    required
                                />
                                {
                                    texturesCountArray.map((t, index) =>
                                        <div className='TDObject-create-form-texture-row' key={index}>
                                            <input className="form-control" type="file" id={'textureFile' + index}/>
                                            <div className='TDObject-create-form-texture-row-texture-type'>
                                                <Form.Select
                                                    aria-placeholder="Vyberte typ..."
                                                    id={'textureType' + index}
                                                    required
                                                >
                                                    {
                                                        Object.values(TextureType).map(type =>
                                                            <option key={type} value={type}>{type}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                            </div>
                                        </div>
                                    )
                                }
                            </Form.Group>
                            {/*<Button variant="danger">Smazat</Button>*/}
                        </div>
                    </Col>

                    <Col className='modeller-model-form-column'>
                        <div className='modeller-model-column-files-picker'>
                            <div className="mb-3">
                                <input className="form-control" type="file" id="assetsFiles" multiple/>
                            </div>
                            {/*<Button variant="danger">Smazat</Button>*/}
                        </div>
                    </Col>

                    <Col className='modeller-model-form-right-column'>
                        <div className='modeller-model-column-files-picker'>
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Název..."
                                                      id='tagField' onChange={handleTagKeyInputChange}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Hodnota..."
                                                      id='tagValueField' onChange={handleTagValueInputChange}/>
                            </Form.Group>
                            <Button variant="success" onClick={handleTagAdd} disabled>Přidat</Button>
                        </div>
                        <div>
                            {
                                tagKeys.map(tag => {
                                    return (
                                        <div className='modeller-model-created-tag-row' key={tag}>
                                            <h5>{getTagPairByKey(tag).key}: {getTagPairByKey(tag).value}</h5>
                                            <Button variant='danger' onClick={() => {deleteTag(tag)}}>X</Button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>


                <div className='modeller-model-form-header-container'>
                    <h5  style={{margin: "0 1rem 0 0"}}>
                        Akce
                    </h5>
                    <HelpPopover htmlContent={actionsHelpText} />
                </div>

                <Row>
                    <Col className='modeller-model-submit-button-container modeller-model-form-column'>
                            <Button
                                variant="primary"
                                type="submit"
                                className='modeller-model-submit-button flex-button'
                                disabled={loading}
                                // disabled
                                name='saveNew'
                            >
                                <SaveIcon />
                                Uložit
                            </Button>
                    </Col>
                    <Col className='modeller-model-submit-button-container modeller-model-form-column modeller-model-warning-button'>
                        <WarningPopover htmlContent={finishWarning} />
                        <Button
                            variant="primary"
                            type="submit"
                            className='modeller-model-submit-button flex-button'
                            disabled={loading}
                            // disabled
                            name='saveFinishNew'
                        >
                            <ExclamationMarkLightIcon />
                            Uložit a dokončit
                        </Button>
                        <WarningPopover htmlContent={finishWarning} />
                    </Col>
                    {/*<Col className='modeller-model-submit-button-container modeller-model-form-column'>*/}
                    {/*    <Button*/}
                    {/*        variant="primary"*/}
                    {/*        type="submit"*/}
                    {/*        className='modeller-model-submit-button'*/}
                    {/*        // disabled={loading}*/}
                    {/*        disabled*/}
                    {/*        name='saveFinishSendApprovalNew'*/}
                    {/*    >*/}
                    {/*        Dokončit a odeslat*/}
                    {/*    </Button>*/}
                    {/*</Col>*/}
                    <Col className='modeller-model-submit-button-container modeller-model-form-right-column'>
                        <Button
                            variant="danger"
                            className='modeller-model-submit-button flex-button'
                            // disabled={loading}
                            // disabled
                            onClick={handleFormReset}
                        >
                            <ReloadIcon />
                            Reset hodnot
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default ModelEditForm
