export enum HelpText {
    TD_OBJECT_API_STATUS =
        "<strong>nedokončený</strong> - model můžete upravovat a vytvářet nové verze<br/>\n" +
        "<hr>" +
        "<strong>dokončený</strong> - model byl dokončen a dále jej nelze upravovat<br/>\n" +
        "<hr>" +
        "<strong>odeslaný ke schválení</strong> - model byl odeslán ke schválení a zatím nebyl ohodnocen<br/>\n" +
        "<hr>" +
        "<strong>zamítnutý</strong> - model byl zamítnut historikem, grafikem nebo oběma<br/>" +
        "<hr>" +
        "<strong>schválený</strong> - model byl schválen grafikem a historikem<br/>" +
        "<hr>" +
        "<strong>zveřejněný</strong> - model byl zveřejněn<br/>" +
        "<hr>" +
        "<strong>Dokončit</strong> nebo <strong>Odeslat</strong> model můžete v <strong>Detailu</strong>",
    TD_OBJECT_DROPDOWN_ACTIONS =
        "<strong>3D</strong> - 3D model a základní informace<br/>\n" +
        "<hr>" +
        "<strong>Detail</strong> - detailní informace. <strong>Akce:</strong> <i>Upravit</i>, <i>Nová verze</i>, <i>Dokončit</i>, <i>Odeslat</i>, <i>Soubory</i>. <br/>\n" +
        "<hr>" +
        "<strong>Upravit</strong> - úprava dat uloženého modelu. Upravit lze pouze nedokončený a neodeslaný model. <strong>Akce:</strong> <i>Uložit</i>, <i>Uložit a dokončit</i><br/>" +
        "<hr>" +
        "<strong>Smazat</strong> - smazat tuto verzi. Lze pouze pro model neodeslaný ke schválení<br/>",
    TD_OBJECT_VIEW_ACTIONS =
        "<strong>Detail</strong> - detailní informace. <strong>Akce:</strong> <i>Upravit</i>, <i>Nová verze</i>, <i>Dokončit</i>, <i>Odeslat</i>, <i>Soubory</i>. <br/>\n" +
        "<hr>" +
        "<strong>Upravit</strong> - úprava dat uloženého modelu. Upravit lze pouze nedokončený a neodeslaný model. <strong>Akce:</strong> <i>Uložit</i>, <i>Uložit a dokončit</i><br/>\n" +
        "<hr>" +
        "<strong>Smazat</strong> - smazat lze pouze nejnovější verzi a neodeslaný model<br/>",
    FORM_ACTIONS_DETAIL =
        "<strong>Upravit</strong> - upravit data modelu<br/>\n" +
        "<hr>" +
        "<strong>Nová verze</strong> - vytvořit novou verzi k modelu (lze pouze před odesláním ke schválením)<br/>\n" +
        "<hr>" +
        "<strong>Dokončit</strong> - označit za dokončený (model nepůjde dále upravovat a půjde odeslat ke schválení)<br/>\n" +
        "<hr>" +
        "<strong>Odeslat</strong> - odeslat ke schválení (model musí být dokončený)<br/>" +
        "<hr>" +
        "<strong>Soubory</strong> - zobrazit stáhnutelné soubory<br/>" +
        "<hr>" +
        "<strong>Smazat</strong> - smazat lze pouze nejnovější verzi a pouze neodeslaný model<br/>",
    FORM_ACTIONS_CREATE =
        "<strong>Uložit</strong> - uložit model jako první verzi<br/>\n" +
        "<hr>" +
        "<strong>Uložit a dokončit</strong> - uložit model a označit za dokončený (model nepůjde upravit a půjde jej odeslat ke schválení)<br/>\n",
    FORM_ACTIONS_EDIT =
        "<strong>Uložit</strong> - uložit změny v tomto modelu<br/>\n" +
        "<hr>" +
        "<strong>Uložit a dokončit</strong> - uložit změny a označit za dokončený (model nepůjde upravit a půjde jej odeslat ke schválení)<br/>\n" +
        "<hr>" +
        "<strong>Reset hodnot</strong> - vyplní formulář daty z původního objektu. <strong> ! Změny budou ztraceny !</strong><br/>\n",
    UN_PUBLISH = "<strong>Privatizovat</strong> - zruší publikaci iterace - model již nebude veřejný<br/>\n",
    PUBLISH = "<strong>Publikovat</strong> - označí model za veřejný<br/>\n",
    TAGS_NOT_IMPLEMENTED = "<strong>Properties</strong> - neboli tagy - ještě nejsou v projektu Starý Most implementovány.<br/>\n",
    VERSION = "Zde vidíte <strong>nejnovější verzi</strong> 3D modelu. Starší verze nejsou dostupné.<br/>\n",
}
