import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import useUserContext from "../../contexts/user.context";
import { fetch3DObjectById } from "../../utils/api-fetchers/TDObject/TDObject-api-fetcher";
import { fetchStructureById } from "../../utils/api-fetchers/structure/structure-api-fetcher";
import { Iso8601ToLocaleDate } from "../../utils/formatters/date-formatter.util";
import { IApprovalProcessDetail } from "../../interfaces/IApprovalProcessDetail";
import { IApprovalProcessModelIteration } from "../../interfaces/IApprovalProcessModelIteration";
import { I3DObject } from "../../interfaces/I3DObject";
import { IStructure } from "../../interfaces/IStructure";
import MagnifyingGlassIcon from "../icons/magnifying-glass-icon.component";

interface IProps{
    approval : IApprovalProcessDetail,
    publicOnly?: boolean
}

interface ITDObjectsMap {
    [id: string]: I3DObject
}

const ApprovalProcessApprovedDropdown = (props: IProps) => {

    const navigate = useNavigate()

    const { approval, publicOnly } = props
    const { currentUser } = useUserContext()

    const [TDObjectsMap, setTDObjectsMap] = useState<ITDObjectsMap>({})
    const [structure, setStructure] = useState<IStructure|undefined>(undefined)

    // =============================== useEffects ===============================

    useEffect(() => {
        const fetchStructure = async () => {
            if (currentUser) {
                setStructure(await fetchStructureById(currentUser.token, approval.structureId))
            }
        }
        fetchStructure()
    }, [approval.structureId, currentUser])

    useEffect(() => {
        const fetch3DObjects = async () => {
            if (currentUser) {
                let newMap: ITDObjectsMap = {...TDObjectsMap}
                for (let i = 0; i < approval.modelIterations.length; i++) {
                    const TDObject = await fetch3DObjectById(currentUser.token, approval.modelIterations[i].tdObjectId)
                    newMap[TDObject.id] = TDObject
                }
                setTDObjectsMap(newMap)
            }
        }
        fetch3DObjects()
    }, [approval, currentUser])

    // =============================== ACTION HANDLERS ===============================

    const handleOpenIteration = (iteration: IApprovalProcessModelIteration) => {

        navigate('/administrator/publish-iteration/' + iteration.id, {
            state:
                {
                    modelIterationId: iteration.id
                }
        })
    }

    // =============================== UTILS ===============================

    let iterationsToDisplay = publicOnly
        ? approval.modelIterations.filter(
            iteration => iteration.historianApprovingId && iteration.graphicianApprovingId && iteration.finalizedDate)
        : approval.modelIterations.filter(
        iteration => iteration.historianApprovingId && iteration.graphicianApprovingId && ! iteration.finalizedDate
    )

    const compareIterations = (a: IApprovalProcessModelIteration, b: IApprovalProcessModelIteration) => {
        if (a.createdDate < b.createdDate) return -1
        if (a.createdDate > b.createdDate) return 1
        return 0
    }
    iterationsToDisplay.sort(compareIterations)

    // =============================== FORMATTERS ===============================

    const getFinalizedResult = (iteration: IApprovalProcessModelIteration) => {
        if (iteration.finalizedDate) {
            return Iso8601ToLocaleDate(iteration.finalizedDate)
        } else
            return "Ne"
    }

    // =============================== RETURN ===============================
    return (
        <>
            {
                iterationsToDisplay.length > 0 &&
                    <div className='approval-process-dropdown-container'>
                        <Accordion key={approval.id}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <Col>
                                        <span className='approver-approval-accordion-item-column-name'>
                                            {`Název: `}
                                        </span>
                                        <span>
                                            {approval.name}
                                        </span>
                                    </Col>

                                    <Col>
                                        <span className='approver-approval-accordion-item-column-name'>
                                            {`Struktura: `}
                                        </span>
                                        <span>
                                            {structure && structure.name}
                                        </span>
                                    </Col>
                                    <Badge bg='info' style={{margin: "0 10px 0 0"}}>{iterationsToDisplay.length}</Badge>
                                </Accordion.Header>

                                <Accordion.Body>
                                    <Row>
                                        <Col>
                                            <h5>Schválené iterace modelu</h5>
                                        </Col>
                                    </Row>
                                    {
                                        iterationsToDisplay.length === 0
                                            ?
                                            <Row>
                                                <Alert variant='info'>Nejsou k dispozici žádné iterace modelu.</Alert>
                                            </Row>
                                            :
                                            <Table striped bordered hover>
                                                <thead>
                                                <tr>
                                                    <th>Název</th>
                                                    <th>Vytvořeno</th>
                                                    <th>Zveřejněno</th>
                                                    <th>Akce</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    iterationsToDisplay.map(iteration =>
                                                        <tr key={iteration.id}>
                                                            <td>{iteration.name}</td>
                                                            <td>{iteration && Iso8601ToLocaleDate(iteration.createdDate)}</td>
                                                            <td>
                                                                {
                                                                    getFinalizedResult(iteration)
                                                                }
                                                            </td>
                                                            <td>
                                                                <Button onClick={() => handleOpenIteration(iteration)} className='flex-button'>
                                                                    <MagnifyingGlassIcon />
                                                                    Zobrazit
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </Table>
                                    }

                                    {/*<Row>*/}
                                    {/*    <h5>Iterace variant modelu</h5>*/}
                                    {/*</Row>*/}
                                    {/*{*/}
                                    {/*    true*/}
                                    {/*        ?*/}
                                    {/*        <Row>*/}
                                    {/*            <Alert variant='info'>Nejsou k dispozici žádné iterace variant modelu.</Alert>*/}
                                    {/*        </Row>*/}
                                    {/*        :*/}
                                    {/*        <Table striped bordered hover>*/}
                                    {/*            <thead>*/}
                                    {/*            <tr>*/}
                                    {/*                <th>Název</th>*/}
                                    {/*                <th>Vytvořeno</th>*/}
                                    {/*                <th>Akce</th>*/}
                                    {/*            </tr>*/}
                                    {/*            </thead>*/}
                                    {/*            <tbody>*/}
                                    {/*            {*/}

                                    {/*                approval.variants.map(iteration =>*/}
                                    {/*                    <tr>*/}
                                    {/*                        <td>{iteration.name}</td>*/}
                                    {/*                        <td>{iteration && Iso8601ToLocaleDate(iteration.createdDate)}</td>*/}
                                    {/*                        <td>*/}
                                    {/*                            <Button>Zobrazit</Button>*/}
                                    {/*                        </td>*/}
                                    {/*                    </tr>*/}
                                    {/*                )*/}
                                    {/*            }*/}
                                    {/*            </tbody>*/}
                                    {/*        </Table>*/}
                                    {/*}*/}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
            }
        </>
    )
}

export default ApprovalProcessApprovedDropdown
