import './homepage-logged-in.styles.scss'

const HomepageLoggedIn = () => {
    return (
        <div className='homepage-logged-in-container'>
            <div className='homepage-logged-in-header-container'>
                <h1>Vítejte v aplikaci pro projekt Starý Most!</h1>
            </div>

            <div className='homepage-logged-in-row-container'>
                <h4>V této aplikaci Vám bude umožněno spravovat data projektu Starý Most.</h4>
            </div>

            <div className='homepage-logged-in-row-container'>
                <h5>V navigačním menu vidíte svůj username, svou roli a veškerá dostupná navigační tlačítka.</h5>
            </div>
            <div className='homepage-logged-in-row-container'>
                <h5>Role <b>modelář</b> umožňuje nahrávat vlastní 3D modely a odesílat je ke schválení a publikaci.</h5>
                <h5>Role <b>historik</b> či <b>grafik</b> umožňuje schvalovat a zamítat dokončené 3D modely.</h5>
                <h5>Role <b>administrátor</b> umožňuje spravovat ulice, struktury, 3D modely a fotografie. Zároveň umožňuje i publikovat schválené modely.</h5>
            </div>
        </div>
    )
}

export default HomepageLoggedIn
