import { useEffect, useState } from "react";

import Alert from "react-bootstrap/Alert";

import ApprovalProcessDropdown from "../../../components/approval-process-dropdown/approval-process-dropdown.component";
import useUserContext from "../../../contexts/user.context";
import { fetchUserApprovals } from "../../../utils/api-fetchers/aproval-process/aproval-process-api-fetcher";
import { IApprovalProcessDetail } from "../../../interfaces/IApprovalProcessDetail";

import './approver-approvals-overview.styles.scss'

const ApproverAllApprovals = () => {
    const { currentUser } = useUserContext()

    const [approvals, setApprovals] = useState<IApprovalProcessDetail[]>([])

    useEffect(() => {
        const getApprovals = async () => {
            if (currentUser) {
                const approvals = await fetchUserApprovals(currentUser.token)
                setApprovals(approvals)
            }
        }
        getApprovals()
    }, [currentUser])

    return (
        <div className='approver-approvals-container'>
            <div className='approver-approvals-header-container'>
                <h2>
                    Schvalovací procesy
                </h2>
                <h5>Procesy, jichž jsem členem</h5>
            </div>
            {
                approvals.length === 0
                    ? <Alert variant='info'>Žádné procesy k zobrazení.</Alert>
                    : approvals.map(approval =>
                        <ApprovalProcessDropdown
                            key={approval.id}
                            approval={approval}
                            displayDecided={true}
                        />)
            }
        </div>
    )
}

export default ApproverAllApprovals
