import React from "react";
import Iframe from "react-iframe";

import './model-viewer.styles.scss'
import { CameraOption, ControlsOption, ModelFormat, ModelView } from "@antospa2/interactive-3d-viewer";
import ENV from "../../env";
import useUserContext from "../../contexts/user.context";

interface IProps {
    modelId: string
    modelFormat?: string | null
}

const ModelViewer = (props: IProps) => {
    const { modelId, modelFormat } = props
    const { currentUser } = useUserContext()
    const apiUrl = ENV.privateApiUrl

    let format = ModelFormat.fbx
    if (modelFormat) {
        switch(modelFormat) {
            case 'FBX': format = ModelFormat.fbx; break;
            case 'GLTF': format = ModelFormat.gltf; break;
            case 'OBJ': format = ModelFormat.obj; break;
        }
    }
    
    return(
        <div className='model-view-container'>
            <ModelView style={{}}
                model={ {url: apiUrl + "/models/" + modelId, format: format} }
                cameraOption={CameraOption.perspective}
                controlsOption={ControlsOption.Orbit}
                requestHeaders={{ Authorization: 'Bearer ' +  currentUser?.token}}
                environmentParams={{}}
            />
        </div>
    )
}

export default ModelViewer
