import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LoadingModal from "../../../components/loading-modal/loading-modal.component";
import ModelCreationForm from "../../../components/TDObject-creation-form/TDObject-creation-form.component";
import useUserContext from "../../../contexts/user.context";
import ErrorAndSuccessModals from "../../../components/error-and-success-modals/error-and-success-modals.component";
import TexturesWarningModal from "../../../components/textures-warning-modal/textures-warning-modal.component";
import {
    parseTextureFilesArray,
    parseTextureTypesArray,
    parseTransformation,
    parseTransformationWeb
} from "../../../utils/parsers/form-input-event-parser";
import {
    addAssetsTo3DObject,
    addTexturesTo3DObject,
    update3DObjectStatus
} from "../../../utils/api-fetchers/TDObject/TDObject-api-fetcher";
import { createFirst3DObjectForStructure } from "../../../utils/api-fetchers/structure/structure-api-fetcher";
import { FormActions } from "../../../enums/form-actions.enum";
import { versionArrayToString } from "../../../utils/formatters/version-formatter.util";
import { I3DObject } from "../../../interfaces/I3DObject";
import ITransformation from "../../../interfaces/ITransformation";

import './modeller-model-creation.styles.scss'

const ModellerModelCreation = () => {

    const navigate = useNavigate()

    const { currentUser } = useUserContext()

    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false)
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false)
    const [isTexturesWarningOpen, setIsTexturesWarningOpen] = useState(false)

    const [loading, setLoading] = useState(false)

    const [modelFile, setModelFile] = useState<any>(null)
    const [assetsFiles, setAssetsFiles] = useState<any[]>([])
    const [tagsObject, setTagsObject] = useState({})

    // =========================== SUBMIT ====================== //

    const handleSubmit = async (event: any) => {
        setLoading(true)
        console.log('submit')
        event.preventDefault();

        const name = event.currentTarget.modelName.value
        const structureId = event.currentTarget.modelStructure.value
        const transformation = parseTransformation(event)
        const transformationWeb = parseTransformationWeb(event)

        const action = event.nativeEvent.submitter.name

        switch (action) {
            case FormActions.SAVE_NEW: {
                let texturesTypes: string[] = []
                let texturesFiles: File[] = []
                try {
                    texturesTypes = parseTextureTypesArray(event)
                    texturesFiles = parseTextureFilesArray(event)
                } catch (e) {
                    setIsTexturesWarningOpen(true)
                    setLoading(false)
                    return
                }
                await handleCreateNewModel(
                    name,
                    structureId,
                    transformation,
                    transformationWeb,
                    texturesFiles,
                    texturesTypes,
                    assetsFiles,
                    tagsObject
                )

                break
            }
            case FormActions.SAVE_FINISH_NEW: {
                let texturesTypes: string[] = []
                let texturesFiles: File[] = []
                try {
                    texturesTypes = parseTextureTypesArray(event)
                    texturesFiles = parseTextureFilesArray(event)
                } catch (e) {
                    setIsTexturesWarningOpen(true)
                    setLoading(false)
                    return
                }
                await handleCreateAndFinishModel(
                    name,
                    structureId,
                    transformation,
                    transformationWeb,
                    texturesFiles,
                    texturesTypes,
                    assetsFiles,
                    tagsObject)
                break
            }
            default: {
                console.log('unknown action')
                break
            }
        }
        setLoading(false)
    }

    // ================================ ACTION HANDLERS ================================

    const handleCreateAndFinishModel = async (
        name: string,
        structureId: string,
        transformation: ITransformation,
        transformationWeb: ITransformation,
        texturesFiles: File[],
        texturesTypes: string[],
        assetsFiles: File[],
        tagsObject: any
    ) => {
        const result = await handleCreateNewModel(
            name,
            structureId,
            transformation,
            transformationWeb,
            texturesFiles,
            texturesTypes,
            assetsFiles,
            tagsObject
        )
        try {
            if (result && currentUser)
                await update3DObjectStatus(
                    currentUser.token,
                    result.id,
                    (typeof result.version ==='string') ? result.version : versionArrayToString(result?.version),
                    result.name,
                    'finished')
        } catch (e) {
            console.log('catching error', e)
            setIsErrorAlertOpen(true)
            setLoading(false)
        }
    }

    const handleCreateNewModel = async (
        name: string,
        structureId: string,
        transformation: ITransformation,
        transformationWeb: ITransformation,
        texturesFiles: File[],
        texturesTypes: string[],
        assetsFiles: File[],
        tagsObject: any) => {

        const result = await createNewModel(name, modelFile, structureId, transformation, transformationWeb, tagsObject)
        if ( !result) {
            return
        } else {
            if (texturesFiles.length > 0)
                await createTextures(result.id, texturesFiles, texturesTypes)
            if (assetsFiles.length > 0)
                await createAssets(result.id)
            setIsSuccessAlertOpen(true)
            return result
        }
    }

    const createNewModel = async (
        TDObjectName: string,
        file: any,
        structureId: string,
        transformation: ITransformation,
        transformationWeb: ITransformation,
        tagsObject: any
    ) : Promise<I3DObject | void> => {

        if (currentUser) {
            try {
                return await createFirst3DObjectForStructure(
                    currentUser?.token,
                    structureId,
                    TDObjectName,
                    file,
                    transformation,
                    transformationWeb,
                    tagsObject
                )
            } catch (e) {
                setIsErrorAlertOpen(true)
                setLoading(false)
            }
        }
    }

    // ================================ TEXTURES / ASSETS HANDLERS ================================

    const createTextures = async (TDObjectId: string, texturesFiles: File[], texturesTypes: string[]) => {
        try {
            if (currentUser)
                return await addTexturesTo3DObject(currentUser?.token, TDObjectId, texturesFiles, texturesTypes)
        } catch (e) {
            setIsErrorAlertOpen(true)
            setLoading(false)
        }
    }
    const createAssets = async (TDObjectId: string) => {
        try {
            if (currentUser)
                return await addAssetsTo3DObject(currentUser?.token, TDObjectId, assetsFiles)
        } catch (e) {
            setIsErrorAlertOpen(true)
            setLoading(false)
        }
    }



    // ================================ STATE UPDATERS ================================
    const handleFileChange = (event: any) => {
        setModelFile(
            event.target.files[0]
        )
    }

    const handleAssetsChange = (event: any) => {
        setAssetsFiles(Array.from(event.target.files))
    }

    // ================================ MODALS HANDLERS ================================

    const handleCloseErrorAlert = () => {
        setIsErrorAlertOpen(false);
    };

    const handleCloseSuccessAlert = () => {
        setIsSuccessAlertOpen(false)
        navigate('/modeller/all-creations')
    };

    // const handleCloseFinishWarning = () => {
    //     setLoading(false)
    //     setIsFinishWarningOpen(false)
    // };
    //
    // const handleYesFinishWarning = () => {
    //     setFinishConfirmed(true)
    //     setIsFinishWarningOpen(false)
    // };

    // ================================ RETURN ================================

    return (
        <div className='modeller-creation-container'>
            <div className='modeller-creation-header-container'>
                <h2>
                    Tvorba nového 3D modelu
                </h2>
            </div>
            <ModelCreationForm
                handleSubmit={handleSubmit}
                handleFileChange={handleFileChange}
                handleAssetsChange={handleAssetsChange}
                loading={loading}
                setTagsObject={setTagsObject}
            />
            <ErrorAndSuccessModals
                isErrorAlertOpen={isErrorAlertOpen}
                handleCloseErrorAlert={handleCloseErrorAlert}
                isSuccessAlertOpen={isSuccessAlertOpen}
                handleCloseSuccessAlert={handleCloseSuccessAlert}
            />
            <TexturesWarningModal isOpen={isTexturesWarningOpen} setIsOpen={setIsTexturesWarningOpen} />
            <LoadingModal loading={loading} />
            {/*<FinishWarningModal open={isFinishWarningOpen} handleClose={handleCloseFinishWarning} handleYes={handleYesFinishWarning} />*/}
        </div>
    )
}

export default ModellerModelCreation;
