import { useEffect, useState } from "react";

import useUserContext from "../../../contexts/user.context";
import { Link, useParams } from "react-router-dom";
import { IStructure } from "../../../interfaces/IStructure";
import { fetchStructureById } from "../../../utils/api-fetchers/structure/structure-api-fetcher";
import TDObjectDropdown from "../../../components/TDObject-dropdown/TDObject-dropdown.component";
import { I3DObject } from "../../../interfaces/I3DObject";
import { Button } from "react-bootstrap";
import { delete3DObjectVersion } from "../../../utils/api-fetchers/TDObject/TDObject-api-fetcher";
import { versionArrayToString } from "../../../utils/formatters/version-formatter.util";
import ErrorAndSuccessModals from "../../../components/error-and-success-modals/error-and-success-modals.component";

const TDOjbects = () => {

    const { id } = useParams()
    const { currentUser } = useUserContext()
    const [structure, setStructure] = useState<IStructure>()
    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false)
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false)

    const reload = async () => {
        if (currentUser && id)
            setStructure(await fetchStructureById(currentUser.token, id))
    }

    useEffect(() => {
        reload()
    }, [currentUser, id])

    const handleObjectDelete = async (entity: I3DObject) => {
        try {
            if (currentUser) {
                await delete3DObjectVersion(currentUser?.token, entity.id, (typeof entity.version ==='string') ? entity.version : versionArrayToString(entity?.version))
                reload()
            }
            setIsSuccessAlertOpen(true)
        } catch (e) {
            console.log('catching error ', e)
            setIsErrorAlertOpen(true)
        }
    }

    const handleCloseErrorAlert = () => {
        setIsErrorAlertOpen(false);
    };

    const handleCloseSuccessAlert = () => {
        setIsSuccessAlertOpen(false)
    };

    return (
        <div className="structures-container">
            <div>
                <h2>
                    3D objekty struktury "{structure?.name}"
                </h2>
            </div>
            <div>
                <Link to={`/manager/tdobjects/create/${structure?.id}`}>
                    <Button>
                        Vytvořit nový
                    </Button>
                </Link>
            </div>
            {
            structure &&
            structure.allVariants.map(tdobject =>
                <TDObjectDropdown TDObject={tdobject} handleDelete={handleObjectDelete} />
            )
            }
            <ErrorAndSuccessModals
                isErrorAlertOpen={isErrorAlertOpen}
                handleCloseErrorAlert={handleCloseErrorAlert}
                isSuccessAlertOpen={isSuccessAlertOpen}
                handleCloseSuccessAlert={handleCloseSuccessAlert}
            />
        </div>
    )
}
export default TDOjbects
