export enum TDObjectStatus {
    UNFINISHED = 'nedokončený',
    FINISHED = 'dokončený',
    SUBMITTED = 'odeslaný ke schválení',
    DECLINED = 'zamítnutý',
    APPROVED = 'schválený',
    FINALIZED = 'zveřejněný',
    DEFAULT = 'neznámý',
}

export const czechTDObjectStatus = (status: string) => {
    switch (status) {
        case 'unfinished': {
            return TDObjectStatus.UNFINISHED
        }
        case 'finished': {
            return TDObjectStatus.FINISHED
        }
        case 'submitted': {
            return TDObjectStatus.SUBMITTED
        }
        case 'declined': {
            return TDObjectStatus.DECLINED
        }
        case 'approved': {
            return TDObjectStatus.APPROVED
        }
        case 'finalized': {
            return TDObjectStatus.FINALIZED
        }
        default:
            return TDObjectStatus.DEFAULT
    }
}
