import ENV from "../../../env";

const apiUrl = ENV.privateApiUrl

export const fetchTextureById = async (token: string, id: string): Promise<any> => {
    return (fetch(apiUrl + "/textures/" + id, {
        headers:{
            'Accept': 'application/json',
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "GET"
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response
    }));
}
