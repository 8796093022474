import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { CookiesProvider } from "react-cookie";

import 'bootstrap/dist/css/bootstrap.css';
import reportWebVitals from './reportWebVitals';

import App from './App';
import { UserProvider } from "./contexts/user.context";
import ENV from "./env";

import './index.css';
import {TasksCountProvider} from "./contexts/tasks-count.context";

const clientId = ENV.clientId

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <CookiesProvider>
            <GoogleOAuthProvider clientId={clientId}>
                <BrowserRouter>
                    <UserProvider>
                        <TasksCountProvider>
                            <App />
                        </TasksCountProvider>
                    </UserProvider>
                </BrowserRouter>
            </GoogleOAuthProvider>
        </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
