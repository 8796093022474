import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Button from "react-bootstrap/Button";

import MagnifyingGlassIcon from "../icons/magnifying-glass-icon.component";
import useUserContext from "../../contexts/user.context";
import { IStreet } from "../../interfaces/IStreet";
import { Table } from "react-bootstrap";
import EditIcon from "../icons/edit-icon.component";
import DeleteIconNarrow from "../icons/delete-icon-narrow.component";


import './street-table.styles.scss'
import { deleteStreet } from "../../utils/api-fetchers/street/street-api-fetcher";
import DeleteConfirmation from "../delete-confirmation-modal/delete-confirmation-modal.component";
import DeleteImpossibleModal from "../delete-impossible-modal/delete-impossible-modal.component";


interface IProps {
    streets: IStreet[],
    reload: () => Promise<void>
}

const StreetTable = (props: IProps) => {

    const { streets, reload } = props
    const { currentUser } = useUserContext()
    const [deleteId, setDeleteId] = useState('')
    const [showModal, setShowModal] = useState(false)
    const [deleteImpossibleOpen, setDeleteImpossibleOpen] = useState(false)

    const handleDelete = async (id: string) => {
        if (currentUser) {
            await deleteStreet(currentUser?.token, id)
            reload()
        }
        handleCloseModal()
    }

    const handleCloseModal = () => {
        setShowModal(false);
      };

    const showDeleteModal = (id: string) => {
        const street = streets.find(x => x.id === id)
        if (street && street.structures && street.structures.length > 0) {
            setDeleteImpossibleOpen(true)
            return
        }
        setDeleteId(id)
        setShowModal(true)
    }

    const deleteImpossibleClose = () => {
        setDeleteImpossibleOpen(false)
    }

    return(
        <div className='street-table-container'>
            <Table className="street-table">
                <thead>
                    <tr>
                        <th>Název</th>
                        <th className="action-column">Akce</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        streets.map(street => (
                            <tr>
                                <td>{street.name}</td>
                                <td className="street-table-buttons">
                                        <Link to={`/manager/streets/detail/${street.id}`}>
                                            <Button className='flex-button'>
                                                <MagnifyingGlassIcon />
                                                Detail
                                            </Button>
                                        </Link>
                                        <Link to={`/manager/streets/edit/${street.id}`}>
                                            <Button className='flex-button'>
                                                <EditIcon />
                                                Upravit
                                            </Button>
                                        </Link>
                                        <Button variant='danger' onClick={() => showDeleteModal(street.id)} className='flex-button'>
                                            <DeleteIconNarrow />
                                        </Button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>
            <DeleteConfirmation handleDelete={handleDelete} id={deleteId} show={showModal} onHide={handleCloseModal} />
            <DeleteImpossibleModal isOpen={deleteImpossibleOpen} handleClose={deleteImpossibleClose} />
        </div>
    )
}

export default StreetTable
