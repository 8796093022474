import { Col, Container, Row } from "react-bootstrap";
import { IPhoto } from "../../interfaces/IPhoto";
import { useEffect, useState } from "react";
import { fetchPhoto } from "../../utils/api-fetchers/photos/photos-api-fetcher";
import useUserContext from "../../contexts/user.context";

import './photo-gallery.styles.scss'
import PhotoLightbox from "../photo-lightbox/photo-lightbox.component";
import DeleteConfirmation from "../delete-confirmation-modal/delete-confirmation-modal.component";

interface AuthorizedImageProps {
  src: string;
  alt: string;
  token: string;
  photoId: string;
  deletePhoto: (photoId: string) => void;
}

const AuthorizedImage: React.FC<AuthorizedImageProps> = (props: AuthorizedImageProps) => {
  const { src, alt, token, photoId, deletePhoto } = props
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const fetch = async () => {
      setImageUrl(URL.createObjectURL(await fetchPhoto(src, token)))
    }

    fetch();
  }, [src, token]);

  const handleDelete = () => {
    deletePhoto(photoId)
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
  <>
    { imageUrl &&
    <div className="image-container">
      <img src={imageUrl} alt={alt} className="gallery-photo" onClick={() => setShowModal(true)} />
      <div
        onClick={() => setShowDeleteModal(true)}
        className="remove-button"
      >
        X
      </div>
      <PhotoLightbox show={showModal} onHide={handleCloseModal} src={imageUrl} alt={alt} />
      <DeleteConfirmation handleDelete={handleDelete} id={photoId} show={showDeleteModal} onHide={handleCloseDeleteModal} />
    </div>
    }
  </>
  )
}

interface IProps {
  photos: IPhoto[];
  deletePhoto: (photoId: string) => void,
}
  
const PhotoGallery = (props: IProps) => {
  const { photos, deletePhoto } = props
  const { currentUser } = useUserContext()

  return (
    <Row xs={1} md={2} lg={4} className="g-4">
    {currentUser &&
      photos.map((photo) => (
        <Col key={photo.id}>
          <div className="gallery-item">
            <AuthorizedImage
              src={photo.href}
              alt={photo.filename}
              token={currentUser.token}
              photoId={photo.id}
              deletePhoto={deletePhoto}
            />
          </div>
        </Col>
      ))}
  </Row>
  )
}
  
export default PhotoGallery;
