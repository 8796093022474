import { createContext, ReactNode, useContext, useState } from "react";

const TasksCountContext = createContext<{tasksCount: number, setTasksCount: (count: number) => void}>({
    tasksCount: 0,
    setTasksCount: () => null
});

export default function useTasksCountContext() {
    return useContext(TasksCountContext)
}

export const TasksCountProvider = ({children}: {children: ReactNode}) => {
    const [tasksCount, setTasksCount] = useState(0)
    const value = { tasksCount, setTasksCount }

    return <TasksCountContext.Provider value={value}>{children}</TasksCountContext.Provider>
}
