import { czechRole } from "../../enums/roles.enum";
import { IComment } from "../../interfaces/IComment";

import './approval-iteration-comment.styles.scss'

interface IProps {
    comment: IComment
}

const ApprovalIterationComment = (props: IProps) => {

    const { comment } = props
    const commentAuthor = comment.by
    const authorCzechRole = czechRole(commentAuthor.role)

    return(
        <h6>{commentAuthor.name} ({authorCzechRole}): {comment.content.text}</h6>
    )
}

export default ApprovalIterationComment
