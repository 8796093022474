import { Route, Routes } from "react-router-dom";
import Streets from "./streets/streets.component";
import StreetCreate from "./streets/create/street-create.component";
import StreetDetail from "./streets/detail/streets-detail.component";
import StructureCreate from "./structures/create/structure-create.component";
import StructureDetail from "./structures/detail/structure-detail.component";
import TDOjbects from "./tdobjects/tdobjects.component";
import TDObjectCreation from "./tdobjects/tdobject-creation.component";

const ManagerComponent = () => {

    return (
        <Routes>
            <Route path='streets' element={<Streets />}/>
            <Route path='streets/create' element={<StreetCreate />}/>
            <Route path='streets/edit/:id' element={<StreetCreate />}/>
            <Route path='streets/detail/:id' element={<StreetDetail />}/>
            <Route path='structures/create/:streetId' element={<StructureCreate />}/>
            <Route path='structures/edit/:structureId' element={<StructureCreate />}/>
            <Route path='structures/detail/:id' element={<StructureDetail />}/>
            <Route path='tdobjects/:id' element={<TDOjbects />}/>
            <Route path='tdobjects/create/:id' element={<TDObjectCreation />}/>
        </Routes>
    )
}

export default ManagerComponent;
