import ITransformation from "../../interfaces/ITransformation";

export const defaultTransformation: ITransformation = {
    "translation": [
        0,
        0,
        0
    ],
    "scale": [
        1,
        1,
        1
    ],
    "rotation": [
        0,
        0,
        0,
        1
    ]
}

export const defaultTransformationWeb: ITransformation = {
    "translation": [
        0,
        0,
        0
    ],
    "scale": [
        1,
        1,
        1
    ],
    "rotation": [
        0,
        0,
        0,
        1
    ]
}
