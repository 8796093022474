import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import LoadingModal from "../../../components/loading-modal/loading-modal.component";
import ModelEditForm from "../../../components/TDObject-edit-form/TDObject-edit-form.component";
import useUserContext from "../../../contexts/user.context";
import ErrorAndSuccessModals from "../../../components/error-and-success-modals/error-and-success-modals.component";
import TexturesWarningModal from "../../../components/textures-warning-modal/textures-warning-modal.component";
import { versionArrayToString } from "../../../utils/formatters/version-formatter.util";
import {
    addAssetsTo3DObject,
    addTexturesTo3DObject, update3DObjectStatus,
    update3DObjectVersion
} from "../../../utils/api-fetchers/TDObject/TDObject-api-fetcher";
import {
    parseAssetsFilesArray,
    parseModelFile, parseTextureFilesArray, parseTextureTypesArray,
    parseTransformation,
    parseTransformationWeb
} from "../../../utils/parsers/form-input-event-parser";
import { FormActions } from "../../../enums/form-actions.enum";
import { I3DObject } from "../../../interfaces/I3DObject";
import ITransformation from "../../../interfaces/ITransformation";

import './unfinished-model-edit.styles.scss'


const UnfinishedModeEdit = () => {


    const { currentUser } = useUserContext()

    const location = useLocation();
    const { state } = location
    const { TDObject } = state

    const navigate = useNavigate()


    const [loading, setLoading] = useState(false)

    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false)
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false)
    const [isTexturesWarningOpen, setIsTexturesWarningOpen] = useState(false)

    // =========================== SUBMIT ===========================

    const handleSubmit = async (event: any) => {
        setLoading(true)
        event.preventDefault()

        const modelFile = parseModelFile(event)
        const name = event.currentTarget.modelName.value
        const transformation = parseTransformation(event)
        const transformationWeb = parseTransformationWeb(event)

        const action = event.nativeEvent.submitter.name

        switch (action) {
            case FormActions.SAVE_NEW: {
                await handleSave(event, name, modelFile, transformation, transformationWeb)
                break
            }
            case FormActions.SAVE_FINISH_NEW: {
                const result = await handleSave(event, name, modelFile, transformation, transformationWeb)
                await handleFinishModel(result)
                break;
            }
            default: {
                console.log('unknown action')
                break
            }
        }
        setLoading(false)
    }

    // =========================== ACTION HANDLERS ===========================

    const handleFinishModel = async (TDObject: I3DObject) => {
        const version = typeof TDObject.version === 'string' ? TDObject.version : versionArrayToString(TDObject.version)
        try {
            if (currentUser) {
                await update3DObjectStatus(currentUser.token, TDObject.id, version, TDObject.name, 'finished')
            }
            setIsSuccessAlertOpen(true)
        } catch (e) {
            setIsErrorAlertOpen(true)
        }
    }

    const handleSave = async (event: any, name: string, modelFile: File, transformation: ITransformation, transformationWeb: ITransformation) => {
        let texturesTypes: string[] = []
        let texturesFiles: File[] = []

        try {
            texturesTypes = parseTextureTypesArray(event)
            texturesFiles = parseTextureFilesArray(event)
        } catch (e) {
            setIsTexturesWarningOpen(true)
            setLoading(false)
            return
        }

        const assetsFiles = parseAssetsFilesArray(event)

        return await handleEditModel(
            name,
            modelFile,
            transformation,
            transformationWeb,
            texturesFiles,
            texturesTypes,
            assetsFiles
        )
    }

    const handleEditModel = async (
        name: string,
        modelFile: File,
        transformation: ITransformation,
        transformationWeb: ITransformation,
        texturesFiles: File[],
        texturesTypes: string[],
        assetsFiles: File[]
    ) => {
        const result = await updateModel(name, modelFile, transformation, transformationWeb)
        if (! result)
            return
        else {
            // if (texturesFiles.length > 0)
            //     await createTextures(result.id, texturesFiles, texturesTypes)
            // if (assetsFiles.length > 0)
            //     await createAssets(result.id, assetsFiles)
            setIsSuccessAlertOpen(true)
            return result
        }
    }

    // const createTextures = async (TDObjectId: string, texturesFiles: File[], texturesTypes: string[]) => {
    //     try {
    //         if (currentUser)
    //             return await addTexturesTo3DObject(currentUser?.token, TDObjectId, texturesFiles, texturesTypes)
    //     } catch (e) {
    //         setIsErrorAlertOpen(true)
    //     }
    // }
    // const createAssets = async (TDObjectId: string, assetsFiles: File[]) => {
    //     try {
    //         if (currentUser)
    //             return await addAssetsTo3DObject(currentUser?.token, TDObjectId, assetsFiles)
    //     } catch (e) {
    //         setIsErrorAlertOpen(true)
    //     }
    // }

    const updateModel = async (
        name: string,
        modelFile: File,
        transformation: ITransformation,
        transformationWeb: ITransformation) => {
        if (currentUser) {
            try {
                return await update3DObjectVersion(
                    currentUser.token,
                    TDObject.id, name,
                    (typeof TDObject.version ==='string') ? TDObject.version : versionArrayToString(TDObject.version),
                    modelFile,
                    transformation,
                    transformationWeb)
            } catch (e) {
                console.log('catching error', e)
                setIsErrorAlertOpen(true)
            }
        }
    }

    // =========================== ALERTS HANDLERS ===========================

    const handleCloseErrorAlert = () => {
        setIsErrorAlertOpen(false);
    };

    const handleCloseSuccessAlert = () => {
        setIsSuccessAlertOpen(false)
        navigate('/modeller/model-detail-form/' + TDObject.id, {
            state: {
                TDObject: TDObject
            }
        })
    };

    return(
        <div className='modeller-creation-container'>
            <div className='modeller-creation-header-container'>
                <h2>
                    Úprava modelu
                </h2>
            </div>

            <ModelEditForm handleSubmit={handleSubmit}  default3DObject={TDObject} loading={loading}/>
            <ErrorAndSuccessModals
                isErrorAlertOpen={isErrorAlertOpen}
                handleCloseErrorAlert={handleCloseErrorAlert}
                isSuccessAlertOpen={isSuccessAlertOpen}
                handleCloseSuccessAlert={handleCloseSuccessAlert}
            />
            <TexturesWarningModal isOpen={isTexturesWarningOpen} setIsOpen={setIsTexturesWarningOpen} />
            <LoadingModal loading={loading} />
        </div>
    )
}

export default UnfinishedModeEdit
