import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Accordion from "react-bootstrap/Accordion";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";

import useUserContext from "../../contexts/user.context";
import MagnifyingGlassIcon from "../icons/magnifying-glass-icon.component";
import { fetch3DObjectById } from "../../utils/api-fetchers/TDObject/TDObject-api-fetcher";
import { fetchStructureById } from "../../utils/api-fetchers/structure/structure-api-fetcher";
import { Iso8601ToLocaleDate } from "../../utils/formatters/date-formatter.util";
import { IApprovalProcessDetail } from "../../interfaces/IApprovalProcessDetail";
import { IApprovalProcessModelIteration } from "../../interfaces/IApprovalProcessModelIteration";
import { I3DObject } from "../../interfaces/I3DObject";

import { IStructure } from "../../interfaces/IStructure";
import './approval-process-dropdown.styles.scss'

interface IProps{
    approval : IApprovalProcessDetail,
    displayDecided: boolean,
    modeller?: boolean
}

interface ITDObjectsMap {
    [id: string]: I3DObject
}

const ApprovalProcessDropdown = (props: IProps) => {

    const navigate = useNavigate()

    const { approval, displayDecided, modeller } = props
    const { currentUser } = useUserContext()

    const [TDObjectsMap, setTDObjectsMap] = useState<ITDObjectsMap>({})
    const [structure, setStructure] = useState<IStructure | undefined>(undefined)

    // =============================== useEffects ===============================

    useEffect(() => {
        const fetchStructure = async () => {
            if (currentUser) {
                setStructure(await fetchStructureById(currentUser.token, approval.structureId))
            }
        }
        fetchStructure()
    }, [approval.structureId, currentUser])

    useEffect(() => {
        const fetch3DObjects = async () => {
            if (currentUser) {
                let newMap: ITDObjectsMap = {...TDObjectsMap}
                for (let i = 0; i < approval.modelIterations.length; i++) {
                    const TDObject = await fetch3DObjectById(currentUser.token, approval.modelIterations[i].tdObjectId)
                    // console.log(TDObject)
                    // console.log(currentUser)

                    if (currentUser.role === 'commonUser' && TDObject.userId !== currentUser.id) {
                        continue
                    }
                    newMap[TDObject.id] = TDObject
                }
                setTDObjectsMap(newMap)
            }
        }
        fetch3DObjects()
    }, [approval, currentUser])


    let userRole = ''
    if (currentUser)
        userRole = currentUser.role

    // =============================== CHECKING CONDITIONS ===============================

    const isDecidedByRole = (iteration: IApprovalProcessModelIteration): boolean => {
        if (userRole === 'historian')
            return iteration.historianApprovingId !== null ||
                iteration.historianDecliningId !== null
        if (userRole === 'approver')
            return iteration.graphicianApprovingId !== null ||
                iteration.graphicianDecliningId !== null
        return false
    }

    const isInSubmittedState = (iteration: IApprovalProcessModelIteration): boolean => {
        const TDObject = TDObjectsMap[iteration.tdObjectId]
        // console.log(TDObject)
        if (TDObject) return TDObject.status === 'submitted'
        return false
    }

    // =============================== ACTIONS HANDLERS ===============================

    const handleOpenIteration = (iteration: IApprovalProcessModelIteration) => {

        if (modeller) {
            navigate('/modeller/iteration-approval/' + iteration.id, {
                state:
                    {
                        modelIteration: iteration
                    }
            })
        } else {
            navigate('/approver/iteration-approval/' + iteration.id, {
                state:
                    {
                        modelIteration: iteration
                    }
            })
        }
    }


    // =============================== CONSTANTS ===============================
    const undecidedModelIteration = approval.modelIterations.filter(iteration => ! isDecidedByRole(iteration) && isInSubmittedState(iteration))

    let iterationsToDisplay = displayDecided ? approval.modelIterations : undecidedModelIteration

    const compareIterations = (a: IApprovalProcessModelIteration, b: IApprovalProcessModelIteration) => {
        if (a.createdDate < b.createdDate) return -1
        if (a.createdDate > b.createdDate) return 1
        return 0
    }
    iterationsToDisplay.sort(compareIterations)

    // =============================== FORMATTERS ===============================

    const getHistorianStatusIteration = (iteration: IApprovalProcessModelIteration) => {
        if (iteration.historianApprovingId)
            return <Button variant="success">H</Button>
        if (iteration.historianDecliningId)
            return <Button variant="danger">H</Button>
        return <Button variant="secondary">H</Button>
    }

    const getGraphicianStatusIteration = (iteration: IApprovalProcessModelIteration) => {
        if (iteration.graphicianApprovingId)
            return <Button variant="success">G</Button>
        if (iteration.graphicianDecliningId)
            return <Button variant="danger">G</Button>
        return <Button variant="secondary">G</Button>
    }

    const getFinalizedResult = (iteration: IApprovalProcessModelIteration) => {
        if (iteration.finalizedDate) {
            return Iso8601ToLocaleDate(iteration.finalizedDate)
        } else
            return "Ne"
    }

    // =============================== RETURN ===============================

    return (
        <>
            {
                iterationsToDisplay.length === 0
                    ? <Alert variant='info'>V procesu <strong>{approval.name}</strong> nejsou žádné iterace k zobrazení.</Alert>
                    : <div className='approval-process-dropdown-container'>
                        <Accordion key={approval.id}>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <Col>
                                        <span className='approver-approval-accordion-item-column-name'>
                                            {`Název: `}
                                        </span>
                                        <span>
                                            {approval.name}
                                        </span>
                                    </Col>

                                    <Col>
                                        <span className='approver-approval-accordion-item-column-name'>
                                            {`Struktura: `}
                                        </span>
                                        <span>
                                            {structure && structure.name}
                                        </span>
                                    </Col>
                                    <Badge bg='info' style={{margin: "0 10px 0 0"}}>{iterationsToDisplay.length}</Badge>
                                </Accordion.Header>

                                <Accordion.Body>
                                    <Row>
                                        <Col>
                                            <h5>Iterace modelu</h5>
                                        </Col>
                                        <Col>
                                            <div className='approval-process-dropdown-legend-names-container'>
                                                <div>
                                                    G - grafik
                                                </div>
                                                <div>
                                                    H - historik
                                                </div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className='approval-process-dropdown-legend-buttons-container'>
                                                <div>
                                                    <Button variant='success'> </Button> - Schváleno
                                                </div>
                                                <div>
                                                    <Button variant='danger'> </Button> - Zamítnuto
                                                </div>
                                                <div>
                                                    <Button variant='secondary'> </Button> - Neohodnoceno
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        iterationsToDisplay.length === 0
                                            ?
                                            <Row>
                                                <Alert variant='info'>Nejsou k dispozici žádné iterace modelu.</Alert>
                                            </Row>
                                            :
                                            <Table striped bordered hover>
                                                <thead>
                                                <tr>
                                                    <th>Název</th>
                                                    <th>Vytvořeno</th>
                                                    <th>Stav procesu</th>
                                                    <th>Zveřejněno</th>
                                                    <th>Akce</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    iterationsToDisplay.map(iteration =>
                                                        <tr key={iteration.id}>
                                                            <td>{iteration.name}</td>
                                                            <td>{iteration && Iso8601ToLocaleDate(iteration.createdDate)}</td>
                                                            <td>
                                                                <div className='approval-state-indicators-container'>
                                                                    {
                                                                        getGraphicianStatusIteration(iteration)
                                                                    }
                                                                    {
                                                                        getHistorianStatusIteration(iteration)
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {
                                                                    getFinalizedResult(iteration)
                                                                }
                                                            </td>
                                                            <td>
                                                                <Button onClick={() => handleOpenIteration(iteration)} className='flex-button'>
                                                                    <MagnifyingGlassIcon />
                                                                    Zobrazit
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                </tbody>
                                            </Table>
                                    }
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
            }
        </>
    )
}

export default ApprovalProcessDropdown
