import { useState } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import useUserContext from "../../contexts/user.context";
import { fetchTextureById } from "../../utils/api-fetchers/textures/textures-api-fetcher";
import { fetchAssetById } from "../../utils/api-fetchers/assets/assets-api-fetcher";
import DownloadIcon from "../icons/download-icon.component";
import { fetchModelById } from "../../utils/api-fetchers/models/models-api-fetcher";



interface IProps {
    id: string,
    filename: string,
    type: string,
}

const DownloadButton = (props: IProps) => {

    const { id, type, filename } = props

    const { currentUser } = useUserContext()

    const [showErrorMessage, setShowErrorMessage] = useState(false)

    // =============================== ACTION HANDLERS ===============================

    const downloadFile = async () => {
        switch (type) {
            case "texture": {
                await handleDownloadTexture()
                break
            }
            case "model": {
                await handleDownloadModel()
                break
            }
            case "asset": {
                await handleDownloadAsset()
                break
            }
            default:
                break
        }

    };

    const handleDownloadTexture = async () => {
        if (currentUser) {
            try {
                const response = await fetchTextureById(currentUser.token, id);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (e) {
                console.log('catching error', e)
                setShowErrorMessage(true)
            }
        }
    }
    const handleDownloadAsset = async () => {
        if (currentUser) {
            try {
                const response = await fetchAssetById(currentUser.token, id)
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (e) {
                console.log('catching error', e)
                setShowErrorMessage(true)
            }
        }
    }
    const handleDownloadModel = async () => {
        if (currentUser) {
            try {
                const response = await fetchModelById(currentUser.token, id);
                const blob = await response.blob();
                const url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (e) {
                console.log('catching error', e)
                setShowErrorMessage(true)
            }
        }
    }

    // =============================== RETURN ===============================
    return (
        <Row>
            <Col style={{display: "flex", justifyContent: "space-between"}}>
                <Button onClick={downloadFile} className='flex-button'>
                    <DownloadIcon />
                    Stáhnout
                </Button>
                {
                    showErrorMessage &&
                        <div style={{color: "red"}}>Došlo k chybě.</div>
                }
            </Col>

        </Row>
    );
}

export default DownloadButton
