import { useEffect, useState } from "react";

import Accordion from "react-bootstrap/Accordion";
import Alert from "react-bootstrap/Alert";

import ApprovalProcessApprovedDropdown
    from "../../../components/approval-process-approved-dropdown/approval-process-approved-dropdown.component";
import useUserContext from "../../../contexts/user.context";
import { fetchUserApprovals } from "../../../utils/api-fetchers/aproval-process/aproval-process-api-fetcher";
import { IApprovalProcessDetail } from "../../../interfaces/IApprovalProcessDetail";

import './admin-published.styles.scss'

const AdminPublished = () => {

    const { currentUser } = useUserContext()

    const [approvals, setApprovals] = useState<IApprovalProcessDetail[]>([])
    const [showAlert, setShowAlert] = useState(false)

    useEffect(() => {
        const getApprovals = async () => {
            if (currentUser) {
                setApprovals(await fetchUserApprovals(currentUser.token))
                // console.log(publishableObjects)
            }
        }
        getApprovals()
    }, [currentUser])

    useEffect(() => {
        for (let aIndex = 0; aIndex < approvals.length; aIndex++) {
            const approval = approvals[aIndex]
            for (let iIndex = 0; iIndex < approval.modelIterations.length; iIndex++) {
                const iteration = approval.modelIterations[iIndex]
                if (iteration.historianApprovingId && iteration.graphicianApprovingId && iteration.finalizedDate) {
                    setShowAlert(false)
                    return
                }
            }
        }
        setShowAlert(true)
    }, [approvals])

    return (
        <div className='admin-publishable-container'>
            <div className='admin-publishable-header-container'>
                <h2>
                    Zveřejněné modely
                </h2>
                <h5>Tyto iterace a jejich modely byly schváleny a  zveřejněny. </h5>
            </div>
            {
                showAlert
                ? <Alert variant='info'>Neexistují žádné schválené iterace s modely.</Alert>
                : <Accordion>
                        {
                            approvals.length === 0 ?
                                <Alert variant='info'>Neexistují žádné schvalovací procesy.</Alert>
                                :
                                approvals.map(approval => {
                                    return <ApprovalProcessApprovedDropdown key={approval.id} approval={approval} publicOnly={true} />
                                })
                        }
                    </Accordion>
            }
        </div>
    )
}

export default AdminPublished
