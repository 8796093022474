import { useEffect, useState } from "react";

import useUserContext from "../../../../contexts/user.context";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import { IStructure } from "../../../../interfaces/IStructure";
import { fetchStructureById } from "../../../../utils/api-fetchers/structure/structure-api-fetcher";

import './structure-detail.styles.scss'
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import L, { LatLng } from "leaflet";
import { IAPILocation } from "../../../../interfaces/ILocation";
import PhotoUpload from "../../../../components/photo-upload/photo-upload.component";
import PhotoGallery from "../../../../components/photo-gallery/photo-gallery.component";
import { deletePhotoFromStructure, uploadPhotoToStructure } from "../../../../utils/api-fetchers/photos/photos-api-fetcher";

const StructureDetail = () => {

    const { id } = useParams()
    const { currentUser } = useUserContext()
    const [structure, setStructure] = useState<IStructure>()

    const icon = L.icon({
        iconUrl: "/marker-icon.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41]
    });

    const reload = async () => {
        if (currentUser && id)
            setStructure(await fetchStructureById(currentUser.token, id))
    }

    useEffect(() => {
        reload()
    }, [currentUser, id])

    const uploadPhoto = async (photo: any) => {
        if (!currentUser || !structure) return
        await uploadPhotoToStructure(currentUser.token, structure.id, photo)
        reload()
    }

    const deletePhoto = async (photoId: string) =>{
        if (!currentUser || !structure) return
        await deletePhotoFromStructure(currentUser.token, structure.id, photoId)
        reload()
    }

    return (
        <div className="structures-container">
            <div>
                <h2>
                    Detail struktury
                </h2>
            </div>
            {
            structure &&
            <>
                <div>
                    <Row>
                        <Col>
                            Název
                        </Col>
                        <Col>
                            {structure.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Město
                        </Col>
                        <Col>
                            {structure.city}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Popis
                        </Col>
                        <Col>
                            {structure.description}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            Poloha
                        </Col>
                        <Col>
                        <MapContainer center={new LatLng((structure.location as IAPILocation).lat, (structure.location as IAPILocation).lon)} zoom={14} scrollWheelZoom={true}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={new LatLng((structure.location as IAPILocation).lat, (structure.location as IAPILocation).lon)} icon={icon} />
                        </MapContainer>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Link to={`/manager/tdobjects/${structure?.id}`}>
                        <Button>
                            3D Objekty
                        </Button>
                    </Link>
                </div>
            </>
            }
            <div>
                <h4>
                    Fotografie
                </h4>
                <PhotoUpload uploadPhoto={uploadPhoto}  />
                { structure && <PhotoGallery photos={structure.photos} deletePhoto={deletePhoto} /> }
            </div>
        </div>
        
    )
}
export default StructureDetail
