import React, { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import { CredentialResponse, googleLogout } from "@react-oauth/google";
import { useCookies } from "react-cookie";

import Button from "react-bootstrap/Button";
import Popover from "react-bootstrap/Popover";

import { logIn } from "../../utils/api-fetchers/auth/auth-google-sign-in-api-fetcher";
import { czechRole } from "../../enums/roles.enum";
import LogOutComponent from "./logout/logout.component";
import LogInComponent from "./login/login.component";
import useUserContext from "../../contexts/user.context";

import IUser from "../../interfaces/IUser";

import ENV from "../../env";
import './google-auth.style.scss'

const webClientId = ENV.clientId ?? ''


const GoogleAuthComponent = () => {

    const { currentUser, setCurrentUser } = useUserContext()

    const [userCookie, setUserCookie] = useCookies(['VMCK-user']);

    const navigate = useNavigate();

    useEffect(() => {
        if (userCookie['VMCK-user'] !== 'null' && userCookie['VMCK-user'] !== 'undefined') {
            setCurrentUser(userCookie['VMCK-user'])
        }
        else {
            setCurrentUser(null)
        }
        // setCurrentUser(userCookie['VMCK-user'])
    }, [setCurrentUser, userCookie])

    const rolePopover = (
        <Popover id="popover-basic">
            <Popover.Body>
                Role
            </Popover.Body>
        </Popover>
    );

    const usernamePopover = (
        <Popover id="popover-basic">
            <Popover.Body>
                Username
            </Popover.Body>
        </Popover>
    );

    const loginAsync = async (googleToken: string, clientId: string) => {
        return await logIn(googleToken, clientId)
    }

    const onLoginSuccess = async (credentialResponse: CredentialResponse) => {

        const id_token = credentialResponse.credential;

        if (id_token) {
            const authResponse = await loginAsync(id_token, webClientId)

            const user: IUser = {
                id: authResponse.user.id,
                username: authResponse.user.username,
                name: authResponse.user.name,
                status: authResponse.user.status,
                email: authResponse.user.email,
                role: authResponse.user.role,
                token: authResponse.token
            }

            setCurrentUser(user)
            console.log("login successful");
            setUserCookie('VMCK-user', user, { path: '/' })
        } else {
            // setUserCookie('VMCK-user', null)
            setCurrentUser(null)
            setUserCookie('VMCK-user', null, { path: '/' })
            // setUserCookie('VMCK-user', null, { path: '/', domain: '.localhost' })
        }
    }

    const onLogout = () => {
        console.log('logout')
        googleLogout();
        setCurrentUser(null)
        setUserCookie('VMCK-user', null, { path: '/' })
        navigate('/')
    }

    const onLoginFailure = () => {
        console.log('login failed')
        setCurrentUser(null)
    };


    return (
        <div className='auth-component'>
            {
                currentUser
                    ? ( <Fragment>
                            <OverlayTrigger trigger={["hover", "focus"]} placement="bottom"
                                            overlay={usernamePopover}>
                                <Button variant='light' className='nav-button'>{currentUser.username}</Button>
                            </OverlayTrigger>
                            <OverlayTrigger trigger={["hover", "focus"]} placement="bottom"
                                            overlay={rolePopover}>
                                <Button variant='light' className='nav-button'>{czechRole(currentUser.role)}</Button>
                            </OverlayTrigger>
                            <LogOutComponent handleLogout={onLogout}/>
                        </Fragment>
                    )
                    : <LogInComponent onSuccess={onLoginSuccess} onFailure={onLoginFailure} />
            }
        </div>

    )
}

export default GoogleAuthComponent;
