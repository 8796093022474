import { useParams } from "react-router-dom";

import useUserContext from "../../../../contexts/user.context";
import StreetForm from "../../../../components/street-form/street-form.component";
import { useEffect, useState } from "react";
import { fetchStreetById } from "../../../../utils/api-fetchers/street/street-api-fetcher";
import { IStreet } from "../../../../interfaces/IStreet";

const StreetCreate = () => {

    const { currentUser } = useUserContext()
    const { id } = useParams()
    const [streetData, setStreetData] = useState<IStreet | undefined>(undefined)
    const [loading, setLoading] = useState(id !== undefined)

    useEffect(() => {
        const fetchStreet = async () => {
            if (id && currentUser) {
                const street = await fetchStreetById(currentUser.token, id)
                setStreetData(street)
                setLoading(false)
            }
        }
        fetchStreet()
    }, [currentUser, id])

    // ================================ RETURN ================================

    return (
        <div className='modeller-creation-container'>
            <div className='modeller-creation-header-container'>
                <h2>
                    {id ? <>Upravit</> : <>Vytvořit</>} ulici
                </h2>
            </div>
            <StreetForm
                streetData={streetData}
                loading={loading}
            />
        </div>
    )
}

export default StreetCreate;
