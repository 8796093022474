import ENV from "../../../env";
import { IStreet } from "../../../interfaces/IStreet"

const apiUrl = ENV.privateApiUrl

export const fetchStreets = async (token: string): Promise<IStreet[]> => {
    return fetch(apiUrl + "/areas/", {
        headers:{
            'Accept': 'application/json',
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "GET"
    }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
}

export const fetchStreetById = async (token: string, id: string): Promise<IStreet> => {
    return fetch(apiUrl + "/areas/" + id, {
        headers:{
            'Accept': 'application/json',
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "GET"
    }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
}

export const createStreet = async (token: string, data: IStreet) => {
    return fetch(apiUrl + "/areas", {
        headers:{
            'Accept': 'application/json',
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        body: JSON.stringify(data),
        method: "POST"
    }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}

export const updateStreet = async (token: string, id: string, data: IStreet) => {
    return fetch(apiUrl + `/areas/${id}`, {
        headers:{
            'Accept': 'application/json',
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        body: JSON.stringify(data),
        method: "PUT"
    }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}

export const deleteStreet = async (token: string, streetId: string) => {
    return fetch(apiUrl + "/areas/" + streetId, {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "DELETE"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}
