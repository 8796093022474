import {MimeType} from "../../enums/file-type.enum";


// =========================== MIME TYPE ====================== //

export const getMimeTypeFromFormat = (format: string) => {
    switch (format) {
        case 'GLB': return MimeType.GLB
        case 'OBJ': return MimeType.OBJ
        case 'GLTF': return MimeType.GLTF
        case 'FBX': return MimeType.FBX
        case 'PNG': return MimeType.PNG_IMAGE
        case 'JPG': return MimeType.JPG_IMAGE
        case 'JPEG': return MimeType.JPG_IMAGE
        case 'MTL': return MimeType.MTL
        case 'JSON': return MimeType.JSON
        default: return null
    }
}
