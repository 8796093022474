import Modal from "react-bootstrap/Modal";

interface IProps {
    isOpen: boolean,
    handleClose: () => void,
}

const DeleteImpossibleModal = (props: IProps) => {
    const { isOpen, handleClose } = props
    return(
        <div>
            <Modal show={isOpen} onHide={handleClose}>
                <Modal.Header closeButton>Pozor!</Modal.Header>
                <Modal.Body>
                    Tento objekt obsahuje další vnořené objekty (struktury nebo 3D objekty). Aby nedošlo k nechtěné ztrátě dat musíte nejdříve ručně odstranit vnořené objekty.
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default DeleteImpossibleModal
