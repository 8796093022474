import { useParams } from "react-router-dom";

import useUserContext from "../../../../contexts/user.context";
import { useEffect, useState } from "react";
import StructureForm from "../../../../components/structure-form/structure-form.component";
import { IStructure } from "../../../../interfaces/IStructure";
import { fetchStructureById } from "../../../../utils/api-fetchers/structure/structure-api-fetcher";

const StructureCreate = () => {

    const { currentUser } = useUserContext()
    const { streetId, structureId } = useParams()
    const [structureData, setStructureData] = useState<IStructure | undefined>(undefined)
    const [loading, setLoading] = useState(structureId !== undefined)
    
    useEffect(() => {
        const fetchStreet = async () => {
            if (structureId && currentUser) {
                const structure = await fetchStructureById(currentUser.token, structureId)
                setStructureData(structure)
                setLoading(false)
            }
        }
        fetchStreet()
    }, [currentUser, structureId])

    // ================================ RETURN ================================

    return (
        <div className='modeller-creation-container'>
            <div className='modeller-creation-header-container'>
                <h2>
                    {streetId ? <>Vytvořit</> : <>Upravit</>} strukturu
                </h2>
            </div>
            <StructureForm
                structureData={structureData}
                streetId={streetId}
                loading={loading}
            />
        </div>
    )
}

export default StructureCreate;
