import { useEffect, useState } from "react";

import useUserContext from "../../../../contexts/user.context";
import { IStreet } from "../../../../interfaces/IStreet";
import { fetchStreetById } from "../../../../utils/api-fetchers/street/street-api-fetcher";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import './streets-detail.styles.scss'
import StructureTable from "../../../../components/structure-table/structure-table.component";
import NewIcon from "../../../../components/icons/new-icon.component";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import L, { LatLng } from "leaflet";
import { IAPILocation } from "../../../../interfaces/ILocation";
import PhotoUpload from "../../../../components/photo-upload/photo-upload.component";
import { deletePhotoFromStreet, uploadPhotoToStreet } from "../../../../utils/api-fetchers/photos/photos-api-fetcher";
import PhotoGallery from "../../../../components/photo-gallery/photo-gallery.component";

const StreetDetail = () => {

    const { id } = useParams()
    const { currentUser } = useUserContext()
    const [street, setStreet] = useState<IStreet>()

    const icon = L.icon({
        iconUrl: "/marker-icon.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41]
    });

    const reload = async () => {
        console.log("reload")
        if (currentUser && id)
            setStreet(await fetchStreetById(currentUser.token, id))
    }

    const uploadPhoto = async (photo: any) => {
        if (!currentUser || !street) return
        await uploadPhotoToStreet(currentUser.token, street.id, photo)
        reload()
    }

    const deletePhoto = async (photoId: string) =>{
        if (!currentUser || !street) return
        await deletePhotoFromStreet(currentUser.token, street.id, photoId)
        reload()
    }

    useEffect(() => {
        reload()
    }, [currentUser, id])

    return (
        <div className="streets-container">
            <div>
                <h2>
                    Detail ulice
                </h2>
            </div>
            {
                street &&
                <>
                    <div>
                        <Row>
                            <Col>
                                Název
                            </Col>
                            <Col>
                                {street.name}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Město
                            </Col>
                            <Col>
                                {street.city}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Popis
                            </Col>
                            <Col>
                                {street.description}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Poloha
                            </Col>
                            <Col>
                            <MapContainer center={new LatLng((street.location as IAPILocation).lat, (street.location as IAPILocation).lon)} zoom={14} scrollWheelZoom={true}>
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                                <Marker position={new LatLng((street.location as IAPILocation).lat, (street.location as IAPILocation).lon)} icon={icon} />
                            </MapContainer>
                            </Col>
                        </Row>
                    </div>
                    <h4>
                        Struktury:
                    </h4>
                    <div>
                        <Link to={`/manager/structures/create/${street.id}`}><Button variant='primary' className='flex-button'><NewIcon />Vytvořit strukturu</Button></Link>
                    </div>
                </>
            }
            { street && street.structures &&
                <StructureTable
                    structures={street.structures}
                    reload={reload}
                />
            }
            <div>
                <h4>
                    Fotografie
                </h4>
                <PhotoUpload uploadPhoto={uploadPhoto}  />
                { street && <PhotoGallery photos={street.photos} deletePhoto={deletePhoto} /> }
            </div>
        </div>
    )
}
export default StreetDetail
