import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import LoadingModal from "../../../components/loading-modal/loading-modal.component";
import useUserContext from "../../../contexts/user.context";
import ErrorAndSuccessModals from "../../../components/error-and-success-modals/error-and-success-modals.component";
import ModelDetailForm from "../../../components/TDObject-detail-form/TDObject-detail-form.component";
import FilesModal from "../../../components/files-modal/files-modal.component";
import SendForApprovalAutomaticModal
    from "../../../components/send-for-approval-modal-automatic/send-for-approval-automatic-modal.component";
import {
    delete3DObjectVersion, fetch3DObjectById,
    update3DObjectStatus
} from "../../../utils/api-fetchers/TDObject/TDObject-api-fetcher";
import { versionArrayToString } from "../../../utils/formatters/version-formatter.util";
import { FormActions } from "../../../enums/form-actions.enum";
import { I3DObject } from "../../../interfaces/I3DObject";

import './model-detail-form.styles.scss'

const ModelDetail = () => {

    const navigate = useNavigate()

    const { currentUser } = useUserContext()

    const location = useLocation()
    const { state } = location
    const { TDObject } = state

    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false)
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false)
    const [isSendForApprovalModalOpen, setIsSendForApprovalModalOpen] = useState(false)
    const [filesModalOpen, setFilesModalOpen] = useState(false)
    const [fetched3DObject, setFetched3DObject] = useState<I3DObject>(TDObject)

    const [loading, setLoading] = useState(false)

    // ================================ useEffects ================================

    useEffect(() => {
        const fetchObject = async () => {
            if (currentUser)
                setFetched3DObject(await fetch3DObjectById(currentUser.token, TDObject.id))
        }
        fetchObject()
    }, [currentUser, TDObject.id])

    // ================================ SUBMIT ================================

    const handleSubmit = async (event: any) => {
        setLoading(true)
        event.preventDefault()

        const action = event.nativeEvent.submitter.name

        switch (action) {
            case FormActions.DELETE_EXISTING: {
                await handleDeleteModel()
                break
            }
            case FormActions.FINISH_EXISTING: {
                await handleFinishModel()
                break
            }
            case FormActions.SEND_APPROVAL_EXISTING: {
                setIsSendForApprovalModalOpen(true)
                break
            }
            case FormActions.NEW_VERSION_EXISTING: {
                handleNewVersionCreation()
                break
            }
            case FormActions.OPEN_FILES: {
                setFilesModalOpen(true)
                break
            }
            default: {
                console.log('unknown action')
                break
            }
        }
        setLoading(false)
    }

    // ================================ ACTION HANDLERS ================================

    const handleNewVersionCreation = () => {
        navigate('/modeller/create-new-version/' + TDObject.id, {
            state:
                {
                    TDObject: fetched3DObject
                }
        })
    }

    const handleDeleteModel = async () => {
        try {
            if (currentUser && fetched3DObject)
                await delete3DObjectVersion(currentUser?.token, fetched3DObject.id, (typeof fetched3DObject.version ==='string') ? fetched3DObject.version : versionArrayToString(fetched3DObject.version))
            setIsSuccessAlertOpen(true)
        } catch (e) {
            setIsErrorAlertOpen(true)
        }
    }

    const handleFinishModel = async () => {
        try {
            if (currentUser) {
                await update3DObjectStatus(currentUser.token, fetched3DObject.id, (typeof fetched3DObject.version ==='string') ? fetched3DObject.version : versionArrayToString(fetched3DObject?.version), fetched3DObject.name, 'finished')
            }
            setIsSuccessAlertOpen(true)
        } catch (e) {
            setIsErrorAlertOpen(true)
        }
    }

    // ================================ MODAL HANDLERS ================================

    const handleCloseErrorAlert = () => {
        setIsErrorAlertOpen(false);
    };

    const handleCloseSuccessAlert = () => {
        setIsSuccessAlertOpen(false)
        navigate('/modeller/all-creations')
    };

    // ================================ RETURN ================================

    return(
        <div className='modeller-detail-container'>
            <div className='modeller-detail-header-container'>
                <h2>
                    Detail uloženého 3D modelu a akce
                </h2>
            </div>
            <ModelDetailForm
                handleSubmit={handleSubmit}
                default3DObject={fetched3DObject}
                loading={loading}
                />

            <ErrorAndSuccessModals
                isErrorAlertOpen={isErrorAlertOpen}
                handleCloseErrorAlert={handleCloseErrorAlert}
                isSuccessAlertOpen={isSuccessAlertOpen}
                handleCloseSuccessAlert={handleCloseSuccessAlert}
            />
            <SendForApprovalAutomaticModal
                isOpen={isSendForApprovalModalOpen}
                setIsOpen={setIsSendForApprovalModalOpen}
                TDObjectId={TDObject.id}
                structureId={TDObject.structureId}
            />
            <FilesModal TDObject={TDObject} open={filesModalOpen} setOpen={setFilesModalOpen} />
            <LoadingModal loading={loading} />
        </div>
    )
}

export default ModelDetail
