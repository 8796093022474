import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';

import './loading-modal.styles.scss'

interface IProps {
    loading: boolean
}

const LoadingModal = (props: IProps) => {

    const { loading } = props

    return(
        <Modal show={loading}>
            <Modal.Body>
                <div className='loading-modal-body'>
                    <h4>Počkejte prosím...</h4>
                    <Spinner animation="border" variant="primary" />
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LoadingModal
