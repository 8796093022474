import { Route, Routes } from 'react-router-dom'

import AdminPublishable from "./publishable/admin-publishable.component";
import AdminPublishIteration from "./publish-iteration/admin-publish-iteration.component";
import AdminPublished from "./published/admin-published.component";

const AdminComponent = () => {
    return (
        <Routes>
            <Route path='publishable' element={<AdminPublishable />}/>
            <Route path='publish-iteration/*' element={<AdminPublishIteration />}/>
            <Route path='published/*' element={<AdminPublished />}/>
        </Routes>
    )
}

export default AdminComponent
