import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import SaveIcon from "../icons/save-icon.component";
import useUserContext from "../../contexts/user.context";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { IStructure } from "../../interfaces/IStructure";
import { createStructure, updateStructure } from "../../utils/api-fetchers/structure/structure-api-fetcher";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import RecenterMap from "../map-recenter/map-recenter.component";
import L, { LatLng } from "leaflet";
import { fetchLocationData } from "../../utils/api-fetchers/map/map-data-fetcher";
import { IAPILocation } from "../../interfaces/ILocation";

interface IProps {
    structureData?: IStructure,
    streetId?: string
    loading: boolean
}

const StructureForm = (props: IProps) => {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm<IStructure>();

    const { currentUser } = useUserContext()
    const navigate = useNavigate()
    const [buttonDisabled, setButtonDisabled] = useState(false)
    
    const {
        structureData,
        streetId,
        loading
    } = props

    const [mapPosition, setMapPosition] = useState<LatLng>(new LatLng(50.1051, 14.3897))

    const icon = L.icon({
        iconUrl: "/marker-icon.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41]
    });

    const markerRef = useRef<L.Marker>(null)
    const eventHandlers = useMemo
    (
      () => ({
        dragend() {
          const marker = markerRef.current
          if (marker != null) {
            setMapPosition(marker.getLatLng())
          }
        },
      }),
      [],
    )

    useEffect(() => {
        if (structureData) {
            setValue("name", structureData.name)
            setValue("city", structureData.city)
            setValue("public", structureData.public)
            setValue("description", structureData.description)
            if (structureData.location)
                setMapPosition(new LatLng((structureData.location as IAPILocation).lat, (structureData.location as IAPILocation).lon))
        }
    }, [structureData])

    const onSubmit = async (data: IStructure) => {
        setButtonDisabled(true)
        const loc = await fetchLocationData(mapPosition.lat, mapPosition.lng)
        let alt = 0
        if (loc.results.length > 0)
            alt = loc.results[0].elevation
        data.location = JSON.stringify({lon: mapPosition.lng, lat: mapPosition.lat, alt: alt})
        if (currentUser && streetId) {
            data.areaId = streetId
            await createStructure(currentUser.token, data)
        } else if (currentUser && structureData) {
            await updateStructure(currentUser.token, structureData.id, data)
        }
        setButtonDisabled(false)
        if (streetId)
            navigate(`/manager/streets/detail/${streetId}`)
        else
            navigate('/manager/streets')
    };

    // =========================== RETURN ===========================

    return(
        <div className='TDObject-create-form'>
            {!loading &&
                <Form className='modeller-model-form' onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col className='modeller-model-form-column'>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Název
                                    <span className='modeller-form-required-star'>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Název..."
                                    id='name'
                                    required
                                    {...register("name")}
                                />
                            </Form.Group>
                        </Col>

                        <Col className='modeller-model-form-column'>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Město
                                    <span className='modeller-form-required-star'>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Město..."
                                    id='city'
                                    required
                                    {...register("city")}
                                />
                            </Form.Group>
                        </Col>
                        <Col className='modeller-model-form-column'>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Veřejná
                                </Form.Label>
                                <Form.Check
                                    id="public"
                                    {...register("public")}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='modeller-model-form-column'>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Poloha
                                </Form.Label>
                                <MapContainer center={mapPosition} zoom={14} scrollWheelZoom={true}>
                                    <RecenterMap position={mapPosition} />
                                    <TileLayer
                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                    <Marker position={mapPosition}
                                    eventHandlers={eventHandlers}
                                    ref={markerRef}
                                    icon={icon}
                                    draggable />
                                </MapContainer>
                            </Form.Group>
                        </Col>
                        <Col className='modeller-model-form-column'>
                        <Form.Label>
                                    Popis
                                    <span className='modeller-form-required-star'>*</span>
                        </Form.Label>
                            <Form.Control
                                        as="textarea"
                                        required
                                        {...register("description")}
                                    />
                        </Col>
                    </Row>


                    <Row>
                        <Col className='modeller-model-submit-button-container modeller-model-form-column'>
                            <Button
                                variant="primary"
                                type="submit"
                                className='modeller-model-submit-button flex-button'
                                name='saveNew'
                                disabled={buttonDisabled}
                            >
                                <SaveIcon />
                                Uložit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }
        </div>
    )
}

export default StructureForm
