import { useEffect, useState } from "react";

import Alert from "react-bootstrap/Alert";

import useUserContext from "../../../contexts/user.context";
import { IStreet } from "../../../interfaces/IStreet";
import { fetchStreets } from "../../../utils/api-fetchers/street/street-api-fetcher";
import StreetTable from "../../../components/street-table/street-table.component";
import NewIcon from "../../../components/icons/new-icon.component";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import './streets.styles.scss'

const Streets = () => {

    const { currentUser } = useUserContext()

    const [streets, setStreets] = useState<IStreet[]>([])

    useEffect(() => {
        const getStreets = async () => {
            if (currentUser) {
                const streets = await fetchStreets(currentUser.token)
                setStreets(streets)
            }
        }
        getStreets()
    }, [currentUser])

    const reload = async () => {
        if (currentUser)
            setStreets(await fetchStreets(currentUser.token))
    }

    return (
        <div className="streets-container">
            <div>
                <h2>
                    Ulice
                </h2>
            </div>
            <div>
                <Link to='/manager/streets/create'><Button variant='primary' className='flex-button'><NewIcon />Vytvořit ulici</Button></Link>
            </div>
            {
                streets && streets.length === 0
                ?   <Alert variant='info'>Neexistují žádné ulice.</Alert>
                :
                <div>
                    <StreetTable streets={streets} reload={reload} />
                </div>
            }
        </div>
    )
}
export default Streets
