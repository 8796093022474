import { getMimeTypeFromFormat } from "../../parsers/file-format-parser";
import { I3DObject } from "../../../interfaces/I3DObject";

import ENV from "../../../env";
import ITransformation from "../../../interfaces/ITransformation";

const apiUrl = ENV.privateApiUrl

const fetchAll3DObjects = (token: string): Promise<I3DObject[]> => {
    return (fetch(apiUrl + "/3DObjects", {
        headers:{
            'Accept': 'application/json',
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "GET"
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}

export const fetch3DObjectsByCreatorId = async (token: string, userId: string): Promise<I3DObject[]> => {
    return (fetch(apiUrl + "/3DObjects?userId=" + userId, {
        headers:{
            'Accept': 'application/json',
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "GET"
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}

export const fetchApproved3DObjects = async (token: string) => {
    const allObjects = await fetchAll3DObjects(token)
    return allObjects.filter(TDObject => TDObject.status === 'approved')
}

export const fetch3DObjectById = async (token: string, objectId: string): Promise<I3DObject> => {
    return (fetch(apiUrl + "/3DObjects/" + objectId, {
        headers:{
            'Accept': 'application/json',
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "GET"
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}

const addTextureTo3DObject = async (token: string, objectId: string, texture: any, textureType: string) => {

    let formData = new FormData()
    if (! texture || ! texture.name)
        throw new Error("Missing texture file!")

    const fileName: string = texture.name
    const format = texture.name.split('.').pop().toUpperCase()
    const mimeType = getMimeTypeFromFormat(format)

    if ( ! mimeType) {
        throw new Error("Invalid MimeType in texture!")
    }

    const file = new Blob([texture], { type: mimeType });

    formData.append('texture', file, fileName);
    formData.append('type', mimeType)
    formData.append('format', format)
    formData.append('textureType', textureType)

    return (fetch(apiUrl + "/3DObjects/" + objectId + "/textures", {
        headers:{
            'Accept': 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "POST",
        body: formData
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}

const addAssetTo3DObject = async (token: string, objectId: string, asset: any) => {

    let formData = new FormData()

    const fileName: string = asset.name
    const format = asset.name.split('.').pop().toUpperCase()
    const mimeType = getMimeTypeFromFormat(format)

    if ( ! mimeType) {
        throw new Error("Invalid MimeType in asset!")
    }

    const file = new Blob([asset], { type: mimeType });

    formData.append('asset', file, fileName);
    formData.append('format', format)

    return (fetch(apiUrl + "/3DObjects/" + objectId + "/assets", {
        headers:{
            'Accept': 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "POST",
        body: formData
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}

export const addTexturesTo3DObject = async (token: string, TDObjectId: string, textures: File[], texturesTypes: string[]) => {
    for (let i = 0; i < textures.length; i++) {
        await addTextureTo3DObject(token, TDObjectId, textures[i], texturesTypes[i])
    }
}

export const addAssetsTo3DObject = async (token: string, TDObjectId: string, assets: any[]) => {
    for (let i = 0; i < assets.length; i++) {
        await addAssetTo3DObject(token, TDObjectId, assets[i])
    }
}

export const delete3DObjectVersion = async (token: string, TDObjectId: string, versionFormatted: string) => {
    return (fetch(apiUrl + "/3DObjects/" + TDObjectId + "/" + versionFormatted, {
        headers:{
            'Accept': 'application/json',
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "DELETE"
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return {
            code: 204,
            text: 'Version of 3D object was deleted.'
        }
    }));
}

export const update3DObjectStatus = async (token: string, TDObjectId: string, version: string, name: string, status: string) => {

    const currentTDObject = await fetch3DObjectById(token, TDObjectId)

    const formData = new FormData()
    formData.append('status', status)
    formData.append('name', name)

    // ====================== appending field, which will disappear if not sent ===================

    formData.append('transformation', JSON.stringify(currentTDObject.transformation))
    formData.append('transformationWeb', JSON.stringify(currentTDObject.transformationWeb))

    // ==============================================================================================

    return (fetch(apiUrl + "/3DObjects/" + TDObjectId + "/" + version, {
        headers:{
            'Accept': 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "PUT",
        body: formData
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}

export const createNewVersionFor3DObject = async (
    token: string,
    TDObjectId: string,
    TDObjectName: string,
    modelFile: any,
    transformation: ITransformation,
    transformationWeb: ITransformation,
    tagsObject: any
) => {

    const fileName: string = modelFile.name
    const format = modelFile.name.split('.').pop().toUpperCase()
    const mimeType = getMimeTypeFromFormat(format)

    if ( ! mimeType) {
        throw new Error("Invalid MimeType!")
    }

    const file = new Blob([modelFile], { type: mimeType });
    const formData = new FormData();
    formData.append('model', file, fileName);
    formData.append('name', TDObjectName)
    formData.append('format', format)
    formData.append('transformation', JSON.stringify(transformation))
    formData.append('transformationWeb', JSON.stringify(transformationWeb))

    if (Object.keys(tagsObject).length > 0)
        formData.append('properties', tagsObject)


    return (fetch(apiUrl + "/3DObjects/" + TDObjectId, {
        headers:{
            'Accept': 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "POST",
        body: formData
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}

export const update3DObjectVersion = async (
    token: string,
    TDObjectId: string,
    TDObjectName: string,
    TDObjectVersion: string,
    modelFile: any,
    transformation: ITransformation,
    transformationWeb: ITransformation
) => {

    const fileName: string = modelFile.name
    const format = modelFile.name.split('.').pop().toUpperCase()
    const mimeType = getMimeTypeFromFormat(format)

    if ( ! mimeType) {
        throw new Error("Invalid MimeType!")
    }

    const file = new Blob([modelFile], { type: mimeType });

    const formData = new FormData();

    formData.append('name', TDObjectName)
    formData.append('model', file, fileName);
    formData.append('format', format)
    formData.append('status', 'unfinished')
    formData.append('transformation', JSON.stringify(transformation))
    formData.append('transformationWeb', JSON.stringify(transformationWeb))

    return (fetch(apiUrl + "/3DObjects/" + TDObjectId + '/' + TDObjectVersion, {
        headers:{
            'Accept': 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "PUT",
        body: formData
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}
