import { fetchUserApprovals } from "../api-fetchers/aproval-process/aproval-process-api-fetcher";
import { fetch3DObjectById } from "../api-fetchers/TDObject/TDObject-api-fetcher";
import IUser from "../../interfaces/IUser";

export const recomputeTasksNumber = async (currentUser: IUser) => {

        const approvals = await fetchUserApprovals(currentUser?.token)
        let localNumberOfTasks = 0

        if (currentUser.role === 'historian') {
            for (let aIndex = 0; aIndex < approvals.length; aIndex++) {
                const approval = approvals[aIndex]
                for (let iIndex = 0; iIndex < approval.modelIterations.length; iIndex ++) {
                    const iteration = approval.modelIterations[iIndex]
                    const TDObject = await fetch3DObjectById(currentUser.token, iteration.tdObjectId)
                    if ( TDObject.status === 'submitted' && ! iteration.historianApprovingId && ! iteration.historianDecliningId)
                        localNumberOfTasks++
                }
            }
        } else if (currentUser.role === 'approver') {
            for (let aIndex = 0; aIndex < approvals.length; aIndex++) {
                const approval = approvals[aIndex]
                for (let iIndex = 0; iIndex < approval.modelIterations.length; iIndex ++) {
                    const iteration = approval.modelIterations[iIndex]
                    const TDObject = await fetch3DObjectById(currentUser.token, iteration.tdObjectId)
                    if ( TDObject.status === 'submitted' && ! iteration.graphicianApprovingId && ! iteration.graphicianDecliningId)
                        localNumberOfTasks++
                }
            }
        } else if (currentUser.role === 'admin') {
            for (let aIndex = 0; aIndex < approvals.length; aIndex++) {
                const approval = approvals[aIndex]
                for (let iIndex = 0; iIndex < approval.modelIterations.length; iIndex ++) {
                    const iteration = approval.modelIterations[iIndex]
                    if (iteration.graphicianApprovingId && iteration.historianApprovingId && ! iteration.finalizedDate)
                        localNumberOfTasks++
                }
            }
        }

        return localNumberOfTasks
}
