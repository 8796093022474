export enum MimeType {
    OBJ = 'model/obj',
    FBX = 'application/octet-stream',
    GLB = 'model/gltf-binary',
    GLTF = 'model/gltf+json',
    JPG_IMAGE = 'image/jpeg',
    PNG_IMAGE = 'image/png',
    MTL = 'model/mtl',
    JSON = 'application/json'
}
