import React, { useEffect, useState } from "react";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

import SendIcon from "../icons/send-icon.component";
import useUserContext from "../../contexts/user.context";
import { IApprovalProcessDetail } from "../../interfaces/IApprovalProcessDetail";
import { IStructure } from "../../interfaces/IStructure";
import { fetchStructureById } from "../../utils/api-fetchers/structure/structure-api-fetcher";

import './approval-and-iteration-creation-automatic-modal.styles.scss'

interface IProps {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    handleSubmit: (event: any) => void,
    approval: IApprovalProcessDetail | undefined,
    structureId: string
}

const ApprovalAndIterationCreationAutomaticModal = (props: IProps) => {

    const { isOpen, setIsOpen, handleSubmit, approval, structureId } = props
    const { currentUser } = useUserContext()

    const [structure, setStructure] = useState<IStructure | undefined>(undefined)

    useEffect(() => {
        const getStructure = async () => {
            if (currentUser) {
                setStructure(await fetchStructureById(currentUser.token, structureId))
            }
        }
        getStructure()
    }, [currentUser, structureId])

    const handleClose = () => {
        setIsOpen(false)
    }

    return(
        <Modal show={isOpen} onHide={handleClose}>
            <Modal.Header closeButton><h4>Odeslat model ke schválení</h4></Modal.Header>
            <Modal.Body>
                {
                    approval
                    ? <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Label>
                                    <strong>Název struktury</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    value={structure ? structure.name : 'Název nedostupný.'}
                                    id='structureName'
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group className='margin-top'>
                                <Form.Label>
                                    <strong>Název schvalovacího procesu</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    readOnly
                                    value={approval ? approval.name : 'Název nedostupný.'}
                                    id='processName'
                                    disabled
                                />
                            </Form.Group>
                            <Form.Group className='margin-top'>
                                <Form.Label>
                                    <strong>Název iterace</strong> (název odevzdání)
                                    <span style={{color: "red"}}>*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Název iterace..."
                                    id='iterationName'
                                    required
                                />
                            </Form.Group>

                            <Button
                                className='flex-button margin-top'
                                variant='primary'
                                type='submit'
                            >
                                <SendIcon />
                                Odeslat
                            </Button>

                        </Form>
                        : <Alert variant='info'>Nejste členem schvalovacího procesu pro strukturu <strong>{structure ? structure.name : 'Jméno nenalezeno.'}</strong>. Nemůžete proto odesílat modely do schvalování.</Alert>
                }

            </Modal.Body>


        </Modal>
    )
}

export default ApprovalAndIterationCreationAutomaticModal
