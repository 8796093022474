import React from "react";
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';

interface IProps{
    onSuccess: (credentialResponse: CredentialResponse) => void
    onFailure: () => void
}

const LogInComponent = (props: IProps) => {

    const { onFailure, onSuccess } = props

    return (
        <div className='login-component'>
            <GoogleLogin
                onSuccess={onSuccess}
                onError={onFailure}
            />
        </div>
    )
}

export default LogInComponent;
