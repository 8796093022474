import { LatLng } from "leaflet";
import { useEffect } from "react";
import { useMap } from "react-leaflet";

const RecenterMap = ({ position } : { position: LatLng }) => {
    const map = useMap();
    useEffect(() => {
        map.setView(position);
    }, [position]);
    return null;
}

export default RecenterMap;
