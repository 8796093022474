import React from "react";

import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { I3DObject } from "../../interfaces/I3DObject";
import DownloadButton from "../download-button/download-button.component";

import './files-modal.styles.scss'

interface IProps {
    TDObject: I3DObject,
    open: boolean,
    setOpen: (value: boolean) => void
}

const FilesModal = (props: IProps) => {
    const { TDObject, open, setOpen } = props

    const handleClose = () => {
        setOpen(false)
    }

    return(
        <Modal show={open} onHide={handleClose}>
            <Modal.Header closeButton><h4>Soubory 3D objektu</h4></Modal.Header>
            <Modal.Body>
                <Row>
                    <Row>
                        <Col><h5>Soubor modelu</h5></Col>
                        <Col><DownloadButton id={TDObject.modelId} type={"model"} filename={'model.' + TDObject.format} /></Col>
                    </Row>
                </Row>
                <hr />
                <Row>
                    <h5>Textury</h5>
                    {
                        TDObject.textures.length === 0 &&
                            <div>K modelu nebyly nahrány žádné textury.</div>
                    }
                    {
                        TDObject.textures.map(
                            t =>
                                <Row key={t.id} className='files-modal-file-row'>
                                    <Col>{t.filename}</Col>
                                    <Col><DownloadButton id={t.id} type={"texture"} filename={t.filename}/></Col>
                                </Row>
                        )
                    }
                </Row>
                <hr />
                <Row>
                    <h5>Assety</h5>
                    {
                        TDObject.assets.length === 0 &&
                        <div>K modelu nebyly nahrány žádné assety.</div>
                    }
                    {
                        TDObject.assets.map(
                            a =>
                                <Row key={a.id} >
                                    <Col>{a.filename}</Col>
                                    <Col><DownloadButton id={a.id} type={"asset"} filename={a.filename}/></Col>
                                </Row>
                        )
                    }
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default FilesModal
