import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Information3DObject from "../../../components/TDObject-information/TDObject-information.component";
import ModelViewer from "../../../components/model-viewer/model-viewer.component";
import ErrorAndSuccessModals from "../../../components/error-and-success-modals/error-and-success-modals.component";
import useUserContext from "../../../contexts/user.context";
import ApprovalIterationAdminResult
    from "../../../components/approval-iteration-admin-result/approval-iteration-admin-result.component";
import IterationCommentsModal from "../../../components/iteration-comments-modal/iteration-comments-modal.component";
import { fetch3DObjectById } from "../../../utils/api-fetchers/TDObject/TDObject-api-fetcher";
import { fetchModelCommentsById } from "../../../utils/api-fetchers/comment/comment-api-fetcher";
import { fetchModelIterationById } from "../../../utils/api-fetchers/aproval-process/aproval-process-api-fetcher";
import { I3DObject } from "../../../interfaces/I3DObject";
import { IApprovalProcessModelIteration } from "../../../interfaces/IApprovalProcessModelIteration";
import { IComment } from "../../../interfaces/IComment";

const AdminPublishIteration = () => {

    const location = useLocation();
    const { state } = location
    const { modelIterationId } = state

    const { currentUser } = useUserContext()

    const [TDObject, setTDObject] = useState<I3DObject | undefined>(undefined)
    const [fetchedIteration, setFetchedIteration] = useState<IApprovalProcessModelIteration | undefined>(undefined)

    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false)
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false)

    const [iterationComments, setIterationComments] = useState<IComment[] | undefined>(undefined)
    const [isCommentsModalOpen, setCommentsModalOpen] = useState(false)

    // ================================ useEffects ================================

    useEffect(() => {
        const fetchIteration = async () => {
            if (currentUser)
                setFetchedIteration(await fetchModelIterationById(currentUser.token, modelIterationId))
        }
        fetchIteration()
    }, [currentUser, modelIterationId])

    useEffect(() => {
        const get3DObject = async () => {
            if (currentUser && fetchedIteration) {
                const tdObjectId = fetchedIteration.tdObjectId
                setTDObject(await fetch3DObjectById(currentUser?.token, tdObjectId))
            }
        }

        get3DObject()
    }, [currentUser, fetchedIteration])

    useEffect(() => {
        const fetchIterationComments = async () => {
            if (currentUser && fetchedIteration) {
                setIterationComments(await fetchModelCommentsById(currentUser.token, fetchedIteration.id))
            }
        }
        fetchIterationComments()
    }, [currentUser, fetchedIteration])

    // ================================ STATE UPDATERS ================================

    const update3DModel = async () => {
        if (currentUser && TDObject)
            setTDObject(await fetch3DObjectById(currentUser.token, TDObject.id))
    }

    const setNewIteration = async (id: number) => {
        if (currentUser)
            setFetchedIteration(await fetchModelIterationById(currentUser?.token, id))
    }

    // ================================ MODAL HANDLERS ================================

    const handleCloseErrorAlert = async () => {
        await update3DModel()
        setIsErrorAlertOpen(false);
    };

    const handleCloseSuccessAlert = async () => {
        await update3DModel()
        setIsSuccessAlertOpen(false)
    };

    // ================================ RETURN ================================
    return(
        <div className='approver-iteration-approval-container'>
            <div className='approver-iteration-approval-header-container'>
                <h2>
                    Schválení iterace modelu
                </h2>
            </div>

            <Information3DObject TDObject={TDObject} />

            <div className='approver-iteration-approval-model-and-button-container'>
                {
                    fetchedIteration &&
                    <ModelViewer modelId={fetchedIteration.tdObjectId} />
                }
                {
                    fetchedIteration &&
                    <ApprovalIterationAdminResult
                        modelIteration={fetchedIteration}
                        setNewIteration={setNewIteration}
                        setIsErrorAlertOpen={setIsErrorAlertOpen}
                        setIsSuccessAlertOpen={setIsSuccessAlertOpen}
                    />
                }
            </div>
            <ErrorAndSuccessModals
                isErrorAlertOpen={isErrorAlertOpen}
                handleCloseErrorAlert={handleCloseErrorAlert}
                isSuccessAlertOpen={isSuccessAlertOpen}
                handleCloseSuccessAlert={handleCloseSuccessAlert}
            />
            {
                iterationComments && <IterationCommentsModal open={isCommentsModalOpen} setOpen={setCommentsModalOpen} comments={iterationComments} />
            }
        </div>
    )
}

export default AdminPublishIteration
