import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import IterationCommentsModal from "../../../components/iteration-comments-modal/iteration-comments-modal.component";
import Information3DObject from "../../../components/TDObject-information/TDObject-information.component";
import ModelViewer from "../../../components/model-viewer/model-viewer.component";
import ApprovalIterationName from "../../../components/approval-iteration-name/approval-iteration-name.component";
import ApprovalIterationRoleResult from "../../../components/approval-iteration-role-result/approval-iteration-role-result.component";
import ErrorAndSuccessModals from "../../../components/error-and-success-modals/error-and-success-modals.component";
import useTasksCountContext from "../../../contexts/tasks-count.context";
import useUserContext from "../../../contexts/user.context";
import { fetch3DObjectById } from "../../../utils/api-fetchers/TDObject/TDObject-api-fetcher";
import {
    addModelIterationComment,
    approveModelIterationAsRole,
    declineModelIterationAsRole, fetchModelIterationById
} from "../../../utils/api-fetchers/aproval-process/aproval-process-api-fetcher";
import { fetchModelCommentsById } from "../../../utils/api-fetchers/comment/comment-api-fetcher";
import { recomputeTasksNumber } from "../../../utils/context-maintainers/tasks-count-context.maintainer";
import { I3DObject } from "../../../interfaces/I3DObject";
import { IApprovalProcessModelIteration } from "../../../interfaces/IApprovalProcessModelIteration";
import { IComment} from "../../../interfaces/IComment";

import './approver-iteration-approval.styles.scss'
import SendIcon from "../../../components/icons/send-icon.component";
import MagnifyingGlassIcon from "../../../components/icons/magnifying-glass-icon.component";

const ApproverIterationApproval = () => {

    const location = useLocation();
    const { state } = location
    const { modelIteration } = state
    const { tdObjectId } = modelIteration

    const { currentUser } = useUserContext()
    const { setTasksCount } = useTasksCountContext()

    const [TDObject, setTDObject] = useState<I3DObject | undefined>(undefined)
    const [fetchedIteration, setFetchedIteration] = useState<IApprovalProcessModelIteration | undefined>(undefined)

    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false)
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false)

    const [iterationComments, setIterationComments] = useState<IComment[] | undefined>(undefined)
    const [isCommentsModalOpen, setCommentsModalOpen] = useState(false)

    // ================================ useEffects ================================

    useEffect(() => {
        const get3DObject = async () => {
            if (currentUser && tdObjectId)
                setTDObject(await fetch3DObjectById(currentUser?.token, tdObjectId))
        }

        get3DObject()
    }, [tdObjectId, currentUser])

    useEffect(() => {
        const fetchIteration = async () => {
            if (currentUser)
                setFetchedIteration(await fetchModelIterationById(currentUser.token, modelIteration.id))
        }

        fetchIteration()
    }, [currentUser, modelIteration.id])

    useEffect(() => {
        const fetchIterationComments = async () => {
            if (currentUser) {
                setIterationComments(await fetchModelCommentsById(currentUser.token, modelIteration.id))
            }
        }
        fetchIterationComments()
    }, [currentUser, modelIteration.id])

    // ================================ handling alerts ================================

    const handleCloseErrorAlert = () => {
        setIsErrorAlertOpen(false);
    };

    const handleCloseSuccessAlert = () => {
        setIsSuccessAlertOpen(false)
    };

    const handleShowComments = () => {
        setCommentsModalOpen(true)
    }

    // ================================ updating state ================================

    const updateIteration = async () => {
        if (currentUser) {
            setFetchedIteration(await fetchModelIterationById(currentUser.token, modelIteration.id))
        }
    }

    const update3DObject = async () => {
        if (currentUser) {
            const fetched3DObject = await fetch3DObjectById(currentUser.token, tdObjectId)
            setTDObject(fetched3DObject)
        }
    }

    // ================================ submit ================================

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if ( ! currentUser || ! fetchedIteration) return

        const decision = event.currentTarget.decision.value
        const comment = event.currentTarget.comment.value

        try {
            if ( decision === 'true') {
                await approveModelIterationAsRole(currentUser?.token, fetchedIteration.id, getApproverRoleVerbose())
                await update3DObject()
                // await handleSendModellerEmail()
            }
            else {
                await declineModelIterationAsRole(currentUser?.token, fetchedIteration.id, getApproverRoleVerbose())
                await update3DObject()
                // await handleSendModellerEmail()
            }

            if (comment !== '') {
                await addModelIterationComment(currentUser.token, fetchedIteration.id, comment)
            }
            await updateIteration()
            setTasksCount(await recomputeTasksNumber(currentUser))
            setIsSuccessAlertOpen(true)
        } catch (e) {
            console.log('catching error', e)
            await updateIteration()
            setIsErrorAlertOpen(true)
        }
    }


    // ================================ function for sending notification email to author of 3D model ====================================== //
    // currently works ONLY if the approver is the same user as the AUTHOR of the model. Otherwise, the "fetchUserById" throws 403 Forbidden //
    // ===================================================================================================================================== //

    // const handleSendModellerEmail = async () => {
    //     if (TDObject && currentUser && fetchedIteration) {
    //         const author = await fetchUserById(currentUser.token, TDObject.userId)
    //         const emailProps: IModellerEmailProps = {
    //             email_to: author.email,
    //             model_name: TDObject.name,
    //             iteration_name: fetchedIteration.name,
    //             model_new_status: TDObject.status
    //         }
    //         sendModellerEmail(emailProps)
    //     }
    // }

    // =====================================================================================================================================



    //================================ role parser ================================

    const getApproverRoleVerbose = () => {
        return currentUser?.role === 'historian' ? 'historian' : 'graphician'
    }

    // ================================ checking conditions ================================

    const commentsExist = () => {
        return iterationComments && iterationComments.length > 0
    }

    const toBeApprovedByRole = () => {
        if (fetchedIteration) {
            if (currentUser?.role === 'historian') {
                return ! fetchedIteration.historianApprovingId && ! fetchedIteration.historianDecliningId
            }
            if (currentUser?.role === 'approver')
                return ! fetchedIteration.graphicianApprovingId && ! fetchedIteration.graphicianDecliningId
        }
        return false
    }


    // ================================ return ================================


    return(
        <div className='approver-iteration-approval-container'>
            <div className='approver-iteration-approval-header-container'>
                <h2>
                    Schválení iterace modelu
                </h2>
            </div>

            <Information3DObject TDObject={TDObject} />
            {
                fetchedIteration &&
                <div className='approver-iteration-approval-model-and-button-container'>
                    {
                        TDObject && <ModelViewer modelId={TDObject?.modelId} modelFormat={TDObject.format} />
                    }
                    {
                        toBeApprovedByRole() ?
                            <Form onSubmit={handleSubmit}>
                                <div className='approver-iteration-approval-info-container'>
                                    <ApprovalIterationName iteration={fetchedIteration} />
                                    <h5 className='approver-iteration-approval-info-padding'>
                                        Zvolte rozhodnutí:
                                    </h5>


                                    <Form.Select aria-label="Default select example" id='decision'>
                                        <option value='true'>Schvaluji</option>
                                        <option value='false'>Zamítám</option>
                                    </Form.Select>

                                    <h5 className='approver-iteration-approval-info-padding'>
                                        Poznámky:
                                    </h5>

                                    <Form.Control as="textarea" rows={3} id='comment'/>

                                    <Row>
                                        <Col>
                                            <Button
                                                type="submit"
                                                className='approver-iteration-approval-info-button flex-button'
                                                size='lg'>
                                                <SendIcon />
                                                Ohodnotit
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button className='approver-iteration-approval-info-button flex-button' hidden={! commentsExist()} onClick={handleShowComments}>
                                                <MagnifyingGlassIcon />
                                                Komentáře
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                            :   <ApprovalIterationRoleResult
                                modelIteration={fetchedIteration}
                                setNewIteration={updateIteration}
                                setIsErrorAlertOpen={setIsErrorAlertOpen}
                                setIsSuccessAlertOpen={setIsSuccessAlertOpen}
                                TDObject={TDObject}
                            />
                    }
                </div>
            }
            <ErrorAndSuccessModals
                isErrorAlertOpen={isErrorAlertOpen}
                handleCloseErrorAlert={handleCloseErrorAlert}
                isSuccessAlertOpen={isSuccessAlertOpen}
                handleCloseSuccessAlert={handleCloseSuccessAlert}
            />
            {
                iterationComments && <IterationCommentsModal open={isCommentsModalOpen} setOpen={setCommentsModalOpen} comments={iterationComments} />
            }
        </div>
    )
}

export default ApproverIterationApproval
