import './homepage-not-logged-in.styles.scss'

const HomepageNotLoggedIn = () => {
    return (
        <div className='homepage-not-logged-in-container'>
            <div className='homepage-not-logged-in-header-container'>
                <h1>Vítejte v aplikaci pro projekt Starý Most!</h1>
            </div>

            <div className='homepage-not-logged-in-row-container'>
                <h4>V této aplikaci Vám bude umožněno spravovat data projektu Starý Most.</h4>
            </div>
            <div className='homepage-not-logged-in-row-container'>
                <h5>
                    Pro vstup do aplikace se prosím přihlašte svým Google účtem.
                </h5>
            </div>
            <div className='homepage-not-logged-in-row-container'>
                <h5>
                    Pokud se do projektu přihlašujete poprvé, budete společně s přihlášením registrováni.
                </h5>
            </div>
        </div>
    )
}

export default HomepageNotLoggedIn
