import { IComment } from "../../../interfaces/IComment";

import ENV from "../../../env";

const apiUrl = ENV.privateApiUrl

export const fetchModelCommentsById = (token: string, commentableId: number): Promise<IComment[]> => {
    return fetch(apiUrl + "/comments/model/" + commentableId,{
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "GET"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
}
