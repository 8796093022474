import React from "react";

import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import ExclamationMarkIcon from "../icons/exclamation-mark-icon.component";
import { WarningText } from "../../enums/warning-text.enum";

interface IProps {
    htmlContent: WarningText
}

const WarningPopover = (props: IProps) => {
    const { htmlContent } = props

    const helpPopover = (
        <Popover>
            <Popover.Body>
                <div dangerouslySetInnerHTML={{__html: htmlContent.toString()}}/>
            </Popover.Body>
        </Popover>
    );


    return(
        <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="auto"
            overlay={helpPopover}
        >
            <div>
                <ExclamationMarkIcon />
            </div>
        </OverlayTrigger>
    )
}

export default WarningPopover
