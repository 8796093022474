import React from "react";

import Modal from "react-bootstrap/Modal";

import { IComment } from "../../interfaces/IComment";
import ApprovalIterationComment from "../approval-iteration-comment/approval-iteration-comment.component";

interface IProps {
    open: boolean,
    setOpen: (value: boolean) => void,
    comments: IComment[]
}

const IterationCommentsModal = (props: IProps) => {

    const { open, setOpen, comments } = props

    const handleClose = () => {
        setOpen(false)
    }

    return(
        <Modal show={open} onHide={handleClose}>
            <Modal.Header closeButton><h4>Komentáře k iteraci</h4></Modal.Header>
            <Modal.Body>
                {
                    comments.map(comment => <ApprovalIterationComment key={comment.id} comment={comment} />)
                }
            </Modal.Body>
        </Modal>
    )
}

export default IterationCommentsModal
