import { getMimeTypeFromFormat } from "../../parsers/file-format-parser";
import { IStructure } from "../../../interfaces/IStructure";
import ITransformation from "../../../interfaces/ITransformation";

import ENV from "../../../env";

const apiUrl = ENV.privateApiUrl

export const fetchStructures = (token: string): Promise<IStructure[]> => {
    return fetch(apiUrl + "/structures",{
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "GET"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    })
}

export const fetchPublicStructures = async (token: string): Promise<IStructure[]> => {
    const result = await fetchStructures(token)
    return result.filter(structure => structure.public)
}

export const fetchStructureById = async (token: string, structureId: string) => {
    return (fetch(apiUrl + "/structures/" + structureId, {
        headers:{
            'Accept': 'application/json',
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "GET"
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}

export const createFirst3DObjectForStructure = async (
    token: string,
    structureId: string,
    TDObjectName: string,
    modelFile: any,
    transformation: ITransformation,
    transformationWeb: ITransformation,
    tagsObject: any) => {

    const fileName: string = modelFile.name
    const format = modelFile.name.split('.').pop().toUpperCase()
    const mimeType = getMimeTypeFromFormat(format)

    if ( ! mimeType) {
        throw new Error("Invalid MimeType!")
    }

    const file = new Blob([modelFile], { type: mimeType });
    const formData = new FormData();
    formData.append('model', file, fileName);
    formData.append('name', TDObjectName)
    formData.append('format', format)
    formData.append('transformation', JSON.stringify(transformation))
    formData.append('transformationWeb', JSON.stringify(transformationWeb))

    if (Object.keys(tagsObject).length > 0)
        formData.append('properties', tagsObject)


    return (fetch(apiUrl + "/structures/" + structureId + "/3Dobjects", {
        headers:{
            'Accept': 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        method: "POST",
        body: formData
    }).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json();
    }));
}

export const createStructure = async (token: string, data: IStructure) => {
    return fetch(apiUrl + "/structures", {
            headers: {
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            body: JSON.stringify(data),
            method: "POST"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}

export const updateStructure = async (token: string, id: string, data: IStructure) => {
    return fetch(apiUrl + `/structures/${id}`, {
        headers:{
            'Accept': 'application/json',
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${token}`
        },
        body: JSON.stringify(data),
        method: "PUT"
    }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}

export const deleteStructure = async (token: string, structureId: string) => {
    return fetch(apiUrl + "/structures/" + structureId, {
            headers:{
                'Accept': 'application/json',
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${token}`
            },
            method: "DELETE"
        }
    ).then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
    })
}
