import React, { useEffect, useState } from "react";

import './photo-lightbox.styles.scss'

interface IProps {
  show: boolean;
  onHide: () => void;
  src: string;
  alt: string;
}

const PhotoLightbox = (props: IProps) => {
  const { show, onHide, src, alt } = props
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      const maxWidth = window.innerWidth * 0.9;
      const maxHeight = window.innerHeight * 0.9;

      let newWidth = maxWidth;
      let newHeight = maxWidth / aspectRatio;

      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = maxHeight * aspectRatio;
      }

      setImageDimensions({ width: newWidth, height: newHeight });
    };
  }, [show, src]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onHide();
    }, 300);
  };

  return (
    <>
      {show && (
        <div className={`lightbox-overlay ${isClosing ? 'closing' : ''}`} onClick={handleClose}>
          <div className={`lightbox ${isClosing ? 'slide-out' : ''}`} onClick={(e) => e.stopPropagation()}>
            <div className="lightbox-content">
              <img src={src} alt={alt} className="lightbox-image" style={{ maxWidth: imageDimensions.width, maxHeight: imageDimensions.height }} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PhotoLightbox;
