import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './photo-upload.styles.scss';

interface IProps {
    uploadPhoto: (event: any) => void,
}

const PhotoUpload = (props: IProps) => {
    const { uploadPhoto } = props
    const [photos, setPhotos] = useState<File[]>([]);

    const handlePhotoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPhotos = Array.from(e.target.files as FileList);
      const validPhotos = newPhotos.filter(file => file.type === 'image/jpeg' || file.type === 'image/png');
      setPhotos([...photos, ...validPhotos]);
    };
  
    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
    };
  
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const newPhotos = Array.from(e.dataTransfer.files).filter(file => file.type === 'image/jpeg' || file.type === 'image/png');
      setPhotos([...photos, ...newPhotos]);
    };
  
    const handleRemovePhoto = (index: number) => {
      const newPhotos = [...photos];
      newPhotos.splice(index, 1);
      setPhotos(newPhotos);
    };
  
    const handleUpload = async () => {
      for (const photo of photos) {
        await uploadPhoto(photo)
      }
      setPhotos([]);
    };
  
    return (
      <Container>
        <Row className="justify-content-md-center">
          <Col md="6">
            <Form.Group onDrop={handleDrop} onDragOver={handleDragOver}>
              <Form.Label>Přetáhněte soubory nebo klikněte na zvolit soubory.</Form.Label>
              <Form.Control
                type="file"
                multiple
                accept=".jpg,.jpeg,.png"
                onChange={handlePhotoUpload}
              />
            </Form.Group>
            <div className="photo-list">
              {photos.map((photo, index) => (
                <Col key={index} xs="12" md="4">
                  <div className="photo-item">
                    <div className="thumbnail-container">
                      <img
                        src={URL.createObjectURL(photo)}
                        alt={`Photo ${index + 1}`}
                        className="uploaded-photo-thumbnail"
                      />
                      <div
                        onClick={() => handleRemovePhoto(index)}
                        className="remove-button"
                      >
                        X
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </div>
            {photos.length > 0 && (
              <Button onClick={handleUpload} className="upload-button">Nahrát</Button>
            )}
          </Col>
        </Row>
      </Container>
    );
};
  
export default PhotoUpload;
