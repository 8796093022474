import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";

import { IApprovalProcessDetail } from "../../interfaces/IApprovalProcessDetail";
import {
    createModelIteration, fetchApprovalById, fetchUserApprovals
} from "../../utils/api-fetchers/aproval-process/aproval-process-api-fetcher";
import ErrorAndSuccessModals from "../error-and-success-modals/error-and-success-modals.component";
import useUserContext from "../../contexts/user.context";
import ApprovalAndIterationCreationAutomaticModal
    from "../approval-and-iteration-creation-automatic-modal/approval-and-iteration-creation-automatic-modal.component";

import './send-for-approval-automatic-modal.styles.scss'

interface IProps {
    isOpen: boolean,
    setIsOpen: (value: boolean) => void,
    structureId: string,
    TDObjectId: string
}

const SendForApprovalAutomaticModal = (props: IProps) => {

    const { isOpen, setIsOpen, structureId, TDObjectId } = props

    const { currentUser } = useUserContext()

    const navigate = useNavigate()

    const [structureApproval, setStructureApproval] = useState<IApprovalProcessDetail | undefined>(undefined)

    const [isErrorAlertOpen, setIsErrorAlertOpen] = useState(false)
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState(false)

    // ================================ useEffect ================================
    useEffect(() => {
        const getApproval = async () => {
            if (currentUser) {
                const userApprovals = await fetchUserApprovals(currentUser.token)
                for (let i = 0; i < userApprovals.length; i++) {
                    const approval = userApprovals[i]
                    if (approval.structureId === structureId)
                        setStructureApproval(await fetchApprovalById(approval.id, currentUser.token))
                }
            }
        }
        getApproval()
    }, [currentUser, structureId])

    // ================================ handling modals ================================

    const handleClose = () => {
        setIsOpen(false)
    }

    const handleCloseErrorAlert = () => {
        setIsErrorAlertOpen(false);
    };

    const handleCloseSuccessAlert = () => {
        setIsSuccessAlertOpen(false)
        navigate('/modeller/all-creations')
    };

    // ================================ sending into process - iteration ================================

    const handleSubmit = async (event: any) => {
        event.preventDefault()
        if (! currentUser || ! structureApproval) return

       await handleSendIntoExistingProcess(event)
    }

    const handleSendIntoExistingProcess = async (event: any) => {
        event.preventDefault()
        const approvalId = structureApproval ? structureApproval.id : 0
        const iterationName = event.currentTarget.iterationName.value

        try {
            if (currentUser) {
                const iteration = await createModelIteration(currentUser.token, approvalId, iterationName, TDObjectId)
                if (! iteration.id) {
                    setIsErrorAlertOpen(true)
                    return
                }
                // ==================== functions for email sending ===============================
                // const approval = await fetchApprovalById(approvalId, currentUser.token)
                // await handleSendApproversEmail(approval, iterationName)
            }
            handleClose()
            setIsSuccessAlertOpen(true)
        } catch (e) {
            console.log(e)
            setIsErrorAlertOpen(true)
        }
    }

    // ==================== functions for email sending ===============================
    // const approval = await fetchApprovalById(approvalId, currentUser.token)
    // await handleSendApproversEmail(approval, iterationName)

    // ============================== function for sending notification email to approvers in approval process ====================================== //
    // currently works ONLY if each of the approvers is the same user as the AUTHOR of the model. Otherwise, the "fetchUserById" throws 403 Forbidden //
    // ============================================================================================================================================== //

    // const handleSendApproversEmail = async (approval: IApprovalProcessDetail, iterationName: string) => {
    //     if (currentUser) {
    //         const approvalUsers = await fetchApprovalMembers(currentUser.token, approval.id)
    //
    //         const historians = approvalUsers.historians
    //         const graphicians = approvalUsers.graphicians
    //
    //         for (let i = 0; i < historians.length; i++) {
    //             const localUser = historians[i]
    //             const userDetail = await fetchUserById(currentUser.token, localUser.userId)
    //             await handleSendEmail(userDetail.email, approval.name, iterationName)
    //         }
    //
    //         for (let i = 0; i < graphicians.length; i++) {
    //             const localUser = graphicians[i]
    //             const userDetail = await fetchUserById(currentUser.token, localUser.userId)
    //             await handleSendEmail(userDetail.email, approval.name, iterationName)
    //         }
    //     }
    // }
    //
    // const handleSendEmail = (email: string, processName: string, iterationName: string) => {
    //     const emailProps: IApproverEmailProps = {
    //         email_to: email,
    //         process_name: processName,
    //         iteration_name: iterationName
    //     }
    //     sendApproverEmail(emailProps)
    // }

    // =================================================== end of email sending functions =========================================================== //
    // ============================================================================================================================================== //


    // ================================ return ================================

    return(
        <>
            <ApprovalAndIterationCreationAutomaticModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                handleSubmit={handleSubmit}
                approval={structureApproval}
                structureId={structureId}
            />
            <ErrorAndSuccessModals
                isErrorAlertOpen={isErrorAlertOpen}
                handleCloseErrorAlert={handleCloseErrorAlert}
                isSuccessAlertOpen={isSuccessAlertOpen}
                handleCloseSuccessAlert={handleCloseSuccessAlert}
            />
        </>
    )
}

export default SendForApprovalAutomaticModal
