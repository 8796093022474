import { Modal, Button } from "react-bootstrap";

interface IProps {
    id: string;
    handleDelete: (id: string) => void;
    show: boolean;
    onHide: () => void;
  }
 
const DeleteConfirmation = (props: IProps) => {
    const { id, handleDelete, show, onHide } = props
    return (
        <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Potvrzení odstranění</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="alert alert-danger">Opravdu chcete odstranit tuto položku?</div></Modal.Body>
        <Modal.Footer>
          <Button variant="default" onClick={onHide}>
            Zrušit
          </Button>
          <Button variant="danger" onClick={() => handleDelete(id) }>
            Odstranit
          </Button>
        </Modal.Footer>
      </Modal>
    )
}
 
export default DeleteConfirmation;